import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const styles = createStyles((theme: Theme) => ({
  link: {
    color: theme.palette.primary.main,
  },
  root: {
    flex: 1,
  },
}));

export const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.primary.main,
  },
  root: {
    flex: 1,
  },
}));
