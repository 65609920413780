import React, { ReactNode, RefObject } from 'react';
import { withStyles } from '@material-ui/core';
import { AlertStore } from '@uvgo-shared/alert';
import { VIEW_MODE } from '@wings/shared';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { forkJoin } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { EtpScenarioStore, EtpSettingsStore, SettingsStore } from '../../../Shared/Stores';
import { EtpPenaltyModel, EtpScenarioDetailModel, updateAircraftSidebarOptions } from '../../../Shared';
import { styles } from './AddEtpScenario.style';
import { ArrowBack } from '@material-ui/icons';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { NavigateFunction } from 'react-router';
import EtpScenarioEditor, { PureEtpScenarioEditor } from '../EtpScenarioEditor/EtpScenarioEditor';
import { IClasses, UIStore, withRouter, UnsubscribableComponent } from '@wings-shared/core';
import { CustomLinkButton, SidebarStore } from '@wings-shared/layout';

interface Props {
  classes?: IClasses;
  navigate?: NavigateFunction;
  etpScenarioStore?: EtpScenarioStore;
  etpSettingsStore?: EtpSettingsStore;
  settingsStore?: SettingsStore;
  sidebarStore?:typeof SidebarStore;
}

@inject('etpScenarioStore', 'etpSettingsStore', 'settingsStore','sidebarStore')
@observer
class AddEtpScenario extends UnsubscribableComponent<Props> {
  private etpScenarioEditorRef: RefObject<PureEtpScenarioEditor> = React.createRef<PureEtpScenarioEditor>();
  @observable private etpScenarioDetailModel: EtpScenarioDetailModel;

  /* istanbul ignore next */
  componentDidMount() {
    this.props.sidebarStore?.setNavLinks(updateAircraftSidebarOptions('ETP Scenario'), 'aircraft');
    UIStore.setPageLoader(true);
    forkJoin([ this.etpSettingsStore.loadEtpSettings(), this.settingsStore.getWeightUOMs() ])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(() => (this.etpScenarioDetailModel = this.etpScenarioDetailTemplate()));
  }

  /* istanbul ignore next */
  private get hasError(): boolean {
    const { current } = this.etpScenarioEditorRef;
    return current ? current.hasError : true;
  }

  /* istanbul ignore next */
  private etpScenarioDetailTemplate(): EtpScenarioDetailModel {
    return new EtpScenarioDetailModel({
      id: 0,
      etpPenalties: this.getEtpPenalties(),
    });
  }

  private getEtpPenalties(): EtpPenaltyModel[] {
    return this.etpSettingsStore.ETPPenaltyCategories.map(
      etpPenaltyCategory => new EtpPenaltyModel({ etpPenaltyCategory })
    );
  }

  private get etpScenarioStore(): EtpScenarioStore {
    return this.props.etpScenarioStore as EtpScenarioStore;
  }

  private get etpSettingsStore(): EtpSettingsStore {
    return this.props.etpSettingsStore as EtpSettingsStore;
  }

  private get settingsStore(): SettingsStore {
    return this.props.settingsStore as SettingsStore;
  }

  /* istanbul ignore next */
  private addEtpScenario(): void {
    UIStore.setPageLoader(true);
    this.etpScenarioStore
      .upsertEtpScenarioDetail(this.etpScenarioDetailModel)
      .pipe(
        finalize(() => UIStore.setPageLoader(false)),
        takeUntil(this.destroy$),
        tap(() => this.props.navigate && this.props.navigate('/aircraft/etp-scenario'))
      )
      .subscribe({
        error: error => AlertStore.critical(error.message),
      });
  }

  public render(): ReactNode {
    const { classes } = this.props as Required<Props>;
    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <CustomLinkButton to="/aircraft/etp-scenario" title="ETP Scenarios" startIcon={<ArrowBack />} />
          <PrimaryButton variant="contained" disabled={this.hasError} onClick={() => this.addEtpScenario()}>
            Save
          </PrimaryButton>
        </div>
        <EtpScenarioEditor
          ref={this.etpScenarioEditorRef}
          viewMode={VIEW_MODE.NEW}
          etpScenarioDetailModel={this.etpScenarioDetailModel}
          etpScenarioStore={this.etpScenarioStore}
          etpSettingsStore={this.etpSettingsStore}
          settingsStore={this.settingsStore}
          onChange={updatedModel => {
            this.etpScenarioDetailModel = updatedModel;
          }}
        />
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(AddEtpScenario));
export { AddEtpScenario as PureAddEtpScenario };
