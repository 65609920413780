import React, { FC, ReactNode, RefObject, useEffect, useRef } from 'react';
import { VIEW_MODE } from '@wings/shared';
import {
  DATE_FORMAT,
  Utilities,
  UIStore,
  ViewPermission,
  SearchStore,
  SettingsTypeModel,
  GRID_ACTIONS,
} from '@wings-shared/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { ColDef, ColGroupDef, GridOptions, ValueFormatterParams } from 'ag-grid-community';
import {
  AirframeModel,
  AIRFRAME_FILTERS,
  AirframeStore,
  AircraftModuleSecurity,
  updateAircraftSidebarOptions,
} from '../Shared';
import { inject, observer } from 'mobx-react';
import { finalize, takeUntil } from 'rxjs/operators';
import { CustomLinkButton, SidebarStore } from '@wings-shared/layout';
import { ISearchHeaderRef, SearchHeaderV2 } from '@wings-shared/form-controls';
import { CustomAgGridReact, agGridUtilities, useAgGrid, useGridState } from '@wings-shared/custom-ag-grid';
import { useUnsubscribe } from '@wings-shared/hooks';
import { useLocation } from 'react-router';

interface Props {
  airframeStore?: AirframeStore;
  sidebarStore?: typeof SidebarStore;
}

const AirframeV2: FC<Props> = ({ airframeStore, sidebarStore }) => {
  const unsubscribe = useUnsubscribe();
  const gridState = useGridState();
  const location = useLocation();
  const agGrid = useAgGrid<AIRFRAME_FILTERS, AirframeModel>([], gridState);
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  const _airframeStore = airframeStore as AirframeStore;

  /* istanbul ignore next */
  useEffect(() => {
    sidebarStore?.setNavLinks(updateAircraftSidebarOptions('Airframe'), 'aircraft');
    const searchData = SearchStore.searchData.get(location.pathname);
    if (searchData) {
      searchHeaderRef.current?.setupDefaultFilters(searchData);
      loadInitialData();
      SearchStore.clearSearchData(location.pathname);
      return;
    }
    loadInitialData();
  }, []);

  /* istanbul ignore next */
  const loadInitialData = (): void => {
    UIStore.setPageLoader(true);
    _airframeStore
      .getAirframes()
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(aircraftVariations => gridState.setGridData(aircraftVariations));
  };

  const actionMenus = () => {
    return [
      {
        title: 'Edit',
        isHidden: !AircraftModuleSecurity.isEditable,
        action: GRID_ACTIONS.EDIT,
        to: node => `/aircraft/airframe/${node?.data.id}/${VIEW_MODE.EDIT.toLowerCase()}`,
      },
      {
        title: 'Details',
        action: GRID_ACTIONS.DETAILS,
        to: node => `/aircraft/airframe/${node?.data.id}/${VIEW_MODE.DETAILS.toLowerCase()}`,
      },
    ];
  };

  /* istanbul ignore next */
  const columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Serial Number',
      field: 'serialNumber',
    },
    {
      headerName: 'Airframe Status',
      field: 'airframeStatus',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.name,
    },
    {
      headerName: 'Manufacture Date',
      field: 'manufactureDate',
      valueFormatter: ({ value }: ValueFormatterParams) =>
        Utilities.getformattedDate(value, DATE_FORMAT.API_DATE_FORMAT),
      comparator: (current: string, next: string) => Utilities.customDateComparator(current, next),
    },
    {
      headerName: 'Temporary Engine Date',
      field: 'temporaryEngineDate',
      valueFormatter: ({ value }: ValueFormatterParams) =>
        Utilities.getformattedDate(value, DATE_FORMAT.API_DATE_FORMAT),
      comparator: (current: string, next: string) => Utilities.customDateComparator(current, next),
    },
    {
      headerName: 'Crew Seat Cap',
      field: 'crewSeatCap',
    },
    {
      headerName: 'Pax Seat Cap',
      field: 'paxSeatCap',
    },
    ...agGrid.auditFields(gridState.isRowEditing),
    {
      ...agGrid.actionColumn({
        headerName: 'Action',
        minWidth: 150,
        maxWidth: 210,
        cellRendererParams: {
          isActionMenu: true,
          actionMenus,
          onAction: (action: GRID_ACTIONS) => {
            if ([ GRID_ACTIONS.EDIT, GRID_ACTIONS.DETAILS ].includes(action)) {
              if (searchHeaderRef.current) {
                SearchStore.saveSearchData(location.pathname, {
                  ...searchHeaderRef.current.getFilters(),
                });
              }
            }
          },
        },
      }),
    },
  ];

  /* istanbul ignore next */
  const gridOptions = (): GridOptions => {
    const baseOptions: Partial<GridOptions> = agGrid.gridOptionsBase({
      context: {},
      columnDefs,
      isEditable: false,
      gridActionProps: {
        tooltip: 'Airframe',
        showDeleteButton: false,
        getDisabledState: () => gridState.hasError,
      },
    });

    return {
      ...baseOptions,
      suppressClickEdit: true,
      isExternalFilterPresent: () => searchHeaderRef.current?.hasSearchValue || false,
      doesExternalFilterPass: node => {
        const searchHeader = searchHeaderRef.current;
        if (!searchHeader) {
          return false;
        }
        const { id, serialNumber } = node.data as AirframeModel;
        return (
          !id ||
          agGrid.isFilterPass(
            {
              [AIRFRAME_FILTERS.SERIAL_NUMBER]: serialNumber,
            },
            searchHeader.searchValue,
            searchHeader.selectedOption
          )
        );
      },
      suppressColumnVirtualisation: true,
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
      },
    };
  };

  const rightContent = (): ReactNode => {
    return (
      <ViewPermission hasPermission={AircraftModuleSecurity.isEditable}>
        <CustomLinkButton
          variant="contained"
          startIcon={<AddIcon />}
          to="new"
          title="Add Airframe"
          disabled={gridState.isRowEditing || UIStore.pageLoading}
        />
      </ViewPermission>
    );
  };

  return (
    <>
      <SearchHeaderV2
        ref={searchHeaderRef as RefObject<ISearchHeaderRef>}
        selectInputs={[ agGridUtilities.createSelectOption(AIRFRAME_FILTERS, AIRFRAME_FILTERS.SERIAL_NUMBER) ]}
        rightContent={rightContent}
        onFilterChange={() => gridState.gridApi?.onFilterChanged()}
        onExpandCollapse={agGrid.autoSizeColumns}
      />
      <CustomAgGridReact isRowEditing={gridState.isRowEditing} rowData={gridState.data} gridOptions={gridOptions()} />
    </>
  );
};

export default inject('airframeStore', 'sidebarStore')(observer(AirframeV2));
