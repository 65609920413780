import React, { ReactNode } from 'react';
import { VIEW_MODE } from '@wings/shared';
import {
  withRouter,
  DATE_FORMAT,
  Utilities,
  UIStore,
  IClasses,
  ViewPermission,
  SearchStore,
  SettingsTypeModel,
  IBaseGridFilterSetup,
  GRID_ACTIONS,
  cellStyle,
} from '@wings-shared/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { ColDef, ColGroupDef, GridOptions, ValueFormatterParams } from 'ag-grid-community';
import {
  AirframeModel,
  AIRFRAME_FILTERS,
  AirframeStore,
  AircraftModuleSecurity,
  updateAircraftSidebarOptions,
} from '../Shared';
import { withTheme } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { finalize, takeUntil } from 'rxjs/operators';
import { CustomLinkButton, SidebarStore } from '@wings-shared/layout';
import { SearchHeader } from '@wings-shared/form-controls';
import { AgGridCellEditor, CustomAgGridReact, BaseGrid } from '@wings-shared/custom-ag-grid';

interface Props {
  classes?: IClasses;
  airframeStore?: AirframeStore;
  sidebarStore?: typeof SidebarStore;
}

const filtersSetup: IBaseGridFilterSetup<AIRFRAME_FILTERS> = {
  defaultPlaceHolder: 'Search by Serial Number',
  filterTypesOptions: Object.values(AIRFRAME_FILTERS),
  defaultFilterType: AIRFRAME_FILTERS.SERIAL_NUMBER,
};

@inject('airframeStore', 'sidebarStore')
@observer
class Airframe extends BaseGrid<Props, AirframeModel, AIRFRAME_FILTERS> {
  constructor(props) {
    super(props, filtersSetup);
  }

  private get airframeStore(): AirframeStore {
    return this.props.airframeStore as AirframeStore;
  }
  componentDidMount() {
    this.props.sidebarStore?.setNavLinks(updateAircraftSidebarOptions('Airframe'), 'aircraft');
    this.loadInitialData();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const { clientSearchValue } = SearchStore;
    if (clientSearchValue.searchValue) {
      return;
    }
    SearchStore.clearSearch();
  }

  /* istanbul ignore next */
  private loadInitialData(): void {
    UIStore.setPageLoader(true);
    this.airframeStore
      .getAirframes()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(aircraftVariations => {
        this.data = aircraftVariations;
        const { clientSearchValue } = SearchStore;
        const { selectedOption, searchValue } = clientSearchValue;
        SearchStore.clearSearch();
        if (searchValue) {
          this._setSelectedOption((selectedOption as AIRFRAME_FILTERS) || AIRFRAME_FILTERS.SERIAL_NUMBER);
          this._setSearchValue(searchValue);
          this.searchHeaderRef.current?.setSearchValue(searchValue);
          return;
        }
      });
  }

  /* istanbul ignore next */
  private columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Serial Number',
      field: 'serialNumber',
    },
    {
      headerName: 'Airframe Status',
      field: 'airframeStatus',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.name,
    },
    {
      headerName: 'Manufacture Date',
      field: 'manufactureDate',
      valueFormatter: ({ value }: ValueFormatterParams) =>
        Utilities.getformattedDate(value, DATE_FORMAT.API_DATE_FORMAT),
      comparator: (current: string, next: string) => Utilities.customDateComparator(current, next),
    },
    {
      headerName: 'Temporary Engine Date',
      field: 'temporaryEngineDate',
      valueFormatter: ({ value }: ValueFormatterParams) =>
        Utilities.getformattedDate(value, DATE_FORMAT.API_DATE_FORMAT),
      comparator: (current: string, next: string) => Utilities.customDateComparator(current, next),
    },
    {
      headerName: 'Crew Seat Cap',
      field: 'crewSeatCap',
    },
    {
      headerName: 'Pax Seat Cap',
      field: 'paxSeatCap',
    },
    ...this.auditFields,
    {
      headerName: 'Action',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      sortable: false,
      minWidth: 150,
      maxWidth: 210,
      suppressSizeToFit: true,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        isActionMenu: true,
        actionMenus: () => [
          {
            title: 'Edit',
            isHidden: !AircraftModuleSecurity.isEditable,
            action: GRID_ACTIONS.EDIT,
            to: node => `/aircraft/airframe/${node?.data.id}/${VIEW_MODE.EDIT.toLowerCase()}`,
          },
          {
            title: 'Details',
            action: GRID_ACTIONS.DETAILS,
            to: node => `/aircraft/airframe/${node?.data.id}/${VIEW_MODE.DETAILS.toLowerCase()}`,
          },
        ],
        onAction: () => {
          if (this.searchValue) {
            const clientSearchValue = { selectedOption: this.selectedOption, searchValue: this.searchValue as string };
            SearchStore.setclientSearchValue(clientSearchValue);
          }
        },
      },
    },
  ];

  /* istanbul ignore next */
  private get gridActionProps(): object {
    return {
      tooltip: 'Airframe',
      showDeleteButton: false,
      getDisabledState: () => this.hasError,
    };
  }

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: false,
      gridActionProps: this.gridActionProps,
    });

    return {
      ...baseOptions,
      suppressClickEdit: true,
      doesExternalFilterPass: node => {
        const { id, serialNumber } = node.data as AirframeModel;

        return (
          !id ||
          this._isFilterPass({
            [AIRFRAME_FILTERS.SERIAL_NUMBER]: serialNumber,
          })
        );
      },
      suppressColumnVirtualisation: true,
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
      },
      frameworkComponents: {
        ...baseOptions.frameworkComponents,
        customCellEditor: AgGridCellEditor,
      },
    };
  }

  private get rightContent(): ReactNode {
    return (
      <ViewPermission hasPermission={AircraftModuleSecurity.isEditable}>
        <CustomLinkButton
          variant="contained"
          startIcon={<AddIcon />}
          to="new"
          title="Add Airframe"
          disabled={this.isProcessing}
        />
      </ViewPermission>
    );
  }

  public render(): ReactNode {
    return (
      <>
        <SearchHeader
          ref={this.searchHeaderRef}
          searchPlaceHolder={this.searchPlaceHolder}
          searchTypeValue={this.selectedOption}
          searchTypeOptions={this._selectOptions}
          onSearchTypeChange={selectedOption => this._setSelectedOption(selectedOption as AIRFRAME_FILTERS)}
          onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
          rightContent={this.rightContent}
          expandCollapse={() => this.autoSizeColumns()}
        />
        <CustomAgGridReact isRowEditing={this.isRowEditing} rowData={this.data} gridOptions={this.gridOptions} />
      </>
    );
  }
}

export default withRouter(withTheme(Airframe));
export { Airframe as PureAirframe };
