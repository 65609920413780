import React, { ReactNode } from 'react';
import { Utilities, ENTITY_STATE, IClasses, SettingsTypeModel, GRID_ACTIONS, cellStyle } from '@wings-shared/core';
import {
  ColDef,
  GridOptions,
  ICellEditorParams,
  ValueFormatterParams,
  RowEditingStartedEvent,
  GridReadyEvent,
} from 'ag-grid-community';
import { observer } from 'mobx-react';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { AircraftModuleSecurity, PolicyScheduleModel, SettingsProfileModel } from '../../../Shared';
import { withStyles, Typography } from '@material-ui/core';
import { action } from 'mobx';
import { styles } from './ScheduleGrid.styles';
import { ChildGridWrapper, ConfirmDialog, CollapsibleWithButton } from '@wings-shared/layout';
import {
  AgGridCellEditor,
  CustomAgGridReact,
  BaseGrid,
  AgGridActions,
  AgGridCheckBox,
  AgGridAutoComplete,
  ICellInstance,
} from '@wings-shared/custom-ag-grid';

interface Props {
  classes?: IClasses;
  isEditable?: boolean;
  rowData: PolicyScheduleModel[];
  policyList: SettingsProfileModel[];
  onDataSave: (response: PolicyScheduleModel[]) => void;
  onRowEdit: (isEditing: boolean) => void;
  title: string;
}

@observer
class ScheduleGrid extends BaseGrid<Props, PolicyScheduleModel> {
  // Called from Ag Grid Component
  public onInputChange({ colDef }: ICellEditorParams, value: string): void {
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
  }

  /* istanbul ignore next */
  componentDidUpdate(prevProps: Props) {
    if (this.props.isEditable !== prevProps.isEditable) {
      this._setColumnVisible('actionRenderer', Boolean(this.props.isEditable));
    }
  }

  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    const { onRowEdit } = this.props;
    if (rowIndex === null) {
      return;
    }
    switch (gridAction) {
      case GRID_ACTIONS.EDIT:
        this._startEditingCell(rowIndex, this.columnDefs[0].field || '');
        break;
      case GRID_ACTIONS.SAVE:
        this.upsertSchedule(rowIndex);
        onRowEdit(false);
        break;
      case GRID_ACTIONS.CANCEL:
        this.cancelEditing(rowIndex);
        onRowEdit(false);
        break;
      case GRID_ACTIONS.DELETE:
        this.confirmRemoveSchedule(rowIndex);
        break;
      default:
        this.gridApi.stopEditing(true);
        onRowEdit(false);
        break;
    }
  }

  private addNewSchedule(): void {
    this._addNewItems([ new PolicyScheduleModel() ], { startEditing: true, colKey: 'schedule' });
    this.hasError = true;
  }

  private confirmRemoveSchedule(rowIndex: number): void {
    const model: PolicyScheduleModel = this._getTableItem(rowIndex);
    if (model.id === 0) {
      this.deleteSchedule(model);
      return;
    }

    ModalStore.open(
      <ConfirmDialog
        title="Confirm Delete"
        message="Are you sure you want to remove this schedule?"
        yesButton="Delete"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => this.deleteSchedule(model)}
      />
    );
  }

  @action
  public onDropDownChange({ colDef }: ICellEditorParams, value: SettingsProfileModel): void {
    if (Utilities.isEqual(colDef.field || '', 'schedule')) {
      const descInstance: ICellInstance = this.getComponentInstance('description');
      descInstance.setValue(value?.description || '');
    }
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
  }

  /* istanbul ignore next */
  private get profileDropDownOption(): SettingsProfileModel[] {
    const { rowData, policyList } = this.props;
    return policyList.filter(schedule => !rowData.some(policy => policy.schedule.id === schedule.id));
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Schedule',
      field: 'schedule',
      cellEditor: 'customAutoComplete',
      sort: 'asc',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => {
        return value?.label;
      },
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Schedule',
        getAutoCompleteOptions: () => this.profileDropDownOption,
        valueGetter: (option: SettingsTypeModel) => option,
      },
    },
    {
      headerName: 'Description',
      field: 'description',
      cellEditorParams: {
        getDisableState: () => true,
      },
    },
    {
      headerName: 'Default',
      field: 'isDefault',
      cellRenderer: 'checkBoxRenderer',
      cellRendererParams: { readOnly: true },
      cellEditor: 'checkBoxRenderer',
      maxWidth: 130,
    },
    {
      headerName: '',
      field: 'actionRenderer',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      maxWidth: 130,
      sortable: false,
      filter: false,
      suppressSizeToFit: true,
      suppressNavigable: true,
      cellStyle: { ...cellStyle() },
    },
  ];

  /* istanbul ignore next */
  private get gridActionProps(): object {
    return {
      showDeleteButton: true,
      getDisabledState: () => this.hasError,
      getEditableState: () => this.props.isEditable,
      getDeleteDisabledState: (schedule: PolicyScheduleModel) => schedule.isDefault,
      onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
    };
  }

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: AircraftModuleSecurity.isEditable,
      gridActionProps: this.gridActionProps,
    });

    return {
      ...baseOptions,
      suppressClickEdit: true,
      onCellDoubleClicked: ({ rowIndex, colDef }) => {
        const { isEditable } = this.props;
        if (!isEditable) {
          return;
        }
        this._startEditingCell(Number(rowIndex), colDef.field|| '');
      },
      onGridReady: (param: GridReadyEvent) => {
        this._onGridReady(param);
        this._setColumnVisible('actionRenderer', Boolean(this.props.isEditable));
      },
      onRowEditingStarted: (event: RowEditingStartedEvent) => {
        if (this.isProcessing) {
          this.gridApi.stopEditing();
          return;
        }
        this.hasError = true;
        this._startEditingRow(event);
        this.props.onRowEdit(true);
      },
      frameworkComponents: {
        actionRenderer: AgGridActions,
        customCellEditor: AgGridCellEditor,
        checkBoxRenderer: AgGridCheckBox,
        customAutoComplete: AgGridAutoComplete,
      },
    };
  }

  /* istanbul ignore next */
  private cancelEditing(rowIndex: number): void {
    const data: PolicyScheduleModel = this._getTableItem(rowIndex);
    const isNewEntry = Utilities.isEqual(data.entityState || '', ENTITY_STATE.UNCHNAGED);
    this._cancelEditing(rowIndex, isNewEntry);
  }

  /* istanbul ignore next */
  @action
  private upsertSchedule(rowIndex: number): void {
    this.gridApi.stopEditing();
    const data = this._getTableItem(rowIndex);
    const gridData = this._getAllTableRows();
    if (gridData?.length === 1) {
      data.isDefault = true;
    }
    this.data = gridData.map((schedule, i) => {
      const index = gridData.findIndex(a => a.schedule.id === data.schedule.id);
      const isDefault = data.isDefault ? i === index : schedule.isDefault;
      return new PolicyScheduleModel({ ...schedule, isDefault, entityState: ENTITY_STATE.NEW });
    });
    this.gridApi.redrawRows();
    this.props.onDataSave(this.data);
  }

  /* istanbul ignore next */
  @action
  private deleteSchedule(model: PolicyScheduleModel): void {
    ModalStore.close();
    this._removeTableItems([ model ]);
    this.data = this._getAllTableRows();
    this.props.onDataSave(this.data);
  }

  private get defaultTitle(): string {
    const defaultSchedule = this.props.rowData.find(x => x.isDefault)?.schedule;
    return defaultSchedule ? `(${defaultSchedule.label})` : '';
  }

  public render(): ReactNode {
    const { isEditable, rowData, title, classes } = this.props as Required<Props>;
    return (
      <div className={classes.root}>
        <CollapsibleWithButton
          title={title}
          buttonText={`Add ${title}`}
          isButtonDisabled={this.isProcessing || !(AircraftModuleSecurity.isEditable && isEditable)}
          onButtonClick={() => this.addNewSchedule()}
          titleVariant="h6"
          titleChildren={<Typography className={classes.defaultWrapper}>{this.defaultTitle}</Typography>}
        >
          <ChildGridWrapper hasAddPermission={false}>
            <CustomAgGridReact
              isRowEditing={this.isRowEditing}
              rowData={rowData}
              gridOptions={this.gridOptions}
              disablePagination={this.isRowEditing}
            />
          </ChildGridWrapper>
        </CollapsibleWithButton>
      </div>
    );
  }
}

export default withStyles(styles)(ScheduleGrid);
