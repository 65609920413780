import React, { ReactNode, RefObject } from 'react';
import { VIEW_MODE } from '@wings/shared';
import {
  CustomAgGridReact,
  BaseGrid,
  AgGridActions,
  AgGridGroupHeader,
  AgGridChipView,
} from '@wings-shared/custom-ag-grid';
import {
  withRouter,
  Utilities,
  UIStore,
  IClasses,
  ViewPermission,
  SearchStore,
  SettingsTypeModel,
  IBaseGridFilterSetup,
  GRID_ACTIONS,
  cellStyle,
  IAPIGridRequest,
  IGridSortFilter,
  GridPagination,
} from '@wings-shared/core';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import {
  ColDef,
  GridOptions,
  ValueGetterParams,
  ValueFormatterParams,
  FilterChangedEvent,
  SortChangedEvent,
  FilterModifiedEvent,
} from 'ag-grid-community';
import {
  AircraftVariationModel,
  AIRCRAFT_VARIATION_FILTERS,
  AircraftVariationStore,
  AircraftModuleSecurity,
  updateAircraftSidebarOptions,
} from '../Shared';
import { withTheme } from '@material-ui/core';
import { observer, inject } from 'mobx-react';
import { finalize, takeUntil } from 'rxjs/operators';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { SearchHeader, PureSearchHeader } from '@wings-shared/form-controls';
import { CustomLinkButton, ConfirmDialog, SidebarStore } from '@wings-shared/layout';
import { AxiosError } from 'axios';
import { AlertStore } from '@uvgo-shared/alert';
import { aircraftVariationGridFilters } from './fields';

interface Props {
  classes?: IClasses;
  aircraftVariationStore: AircraftVariationStore;
  sidebarStore?: typeof SidebarStore;
}

const filtersSetup: IBaseGridFilterSetup<AIRCRAFT_VARIATION_FILTERS> = {
  defaultPlaceHolder: 'Search by Popular Name',
  filterTypesOptions: Object.values(AIRCRAFT_VARIATION_FILTERS),
  defaultFilterType: AIRCRAFT_VARIATION_FILTERS.POPULAR_NAME,
  apiFilterDictionary: aircraftVariationGridFilters,
};

@inject('aircraftVariationStore', 'sidebarStore')
@observer
class AircraftVariation extends BaseGrid<Props, AircraftVariationModel, AIRCRAFT_VARIATION_FILTERS> {
  public searchHeaderRef: RefObject<PureSearchHeader> = React.createRef<PureSearchHeader>();

  constructor(props) {
    super(props, filtersSetup);
  }

  private get aircraftVariationStore(): AircraftVariationStore {
    return this.props.aircraftVariationStore;
  }

  componentDidMount() {
    this.props.sidebarStore?.setNavLinks(updateAircraftSidebarOptions('Aircraft Variation'), 'aircraft');
    this.loadInitialData();
    this.onAdvanceSearch$().subscribe(() => this.loadInitialData());
    this.setMiniFilterTextDebounce();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const { clientSearchValue } = SearchStore;
    if (clientSearchValue.searchValue) {
      return;
    }
    SearchStore.clearSearch();
  }

  /* istanbul ignore next */
  private loadInitialData(pageRequest?: IAPIGridRequest): void {
    const request: IAPIGridRequest = {
      pageNumber: 1,
      pageSize: 30,
      ...pageRequest,
      ...this._searchFilters,
      ...this._sortFilters,
      ...this._gridAPIAdvancedFilterCollection,
      ...this._gridAPIAdvancedSearchCollection,
    };

    UIStore.setPageLoader(true);
    this.aircraftVariationStore
      .getAircraftVariations(request)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(response => {
        this.pagination = new GridPagination({ ...response });
        this.data = response.results;
        this.gridAdvancedSearchFilterApplied();
      });
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Popular Names',
      field: 'popularNames',
      filter: 'agTextColumnFilter',
      filterParams: this.nameSearchFilterParams('contains', 'popularNames', 2),
      cellRenderer: 'agGridChipView',
      valueFormatter: ({ value }: ValueFormatterParams) => value?.name,
      filterValueGetter: ({ data }: ValueGetterParams) => data.popularNames?.map(v => v.label).join(', ') || '',
    },
    {
      headerName: 'Make',
      field: 'make',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: 'agTextColumnFilter',
      filterParams: this.nameSearchFilterParams('contains', 'make', 2),
      filterValueGetter: ({ data }: ValueGetterParams) => data.make.name,
    },
    {
      headerName: 'Model',
      field: 'model',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: 'agTextColumnFilter',
      filterParams: this.nameSearchFilterParams('contains', 'model', 2),
      filterValueGetter: ({ data }: ValueGetterParams) => data.model.name,
    },
    {
      headerName: 'Series',
      field: 'series',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: 'agTextColumnFilter',
      filterParams: this.nameSearchFilterParams('contains', 'series', 2),
      filterValueGetter: ({ data }: ValueGetterParams) => data.series.name,
    },
    {
      headerName: 'Engine Type',
      field: 'engineType',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: 'agTextColumnFilter',
      filterParams: this.nameSearchFilterParams('contains', 'engineType', 2),
      filterValueGetter: ({ data }: ValueGetterParams) => data.engineType.name,
    },
    {
      headerName: 'ICAO Type Designator',
      field: 'icaoTypeDesignator',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: 'agTextColumnFilter',
      filterParams: this.nameSearchFilterParams('contains', 'icaoTypeDesignator', 2),
      filterValueGetter: ({ data }: ValueGetterParams) => data.icaoTypeDesignator?.label,
    },
    ...this.auditFieldsWithAdvanceFilter,
    {
      headerName: 'Action',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      sortable: false,
      filter: false,
      minWidth: 150,
      maxWidth: 210,
      suppressSizeToFit: true,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        isActionMenu: true,
        actionMenus: () => [
          {
            title: 'Edit',
            isHidden: !AircraftModuleSecurity.isEditable,
            action: GRID_ACTIONS.EDIT,
            to: node => `/aircraft/aircraft-variation/${node?.data.id}/${VIEW_MODE.EDIT.toLowerCase()}`,
          },
          {
            title: 'Details',
            action: GRID_ACTIONS.DETAILS,
            to: node => `/aircraft/aircraft-variation/${node?.data.id}/${VIEW_MODE.DETAILS.toLowerCase()}`,
          },
          {
            title: 'Delete',
            isHidden: !AircraftModuleSecurity.isEditable,
            action: GRID_ACTIONS.DELETE,
          },
        ],
        onAction: (action: GRID_ACTIONS, rowIndex: number) => {
          if (this.searchValue) {
            const clientSearchValue = { selectedOption: this.selectedOption, searchValue: this.searchValue as string };
            SearchStore.setclientSearchValue(clientSearchValue);
          }
          this.onAction(action, rowIndex);
        },
      },
    },
  ];

  private onAction(action: GRID_ACTIONS, rowIndex: number): void {
    if (Utilities.isEqual(action, GRID_ACTIONS.DELETE)) {
      this.confirmDeleteAction(rowIndex);
    }
  }

  /* istanbul ignore next */
  private deleteAircraftVariationRecord(model: AircraftVariationModel): void {
    UIStore.setPageLoader(true);
    this.props.aircraftVariationStore
      .deleteAircraftVariationRecord(model.id)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: response => {
          this._removeTableItems([ model ]);
          this.data = this.data.filter(({ id }) => model.id !== id);
        },
        error: (error: AxiosError) => AlertStore.critical(error.message),
      });
  }

  /* istanbul ignore next */
  private confirmDeleteAction(rowIndex: number): void {
    const model: AircraftVariationModel = this._getTableItem(rowIndex);
    ModalStore.open(
      <ConfirmDialog
        title="Confirm Delete"
        message="Are you sure you want to remove this record?"
        yesButton="Delete"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => {
          ModalStore.close();
          this.deleteAircraftVariationRecord(model);
        }}
      />
    );
  }

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: false,
      gridActionProps: {
        tooltip: 'Aircraft Variation',
        getDisabledState: () => this.hasError,
      },
    });

    return {
      ...baseOptions,
      pagination: false,
      isExternalFilterPresent: () => false,
      onFilterChanged: (events: FilterChangedEvent) => {
        this.gridAdvancedSearchFilters.length === 0 && this.loadInitialData();
      },
      onSortChanged: ({ api }: SortChangedEvent) => {
        this.sortFilters = api.getSortModel() as IGridSortFilter[];
        this.loadInitialData();
      },
      onFilterModified: (filterModified: FilterModifiedEvent) => this.onGridApiFilterModified(filterModified),
      frameworkComponents: {
        actionRenderer: AgGridActions,
        customHeader: AgGridGroupHeader,
        agGridChipView: AgGridChipView,
      },
    };
  }

  private get rightContent(): ReactNode {
    return (
      <ViewPermission hasPermission={AircraftModuleSecurity.isEditable}>
        <CustomLinkButton
          variant="contained"
          startIcon={<AddIcon />}
          to="new"
          title="Add Aircraft Variation"
          disabled={this.isProcessing}
        />
      </ViewPermission>
    );
  }

  public render(): ReactNode {
    return (
      <>
        <SearchHeader
          ref={this.searchHeaderRef}
          searchTypeValue={this.selectedOption}
          searchTypeOptions={this._selectOptions}
          onSearchTypeChange={option => this._setSelectedOption(option as AIRCRAFT_VARIATION_FILTERS)}
          onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
          onResetFilterClick={() => this.onFilterResetClickHandler()}
          rightContent={this.rightContent}
          isDisabled={Boolean(this.gridAdvancedSearchFilters.length)}
          expandCollapse={() => this.autoSizeColumns()}
        />
        <CustomAgGridReact
          isRowEditing={this.isRowEditing}
          rowData={this.data}
          gridOptions={this.gridOptions}
          serverPagination={true}
          paginationData={this.pagination}
          onPaginationChange={request => this.loadInitialData(request)}
        />
      </>
    );
  }
}

export default withRouter(withTheme(AircraftVariation));
export { AircraftVariation as PureAircraftVariation };
