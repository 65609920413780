import { createStyles, makeStyles } from '@material-ui/core';

export const styles = createStyles(() => ({
  root: {
    flex: 1,
  },
  gridWrapper: {
    minHeight: 200,
  },
}));

export const useStyles = makeStyles({
  root: {
    flex: 1,
  },
  gridWrapper: {
    minHeight: 200,
  },
});
