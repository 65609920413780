import { BaseUpsertComponent, ModelStatusOptions, VIEW_MODE } from '@wings/shared';
import {
  EDITOR_TYPES,
  ViewInputControl,
  AuditFields,
  IViewInputControl,
  IGroupInputControls,
} from '@wings-shared/form-controls';
import {
  withRouter,
  Utilities,
  DATE_FORMAT,
  UIStore,
  IClasses,
  IAPIPageResponse,
  IAPIGridRequest,
  IOptionValue,
  ISelectOption,
  ViewPermission,
  SettingsTypeModel,
  GRID_ACTIONS,
} from '@wings-shared/core';
import { AuthStore } from '@wings-shared/security';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { NavigateFunction } from 'react-router';
import {
  AircraftModuleSecurity,
  FlightPlanFormatAccountModel,
  FlightPlanFormatChangeRecordModel,
  FlightPlanFormatDocumentModel,
  FlightPlanModel,
  FlightPlanStore,
  SettingsStore,
  updateAircraftSidebarOptions,sidebarMenu
} from '../../../Shared';
import { fields } from './Fields';
import { ArrowBack } from '@material-ui/icons';
import { styles } from './FlightPlanEditor.style';
import { forkJoin, Observable } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { withStyles } from '@material-ui/core';
import { AlertStore } from '@uvgo-shared/alert';
import { AxiosError } from 'axios';
import {
  FlightPlanFormatMasterDetails,
  FlightPlanViewInputControls,
  FlightPlanChangeRecordGrid,
  FlightPlanDocumentGrid,
} from '../index';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import {
  CustomLinkButton,
  EditSaveButtons,
  DetailsEditorWrapper,
  ConfirmDialog,
  Collapsable,
  SidebarStore,
} from '@wings-shared/layout';

interface Props {
  classes?: IClasses;
  viewMode?: VIEW_MODE;
  flightPlanStore?: FlightPlanStore;
  settingsStore?: SettingsStore;
  params?: { mode: VIEW_MODE; id: number };
  navigate?: NavigateFunction;
  sidebarStore?: typeof SidebarStore
}

@inject('flightPlanStore', 'settingsStore','sidebarStore')
@observer
class FlightPlanEditor extends BaseUpsertComponent<Props, FlightPlanModel> {
  @observable private flightPlan: FlightPlanModel = new FlightPlanModel();
  @observable private flightPlanDetails: FlightPlanModel = new FlightPlanModel();
  @observable private editingGrid: string[] = [];

  constructor(p: Props) {
    super(p, fields);
    const mode: string = this.props.params?.mode?.toUpperCase() || '';
    this.viewMode = VIEW_MODE[mode] || VIEW_MODE.DETAILS;
  }

  componentDidMount() {
    this.props.sidebarStore?.setNavLinks(
      updateAircraftSidebarOptions('Flight Plan Format'),
      'aircraft'
    );
    UIStore.setPageLoader(true);
    forkJoin([
      this.loadFlightPlanById(),
      this.flightPlanStore.getFlightPlans(),
      this.settingsStore.getFlightPlanFormatStatus(),
      this.settingsStore.getSourceTypes(),
      this.settingsStore.getAccessLevels(),
    ])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(([ flightPlan ]) => {
        this.flightPlan = new FlightPlanModel(flightPlan.results[0]);
        this.flightPlanDetails = new FlightPlanModel({ ...this.flightPlan });
        this.setBuiltByValidationRules(this.flightPlan.flightPlanFormatStatus);
        this.setFormValues(this.flightPlan);
      });
  }

  /* istanbul ignore next */
  @action
  private updateRowEditing(isEditing: boolean, girdName: string): void {
    if (isEditing) {
      this.editingGrid.push(girdName);
      return;
    }
    this.editingGrid = [ ...this.editingGrid.filter(a => !Utilities.isEqual(a, girdName)) ];
  }

  private get isRowEditing(): boolean {
    return Boolean(this.editingGrid.length);
  }

  private loadFlightPlanById(): Observable<IAPIPageResponse<FlightPlanModel>> {
    const filterCollection = JSON.stringify([
      { propertyName: 'FlightPlanFormatId', propertyValue: this.flightPlanId || 0 },
    ]);

    const request: IAPIGridRequest = {
      pageNumber: 1,
      pageSize: 10,
      filterCollection,
    };
    return this.flightPlanStore.getFlightPlanById(request);
  }

  private get flightPlanStore(): FlightPlanStore {
    return this.props.flightPlanStore as FlightPlanStore;
  }

  private get settingsStore(): SettingsStore {
    return this.props.settingsStore as SettingsStore;
  }

  private get flightPlanId(): number {
    return Number(this.props.params?.id);
  }

  private get isFpfOpenButtonDisabled(): boolean {
    const fpfStatus = this.getField('flightPlanFormatStatus').value;
    return Utilities.isEqual(fpfStatus?.label, 'Open') || this.flightPlanId === 0 || this.isDetailView;
  }

  public get hasError(): boolean {
    return this.form.hasError || this.isRowEditing;
  }

  protected get isEditable(): boolean {
    return this.viewMode === VIEW_MODE.EDIT || this.viewMode === VIEW_MODE.NEW;
  }

  private get groupInputControls(): IGroupInputControls {
    return {
      title: 'General',
      inputControls: [
        {
          fieldKey: 'format',
          type: EDITOR_TYPES.TEXT_FIELD,
          isDisabled: Boolean(this.flightPlanId),
        },
        {
          fieldKey: 'flightPlanFormatStatus',
          type: EDITOR_TYPES.DROPDOWN,
          options: this.settingsStore.flightPlanFormatStatus,
        },
        {
          fieldKey: 'contactForChanges',
          type: EDITOR_TYPES.TEXT_FIELD,
        },
        {
          fieldKey: 'builtBy',
          type: EDITOR_TYPES.TEXT_FIELD,
        },
        {
          fieldKey: 'builtDate',
          type: EDITOR_TYPES.DATE,
          dateTimeFormat: DATE_FORMAT.DATE_PICKER_FORMAT,
        },
        {
          fieldKey: 'lastUsedDate',
          type: EDITOR_TYPES.DATE,
          dateTimeFormat: DATE_FORMAT.DATE_PICKER_FORMAT,
        },
        {
          fieldKey: 'accessLevel',
          type: EDITOR_TYPES.DROPDOWN,
          options: this.settingsStore.accessLevels,
        },
        {
          fieldKey: 'includeEscapeRoutes',
          type: EDITOR_TYPES.SELECT_CONTROL,
          isBoolean: true,
          excludeEmptyOption: true,
          containerClass: this.props.classes?.containerClass,
        },
        {
          fieldKey: 'notes',
          type: EDITOR_TYPES.TEXT_FIELD,
          multiline: true,
          rows: 4,
          isFullFlex: true,
        },
      ],
    };
  }

  private get systemInputControls(): IViewInputControl[] {
    return [
      {
        fieldKey: 'sourceType',
        type: EDITOR_TYPES.DROPDOWN,
        options: this.settingsStore.sourceTypes,
      },
      {
        fieldKey: 'status',
        type: EDITOR_TYPES.DROPDOWN,
        options: ModelStatusOptions,
      },
    ];
  }

  private isAlreadyExists(flightPlan: FlightPlanModel): boolean {
    const { flightPlans } = this.flightPlanStore;
    return flightPlans.some(
      x => Utilities.isEqual(x.format, flightPlan.format) && !Utilities.isEqual(x.id, flightPlan.id)
    );
  }

  /* istanbul ignore next */
  private upsertFlightPlan(redirect: boolean = true): void {
    const model = this.getUpdatedModel();
    if (this.isAlreadyExists(model)) {
      this.showAlert('Format should be unique', 'flightPlanAlert');
      return;
    }
    UIStore.setPageLoader(true);
    this.flightPlanStore
      .upsertFlightPlan(model)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: () => {
          if (redirect) {
            this.navigateToFlightPlan();
            return;
          }
          this.setViewMode(VIEW_MODE.DETAILS);
        },
        error: (error: AxiosError) => AlertStore.critical(error.message),
      });
  }

  private getUpdatedModel(): FlightPlanModel {
    const formValues: FlightPlanModel = this.form.values();
    const { flightPlanFormatDocuments, flightPlanFormatChangeRecords, flightPlanFormatAccounts } = this.flightPlan;
    return new FlightPlanModel({
      ...this.flightPlan,
      ...formValues,
      flightPlanFormatDocuments: flightPlanFormatDocuments.map(({ id, ...rest }) => {
        return new FlightPlanFormatDocumentModel({ id: Math.floor(id), ...rest });
      }),
      flightPlanFormatChangeRecords: flightPlanFormatChangeRecords.map(({ id, ...rest }) => {
        return new FlightPlanFormatChangeRecordModel({ id: Math.floor(id), ...rest });
      }),
      flightPlanFormatAccounts: flightPlanFormatAccounts.map(({ id, ...rest }) => {
        return new FlightPlanFormatAccountModel({ id: Math.floor(id), ...rest });
      }),
    });
  }

  @action
  protected onCancel(model: FlightPlanModel): void {
    const viewMode = this.props.params?.mode.toUpperCase();
    if (viewMode === VIEW_MODE.DETAILS) {
      this.viewMode = VIEW_MODE.DETAILS;
      this.setFormValues(model);
      this.flightPlan = new FlightPlanModel({ ...model });
      return;
    }
    this.navigateToFlightPlan();
  }

  private navigateToFlightPlan(): void {
    this.props.navigate && this.props.navigate('/aircraft');
  }

  @action
  protected onValueChange(value: IOptionValue | IOptionValue[], fieldKey: string): void {
    if (Utilities.isEqual(fieldKey, 'flightPlanFormatStatus')) {
      this.setBuiltByValidationRules(value as ISelectOption);
    }
    this.getField(fieldKey).set(value);
  }

  private setBuiltByValidationRules(option: ISelectOption): void {
    const isRequired: boolean = Utilities.isEqual(option?.label, 'Assigned');
    this.setFormRules('builtBy', isRequired, 'Built By');
  }

  private get headerActions(): ReactNode {
    return (
      <>
        <ViewPermission hasPermission={!this.isEditable}>
          <CustomLinkButton to="/aircraft" title="Flight Plan" startIcon={<ArrowBack />} />
        </ViewPermission>
        <EditSaveButtons
          disabled={this.hasError || UIStore.pageLoading}
          hasEditPermission={AircraftModuleSecurity.isEditable}
          isEditing={this.isRowEditing}
          isEditMode={this.isEditable}
          onAction={action => this.onAction(action)}
        />
      </>
    );
  }

  private getConfirmation(): void {
    ModalStore.open(
      <ConfirmDialog
        title="Confirm Changes"
        message={`Do you want to set the status of format ${this.flightPlan.format} to Open?`}
        yesButton="Confirm"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => {
          ModalStore.close();
          this.setFlightPlanValues();
        }}
      />
    );
  }

  @action
  private setFlightPlanValues(): void {
    const flightPlanFormatStatus = new SettingsTypeModel({ id: 2, name: 'Open' });
    const changeRecord = new FlightPlanFormatChangeRecordModel({
      requestedBy: 'Internal',
      changedBy: AuthStore.user?.name,
      notes: 'Set to Open',
      changedDate: Utilities.getCurrentDate,
    });
    this.updateFlightPlanAccounts([]);
    this.updateFlightPlanChangeRecords([ ...this.flightPlan?.flightPlanFormatChangeRecords, ...[ changeRecord ] ]);
    const flightPlan = new FlightPlanModel({
      ...this.flightPlan,
      contactForChanges: '',
      builtBy: '',
      builtDate: '',
      notes: '',
      lastUsedDate: '',
      flightPlanFormatStatus,
    });
    this.setBuiltByValidationRules(flightPlanFormatStatus);
    this.setFormValues(flightPlan);
    this.flightPlanDetails = new FlightPlanModel({ ...this.getUpdatedModel() });
    this.upsertFlightPlan(false);
  }

  private onAction(action: GRID_ACTIONS): void {
    switch (action) {
      case GRID_ACTIONS.EDIT:
        this.viewMode = VIEW_MODE.EDIT;
        break;
      case GRID_ACTIONS.SAVE:
        this.upsertFlightPlan();
        break;
      case GRID_ACTIONS.CANCEL:
        this.onCancel(this.flightPlanDetails);
        break;
    }
  }

  @action
  private updateFlightPlanAccounts(flightPlanFormatAccounts: FlightPlanFormatAccountModel[]): void {
    this.flightPlan = new FlightPlanModel({
      ...this.flightPlan,
      flightPlanFormatAccounts,
    });
  }

  @action
  private updateFlightPlanChangeRecords(flightPlanFormatChangeRecords: FlightPlanFormatChangeRecordModel[]): void {
    this.flightPlan = new FlightPlanModel({
      ...this.flightPlan,
      flightPlanFormatChangeRecords,
    });
  }

  @action
  private updateFlightPlanDocuments(flightPlanFormatDocuments: FlightPlanFormatDocumentModel[]): void {
    this.flightPlan = new FlightPlanModel({
      ...this.flightPlan,
      flightPlanFormatDocuments,
    });
  }

  private get flightPlanChildGrid(): ReactNode {
    return (
      <>
        <FlightPlanFormatMasterDetails
          isEditable={this.isEditable}
          flightPlanFormatAccounts={this.flightPlan.flightPlanFormatAccounts}
          onDataSave={flightPlanFormatAccounts => this.updateFlightPlanAccounts(flightPlanFormatAccounts)}
          onRowEditing={isEditing => this.updateRowEditing(isEditing, 'flightPlanFormatAccounts')}
        />
        <FlightPlanChangeRecordGrid
          isEditable={this.isEditable}
          flightPlanFormatChangeRecords={this.flightPlan.flightPlanFormatChangeRecords}
          onDataSave={flightPlanFormatChangeRecords =>
            this.updateFlightPlanChangeRecords(flightPlanFormatChangeRecords)
          }
          onRowEditing={isEditing => this.updateRowEditing(isEditing, 'flightPlanFormatChangeRecords')}
        />
        <FlightPlanDocumentGrid
          isEditable={this.isEditable}
          flightPlanFormatDocuments={this.flightPlan.flightPlanFormatDocuments}
          onDataSave={flightPlanFormatDocuments => this.updateFlightPlanDocuments(flightPlanFormatDocuments)}
          onRowEditing={isEditing => this.updateRowEditing(isEditing, 'flightPlanFormatDocuments')}
        />
      </>
    );
  }

  private get systemDataFields(): ReactNode {
    const { classes } = this.props as Required<Props>;
    return (
      <Collapsable title="System">
        <>
          <div className={classes.flexWrap}>
            {this.systemInputControls
              .filter(inputControl => !inputControl.isHidden)
              .map((inputControl: IViewInputControl, index: number) => (
                <ViewInputControl
                  {...inputControl}
                  key={index}
                  field={this.getField(inputControl.fieldKey || '')}
                  isEditable={this.isEditable}
                  onValueChange={(option, _) => this.onValueChange(option, inputControl.fieldKey || '')}
                />
              ))}
          </div>
          <AuditFields
            isEditable={this.isEditable}
            fieldControls={this.auditFields}
            onGetField={(fieldKey: string) => this.getField(fieldKey)}
            isNew={this.isAddNew}
          />
        </>
      </Collapsable>
    );
  }

  private get title(): string {
    return this.getField('format').value || 'Name';
  }

  public render(): ReactNode {
    const { classes } = this.props as Required<Props>;
    return (
      <DetailsEditorWrapper headerActions={this.headerActions} isEditMode={this.isEditable}>
        <div className={classes.flexRow}>
          <FlightPlanViewInputControls
            title={this.title}
            isEditable={this.isEditable}
            groupInputControls={this.groupInputControls}
            onGetField={(fieldKey: string) => this.getField(fieldKey)}
            onValueChange={(option, fieldKey) => this.onValueChange(option, fieldKey)}
            onButtonClick={() => this.getConfirmation()}
            isFpfOpenButtonDisabled={this.isFpfOpenButtonDisabled}
          />
          {this.flightPlanChildGrid}
          {this.systemDataFields}
        </div>
      </DetailsEditorWrapper>
    );
  }
}

export default withRouter(withStyles(styles)(FlightPlanEditor));
export { FlightPlanEditor as PureFlightPlanEditor };
