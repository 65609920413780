import React, { ReactNode } from 'react';
import { VIEW_MODE } from '@wings/shared';
import { ColDef, GridOptions, RowNode, ValueGetterParams, ValueFormatterParams } from 'ag-grid-community';
import {
  EtpPolicyStore,
  EtpPolicyModel,
  ETP_POLICY_FILTERS,
  ImportFileData,
  AircraftModuleSecurity,
  updateAircraftSidebarOptions,
} from '../Shared';
import { Chip, withStyles } from '@material-ui/core';
import { observer, inject } from 'mobx-react';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { finalize, takeUntil } from 'rxjs/operators';
import { styles } from './EtpPolicies.styles';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete';
import {
  IClasses,
  UIStore,
  withRouter,
  ViewPermission,
  SearchStore,
  GRID_ACTIONS,
  IBaseGridFilterSetup,
  cellStyle,
} from '@wings-shared/core';
import { CustomLinkButton, SidebarStore } from '@wings-shared/layout';
import { SearchHeader } from '@wings-shared/form-controls';
import {
  AgGridViewRenderer,
  CustomAgGridReact,
  BaseGrid,
  AgGridActions,
  AgGridGroupHeader,
} from '@wings-shared/custom-ag-grid';

interface Props {
  classes?: IClasses;
  etpPolicyStore: EtpPolicyStore;
  sidebarStore?: typeof SidebarStore;
}

const filtersSetup: IBaseGridFilterSetup<ETP_POLICY_FILTERS> = {
  defaultPlaceHolder: 'Search by Policy Code',
  filterTypesOptions: Object.values(ETP_POLICY_FILTERS),
  defaultFilterType: ETP_POLICY_FILTERS.CODE,
};

@inject('etpPolicyStore', 'sidebarStore')
@observer
class EtpPolicy extends BaseGrid<Props, EtpPolicyModel, ETP_POLICY_FILTERS> {
  constructor(props) {
    super(props, filtersSetup);
  }

  private get etpPolicyStore(): EtpPolicyStore {
    return this.props.etpPolicyStore;
  }

  componentDidMount() {
    this.props.sidebarStore?.setNavLinks(updateAircraftSidebarOptions('ETP Policy'), 'aircraft');
    this.loadInitialData();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const { clientSearchValue } = SearchStore;
    if (clientSearchValue.searchValue) {
      return;
    }
    SearchStore.clearSearch();
  }

  /* istanbul ignore next */
  private loadInitialData(): void {
    UIStore.setPageLoader(true);
    this.etpPolicyStore
      .getEtpPolicies(true)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(EtpScenarios => {
        this.data = EtpScenarios;
        const { clientSearchValue } = SearchStore;
        const { selectedOption, searchValue } = clientSearchValue;
        if (searchValue) {
          this._setSelectedOption((selectedOption as ETP_POLICY_FILTERS) || ETP_POLICY_FILTERS.CODE);
          this._setSearchValue(searchValue);
          this.searchHeaderRef.current?.setSearchValue(searchValue);
          SearchStore.clearSearch();
          return;
        }
        SearchStore.clearSearch();
      });
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Policy Code',
      field: 'code',
      filter: true,
    },
    {
      headerName: 'ETP Scenario',
      field: 'etpScenarios',
      cellRenderer: 'viewRenderer',
      minWidth: 450,
      filter: true,
      filterValueGetter: ({ data }: ValueGetterParams) => data.etpScenarios?.map(x => x.label).join(','),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.map(x => x.label).join(',') || '',
      cellRendererParams: {
        getViewRenderer: (rowIndex: number, node: RowNode, classes: IClasses) =>
          this.viewRenderer(node.data?.etpScenarios.map(x => x.label)),
      },
    },
    {
      headerName: 'Description',
      field: 'description',
      filter: true,
    },
    ...this.auditFields,
    {
      headerName: 'Action',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      sortable: false,
      filter: false,
      minWidth: 150,
      maxWidth: 210,
      suppressSizeToFit: true,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        isActionMenu: true,
        actionMenus: () => [
          {
            title: 'Edit',
            isHidden: !AircraftModuleSecurity.isEditable,
            action: GRID_ACTIONS.EDIT,
            to: node => `/aircraft/etp-policy/${node?.data.id}/${VIEW_MODE.EDIT.toLowerCase()}`,
          },
          {
            title: 'Details',
            action: GRID_ACTIONS.DETAILS,
            to: node => `/aircraft/etp-policy/${node?.data.id}/${VIEW_MODE.DETAILS.toLowerCase()}`,
          },
        ],
        onAction: () => {
          if (this.searchValue) {
            const clientSearchValue = { selectedOption: this.selectedOption, searchValue: this.searchValue as string };
            SearchStore.setclientSearchValue(clientSearchValue);
          }
        },
      },
    },
  ];

  private viewRenderer(etpScenario: string[], getTagProps?: AutocompleteGetTagProps): ReactNode {
    return etpScenario
      .sort((a, b) => Number(a) - Number(b))
      .map((etpScenario: string, index) => (
        <Chip
          classes={{ root: this.props.classes?.root }}
          key={etpScenario}
          label={etpScenario}
          {...(getTagProps instanceof Function ? getTagProps({ index }) : {})}
        />
      ));
  }

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: false,
      gridActionProps: {
        tooltip: 'ETP Policy',
        getDisabledState: () => this.hasError,
      },
    });

    return {
      ...baseOptions,
      doesExternalFilterPass: node => {
        const { etpScenarios, id, description, code } = node.data as EtpPolicyModel;
        const scenarioFilterOption = [ etpScenarios.map(a => a.name).find(x => x === this.searchValue) ];
        return (
          !id ||
          this._isFilterPass({
            [ETP_POLICY_FILTERS.CODE]: code,
            [ETP_POLICY_FILTERS.SCENARIO_NUMBER]: scenarioFilterOption as string[],
            [ETP_POLICY_FILTERS.DESCRIPTION]: description,
          })
        );
      },
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
      },
      frameworkComponents: {
        actionRenderer: AgGridActions,
        viewRenderer: AgGridViewRenderer,
        customHeader: AgGridGroupHeader,
      },
    };
  }

  private onRequestImportEtpPolicies(): void {
    const { etpPolicyStore } = this.props;
    ModalStore.open(
      <ImportFileData
        onImportFileData={file => etpPolicyStore.importEtpPolicies(file)}
        onImportDone={() => this.loadInitialData()}
        title="Import ETP Policies"
        successMessage="All ETP Policies Imported successfully!"
      />
    );
  }

  private get rightContent(): ReactNode {
    return (
      <ViewPermission hasPermission={AircraftModuleSecurity.isEditable}>
        <>
          <CustomLinkButton
            variant="contained"
            startIcon={<AddIcon />}
            to="new"
            title="Add ETP Policy"
            disabled={this.isProcessing}
          />
          <PrimaryButton
            variant="contained"
            color="primary"
            startIcon={<CloudUploadIcon />}
            onClick={() => this.onRequestImportEtpPolicies()}
            disabled={this.isProcessing}
          >
            Import ETP Policy
          </PrimaryButton>
        </>
      </ViewPermission>
    );
  }

  public render(): ReactNode {
    return (
      <>
        <SearchHeader
          ref={this.searchHeaderRef}
          searchPlaceHolder={this.searchPlaceHolder}
          searchTypeValue={this.selectedOption}
          searchTypeOptions={this._selectOptions}
          onSearchTypeChange={selectedOption => this._setSelectedOption(selectedOption as ETP_POLICY_FILTERS)}
          onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
          rightContent={this.rightContent}
          expandCollapse={() => this.autoSizeColumns()}
        />
        <CustomAgGridReact
          isRowEditing={this.isRowEditing}
          rowData={this.data}
          gridOptions={this.gridOptions}
          disablePagination={this.isRowEditing}
        />
      </>
    );
  }
}

export default withRouter(withStyles(styles)(EtpPolicy));
export { EtpPolicy as PureEtpPolicy };
