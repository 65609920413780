import React, { ReactNode } from 'react';
import {
  CustomAgGridReact,
  BaseGrid,
} from '@wings-shared/custom-ag-grid';
import { ColDef, GridOptions, ValueFormatterParams } from 'ag-grid-community';
import { observer } from 'mobx-react';
import { GenericRegistryModel } from '../../../Shared';
import { withStyles } from '@material-ui/core';
import { styles } from './GenericRegistryGrid.styles';
import { IClasses, Utilities, SettingsTypeModel } from '@wings-shared/core';
import { ChildGridWrapper, Collapsable } from '@wings-shared/layout';

interface Props {
  classes?: IClasses;
  rowData: GenericRegistryModel[];
}

@observer
class GenericRegistryGrid extends BaseGrid<Props, GenericRegistryModel> {
  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Generic registry',
      field: 'name',
    },
    {
      headerName: 'Weight UOM',
      field: 'weightUOM',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => {
        return value?.label;
      },
    },
  ];

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    return this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: false,
    });
  }

  public render(): ReactNode {
    const { classes } = this.props as Required<Props>;
    return (
      <div className={classes.root}>
        <Collapsable title="Generic Registry" titleVariant="h6">
          <ChildGridWrapper hasAddPermission={false}>
            <CustomAgGridReact
              isRowEditing={this.isRowEditing}
              rowData={this.props.rowData}
              gridOptions={this.gridOptions}
              disablePagination={this.isRowEditing}
            />
          </ChildGridWrapper>
        </Collapsable>
      </div>
    );
  }
}

export default withStyles(styles)(GenericRegistryGrid);
export { GenericRegistryGrid as PureGenericRegistryGrid };
