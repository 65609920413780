import React, { FC, ReactNode, RefObject, useRef, useState } from 'react';
import { ColDef, GridOptions, ValueGetterParams, ValueFormatterParams, RowNode } from 'ag-grid-community';
import { AircraftVariationModel, VARIATION_SEARCH_FILTERS } from '../../../Shared';
import { Select, MenuItem, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { useStyles } from './VariationSearch.styles';
import { ISearchHeaderRef, SearchHeaderV2 } from '@wings-shared/form-controls';
import { Utilities, SettingsTypeModel } from '@wings-shared/core';
import { CustomAgGridReact, useAgGrid, useGridState } from '@wings-shared/custom-ag-grid';

interface Props {
  data: AircraftVariationModel[];
  onSelect: (selectedVariation: AircraftVariationModel) => void;
}

const VariationSearchV2: FC<Props> = ({ data, onSelect }) => {
  const classes = useStyles();
  const gridState = useGridState();
  const agGrid = useAgGrid<VARIATION_SEARCH_FILTERS, AircraftVariationModel>([], gridState);
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  const [ currentPageSize, SetCurrentPageSize ] = useState(30);

  /* istanbul ignore next */
  const columnDefs: ColDef[] = [
    {
      headerName: 'ICAO Type Designator',
      field: 'icaoTypeDesignator',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterValueGetter: ({ data }: ValueGetterParams) => data.icaoTypeDesignator?.label,
    },
    {
      headerName: 'A/C Make',
      field: 'make',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterValueGetter: ({ data }: ValueGetterParams) => data.make.label,
    },
    {
      headerName: 'A/C Model',
      field: 'model',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterValueGetter: ({ data }: ValueGetterParams) => data.model.label,
    },
    {
      headerName: 'Series',
      field: 'series',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterValueGetter: ({ data }: ValueGetterParams) => data.series.label,
    },
    {
      headerName: 'Engine Type',
      field: 'engineType',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterValueGetter: ({ data }: ValueGetterParams) => data.engineType.label,
    },
    {
      headerName: 'Popular Names',
      field: 'popularNames',
      cellRenderer: 'agGridChipView',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterValueGetter: ({ data }: ValueGetterParams) => data.popularNames.map(a => a.label).toString(),
    },
    {
      headerName: '',
      field: 'action',
      cellRenderer: 'viewRenderer',
      filter: false,
      minWidth: 100,
      cellRendererParams: {
        getViewRenderer: (rowIndex: number, node: RowNode) => viewRenderer(node.data),
      },
    },
  ];

  /* istanbul ignore next */
  const gridOptions = (): GridOptions => {
    const baseOptions: Partial<GridOptions> = agGrid.gridOptionsBase({
      context: {},
      columnDefs,
      isEditable: false,
      gridActionProps: {
        tooltip: 'Aircraft Variation',
        getDisabledState: () => gridState.hasError,
      },
    });

    return {
      ...baseOptions,
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
      },
      paginationPageSize: currentPageSize,
      isExternalFilterPresent: () => searchHeaderRef.current?.hasSearchValue || false,
      doesExternalFilterPass: node => {
        const searchHeader = searchHeaderRef.current;
        if (!searchHeader) {
          return false;
        }
        const { icaoTypeDesignator, make, model, series, engineType } = node.data as AircraftVariationModel;
        return agGrid.isFilterPass(
          {
            [VARIATION_SEARCH_FILTERS.ALL]: [
              icaoTypeDesignator.label,
              make.label,
              model.label,
              series.label,
              engineType.label,
            ],
          },
          searchHeader.searchValue,
          searchHeader.selectedOption
        );
      },
    };
  };

  /* istanbul ignore next */
  const viewRenderer = (rowData: AircraftVariationModel): ReactNode => {
    return (
      <PrimaryButton variant="text" color="primary" onClick={() => onSelect(rowData)}>
        Select
      </PrimaryButton>
    );
  };

  const resetFilter = (): void => {
    searchHeaderRef.current?.resetInputs();
    columnDefs.forEach(col => {
      const filterComponent = gridState.gridApi.getFilterInstance(col.field || '');
      filterComponent?.setModel(null);
      gridState.gridApi.onFilterChanged();
    });
  };

  const onPageSizeChange = (pageSize: number): void => {
    SetCurrentPageSize(pageSize);
    gridState.gridApi.paginationSetPageSize(pageSize);
  };

  return (
    <div className={classes.container}>
      <div className={classes.gridControlContainer}>
        <div className={classes.dropDownContainer}>
          <Typography>Show</Typography>
          <Select
            value={currentPageSize}
            onChange={event => onPageSizeChange(event.target.value as number)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            className={classes.dropdown}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </Select>
          <Typography>entries</Typography>
        </div>
        <div className={classes.searchInputContainer}>
          <SearchHeaderV2
            ref={searchHeaderRef as RefObject<ISearchHeaderRef>}
            selectInputs={[]}
            onFilterChange={() => gridState.gridApi.onFilterChanged()}
          />
          <PrimaryButton variant="text" color="primary" onClick={resetFilter}>
            Clear filter
          </PrimaryButton>
        </div>
      </div>
      <CustomAgGridReact
        isRowEditing={gridState.isRowEditing}
        rowData={data}
        gridOptions={gridOptions()}
        disablePagination={gridState.isRowEditing}
      />
    </div>
  );
};

export default observer(VariationSearchV2);
