import React, { ReactNode, RefObject } from 'react';
import { withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { BaseUpsertComponent, VIEW_MODE } from '@wings/shared';
import { Dialog } from '@uvgo-shared/dialog';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import {
  AircraftModuleSecurity,
  EtpPenaltyModel,
  EtpScenarioDetailModel,
  EtpScenarioModel,
  EtpScenarioStore,
  EtpSettingsStore,
  SettingsStore,
} from '../../../Shared';
import { observable } from 'mobx';
import { finalize, takeUntil } from 'rxjs/operators';
import { AlertStore } from '@uvgo-shared/alert';
import EtpScenarioEditor, { PureEtpScenarioEditor } from '../EtpScenarioEditor/EtpScenarioEditor';
import { styles } from './EtpScenarioDetailDialog.style';
import { forkJoin } from 'rxjs';
import { IClasses, UIStore, ViewPermission } from '@wings-shared/core';

interface Props {
  isLoading?: boolean;
  classes?: IClasses;
  viewMode?: VIEW_MODE;
  etpScenarioId: number;
  etpScenarioStore: EtpScenarioStore;
  etpSettingsStore: EtpSettingsStore;
  settingsStore: SettingsStore;
  onModelUpdate: (etpScenario: EtpScenarioModel) => void;
}

@observer
class EtpScenarioDetailDialog extends BaseUpsertComponent<Props, EtpScenarioDetailModel> {
  private etpScenarioEditorRef: RefObject<PureEtpScenarioEditor> = React.createRef<PureEtpScenarioEditor>();
  @observable private etpScenarioDetail = new EtpScenarioDetailModel();
  @observable private etpScenarioDetailModel = new EtpScenarioDetailModel();

  constructor(p: Props) {
    super(p, null);
    this.viewMode = p.viewMode as VIEW_MODE;
  }

  private get etpSettingsStore(): EtpSettingsStore {
    return this.props.etpSettingsStore;
  }

  private get settingsStore(): SettingsStore {
    return this.props.settingsStore;
  }

  private get etpScenarioStore(): EtpScenarioStore {
    return this.props.etpScenarioStore;
  }

  /* istanbul ignore next */
  componentDidMount() {
    this.loadInitialData();
  }

  private loadInitialData(): void {
    UIStore.setPageLoader(true);
    forkJoin([
      this.etpScenarioStore.getEtpScenarioById(this.props.etpScenarioId),
      this.etpSettingsStore.loadEtpSettings(),
      this.settingsStore.getWeightUOMs(),
    ])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(([ etpScenarioDetailModel ]) => {
        this.etpScenarioDetail = this.mapEtpScenarioDetail(etpScenarioDetailModel);
        this.etpScenarioDetailModel = new EtpScenarioDetailModel(this.etpScenarioDetail);
      });
  }

  private mapEtpScenarioDetail(etpScenarioDetailModel: EtpScenarioDetailModel): EtpScenarioDetailModel {
    const { etpPenalties } = etpScenarioDetailModel;
    const { ETPPenaltyCategories } = this.etpSettingsStore;
    etpScenarioDetailModel.etpPenalties = ETPPenaltyCategories.map(
      x => new EtpPenaltyModel({ ...etpPenalties.find(y => y.etpPenaltyCategory?.id === x.id), etpPenaltyCategory: x })
    );
    return etpScenarioDetailModel;
  }

  private get hasError(): boolean {
    const { current } = this.etpScenarioEditorRef;
    return current ? current.hasError : true;
  }

  /* istanbul ignore next */
  private updateEtpScenarioDetailModel(): void {
    UIStore.setPageLoader(true);
    this.props.etpScenarioStore
      .upsertEtpScenarioDetail(this.etpScenarioDetailModel)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: response => {
          this.props.onModelUpdate(Object.assign(new EtpScenarioModel(), response));
          ModalStore.close();
        },
        error: error => AlertStore.critical(error.message),
      });
  }

  private get isEditMode(): boolean {
    return this.viewMode === VIEW_MODE.EDIT;
  }

  private get dialogActions(): ReactNode {
    if (this.isEditMode) {
      return (
        <>
          <PrimaryButton
            variant="outlined"
            onClick={() => {
              this.etpScenarioDetailModel = new EtpScenarioDetailModel(this.etpScenarioDetail);
              this.etpScenarioEditorRef?.current?.setInitialFormValues(this.etpScenarioDetailModel);
              this.onCancel(this.etpScenarioDetailModel);
            }}
            disabled={UIStore.pageLoading}
          >
            Cancel
          </PrimaryButton>
          <PrimaryButton
            variant="contained"
            onClick={() => this.updateEtpScenarioDetailModel()}
            disabled={this.hasError || UIStore.pageLoading}
          >
            Save
          </PrimaryButton>
        </>
      );
    }

    return (
      <ViewPermission hasPermission={AircraftModuleSecurity.isEditable}>
        <PrimaryButton variant="contained" onClick={() => (this.viewMode = VIEW_MODE.EDIT)}>
          Edit
        </PrimaryButton>
      </ViewPermission>
    );
  }

  private get dialogTitle(): string {
    if (this.etpScenarioDetailModel?.id) {
      return `ETP Scenario - ${this.etpScenarioDetailModel?.etpScenarioNumber}`;
    }
    return '';
  }

  public render() {
    const { classes } = this.props as Required<Props>;
    return (
      <Dialog
        title={this.dialogTitle}
        open={true}
        isLoading={() => UIStore.pageLoading}
        classes={{
          paperSize: classes.paperSize,
          header: classes.headerWrapper,
        }}
        onClose={() => ModalStore.close()}
        dialogContent={() =>
          this.etpScenarioDetailModel.id && (
            <EtpScenarioEditor
              ref={this.etpScenarioEditorRef}
              viewMode={this.viewMode}
              etpScenarioDetailModel={this.etpScenarioDetailModel}
              etpScenarioStore={this.props.etpScenarioStore}
              etpSettingsStore={this.props.etpSettingsStore}
              settingsStore={this.props.settingsStore}
              onChange={etpScenarioDetailModel => {
                this.etpScenarioDetailModel = etpScenarioDetailModel;
              }}
            />
          )
        }
        dialogActions={() => this.dialogActions}
      />
    );
  }
}
export default withStyles(styles)(EtpScenarioDetailDialog);
