import React, { ReactNode, RefObject } from 'react';
import { VIEW_MODE } from '@wings/shared';
import {
  ColDef,
  ColGroupDef,
  GridOptions,
  ValueFormatterParams,
  RowNode,
  SortChangedEvent,
  FilterModifiedEvent,
} from 'ag-grid-community';
import {
  FlightPlanModel,
  FlightPlanStore,
  FLIGHT_PLAN_FILTERS,
  SettingsStore,
  AircraftModuleSecurity,
  sidebarMenu,
} from '../Shared';
import { withStyles, Chip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { inject, observer } from 'mobx-react';
import { finalize, takeUntil } from 'rxjs/operators';
import { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete';
import { styles } from './FlightPlan.styles';
import {
  AccessLevelModel,
  DATE_FORMAT,
  IClasses,
  ISelectOption,
  SourceTypeModel,
  UIStore,
  Utilities,
  withRouter,
  ViewPermission,
  SearchStore,
  SettingsTypeModel,
  IBaseGridFilterSetup,
  GRID_ACTIONS,
  cellStyle,
  IAPIGridRequest,
  GridPagination,
  IGridSortFilter,
} from '@wings-shared/core';
import { SidebarStore, CustomLinkButton } from '@wings-shared/layout';
import { PureSearchHeader, SearchHeader } from '@wings-shared/form-controls';
import {
  AgGridCellEditor,
  AgGridViewRenderer,
  CustomAgGridReact,
  BaseGrid,
  IActionMenuItem,
  AgGridActions,
  AgGridGroupHeader,
  AgGridStatusBadge,
} from '@wings-shared/custom-ag-grid';
import { flightPlanGridFilters } from './fields';

interface Props {
  classes?: IClasses;
  flightPlanStore: FlightPlanStore;
  sidebarStore?: typeof SidebarStore;
  settingsStore: SettingsStore;
}

const filtersSetup: IBaseGridFilterSetup<FLIGHT_PLAN_FILTERS> = {
  defaultPlaceHolder: 'Search by Format',
  filterTypesOptions: Object.values(FLIGHT_PLAN_FILTERS),
  defaultFilterType: FLIGHT_PLAN_FILTERS.FORMAT,
  apiFilterDictionary: flightPlanGridFilters,
};

@inject('flightPlanStore', 'settingsStore', 'sidebarStore')
@observer
class FlightPlan extends BaseGrid<Props, FlightPlanModel, FLIGHT_PLAN_FILTERS> {
  constructor(props) {
    super(props, filtersSetup);
  }

  componentDidMount() {
    this.props.sidebarStore?.setNavLinks(sidebarMenu, 'aircraft');
    this.loadInitialData();
    this.onAdvanceSearch$().subscribe(() => this.loadInitialData());
    this.setMiniFilterTextDebounce();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const { clientSearchValue } = SearchStore;
    if (clientSearchValue.searchValue) {
      return;
    }
    SearchStore.clearSearch();
  }

  private get flightPlanStore(): FlightPlanStore {
    return this.props.flightPlanStore;
  }

  /* istanbul ignore next */
  private loadInitialData(pageRequest?: IAPIGridRequest): void {
    const request: IAPIGridRequest = {
      pageNumber: 1,
      pageSize: this.pagination.pageSize,
      ...pageRequest,
      ...this._searchFilters,
      ...this._sortFilters,
      ...this._gridAPIAdvancedFilterCollection,
      ...this._gridAPIAdvancedSearchCollection,
    };
    UIStore.setPageLoader(true);
    this.flightPlanStore
      .getFlightPlans(true, request)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(flightPlans => {
        this.pagination = new GridPagination({ ...flightPlans });
        this.data = flightPlans.results;
        this.gridAdvancedSearchFilterApplied();
        const { clientSearchValue } = SearchStore;
        const { selectedOption, searchValue } = clientSearchValue;
        if (searchValue) {
          this._setSelectedOption((selectedOption as FLIGHT_PLAN_FILTERS) || FLIGHT_PLAN_FILTERS.FORMAT);
          this._setSearchValue(searchValue);
          this.searchHeaderRef.current?.setSearchValue(searchValue);
          SearchStore.clearSearch();
          return;
        }
        SearchStore.clearSearch();
      });
  }

  /* istanbul ignore next */
  private get actionMenus(): IActionMenuItem[] {
    return [
      {
        title: 'Edit',
        isHidden: !AircraftModuleSecurity.isEditable,
        action: GRID_ACTIONS.EDIT,
        to: node => `/aircraft/flight-plan/${node?.data.id}/${VIEW_MODE.EDIT.toLowerCase()}`,
      },
      {
        title: 'Details',
        action: GRID_ACTIONS.DETAILS,
        to: node => `/aircraft/flight-plan/${node?.data.id}/${VIEW_MODE.DETAILS.toLowerCase()}`,
      },
    ];
  }

  /* istanbul ignore next */
  private columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Format',
      field: 'format',
      cellRenderer: 'agGroupCellRenderer',
      filter: 'agTextColumnFilter',
      filterParams: this.nameSearchFilterParams('contains', 'format', 2),
    },
    {
      headerName: 'Assignment',
      field: 'flightPlanFormatStatus',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.name,
      filter: 'agTextColumnFilter',
      filterParams: this.nameSearchFilterParams('contains', 'flightPlanFormatStatus', 2),
    },
    {
      headerName: 'Last Used Date',
      field: 'lastUsedDate',
      valueFormatter: ({ value }: ValueFormatterParams) =>
        Utilities.getformattedDate(value, DATE_FORMAT.API_DATE_FORMAT),
      comparator: (current: string, next: string) => Utilities.customDateComparator(current, next),
    },
    {
      headerName: 'Flight Plan Details',
      groupId: 'flightPlanDetails',
      children: [
        {
          headerName: 'Built By',
          groupId: 'flightPlanDetails',
          headerComponent: 'customHeader',
          field: 'builtBy',
          filter: 'agTextColumnFilter',
          filterParams: this.nameSearchFilterParams('contains', 'builtBy', 2),
        },
        {
          headerName: 'Built Date',
          columnGroupShow: 'open',
          field: 'builtDate',
          valueFormatter: ({ value }: ValueFormatterParams) =>
            Utilities.getformattedDate(value, DATE_FORMAT.API_DATE_FORMAT),
          comparator: (current: string, next: string) => Utilities.customDateComparator(current, next),
        },
        {
          headerName: 'Contact For Changes',
          field: 'contactForChanges',
          columnGroupShow: 'open',
          filter: 'agTextColumnFilter',
          filterParams: this.nameSearchFilterParams('contains', 'contactForChanges', 2),
        },
        {
          headerName: 'Notes',
          field: 'notes',
          columnGroupShow: 'open',
          filter: 'agTextColumnFilter',
          filterParams: this.nameSearchFilterParams('contains', 'notes', 2),
        },
        {
          headerName: 'Access Level',
          columnGroupShow: 'open',
          field: 'accessLevel',
          comparator: (current: AccessLevelModel, next: AccessLevelModel) =>
            Utilities.customComparator(current, next, 'name'),
          valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
          filter: 'agTextColumnFilter',
          filterParams: this.nameSearchFilterParams('contains', 'accessLevel', 2),
        },
        {
          headerName: 'Source',
          columnGroupShow: 'open',
          field: 'sourceType',
          comparator: (current: SourceTypeModel, next: SourceTypeModel) =>
            Utilities.customComparator(current, next, 'name'),
          valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
          filter: 'agTextColumnFilter',
          filterParams: this.nameSearchFilterParams('contains', 'sourceType', 2),
        },
        {
          headerName: 'Status',
          field: 'status',
          cellRenderer: 'statusRenderer',
          columnGroupShow: 'open',
          comparator: (current: ISelectOption, next: ISelectOption) =>
            Utilities.customComparator(current, next, 'value'),
          valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
          filter: 'agTextColumnFilter',
          filterParams: this.nameSearchFilterParams('start', 'status', 2),
        },
        {
          columnGroupShow: 'open',
          ...this.auditFieldsWithAdvanceFilter[0],
        },
      ],
    },
    {
      headerName: 'Action',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      sortable: false,
      minWidth: 150,
      maxWidth: 210,
      suppressSizeToFit: true,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        isActionMenu: true,
        onAction: () => {
          if (this.searchValue) {
            const clientSearchValue = { selectedOption: this.selectedOption, searchValue: this.searchValue as string };
            SearchStore.setclientSearchValue(clientSearchValue);
          }
        },
        actionMenus: () => this.actionMenus,
      },
    },
  ];

  /* istanbul ignore next */
  private get gridActionProps(): object {
    return {
      tooltip: 'Flight Plan',
      showDeleteButton: false,
      getDisabledState: () => this.hasError,
    };
  }

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: false,
      gridActionProps: this.gridActionProps,
    });

    return {
      ...baseOptions,
      suppressClickEdit: true,
      pagination: false,
      isExternalFilterPresent: () => false,
      groupHeaderHeight: 0,
      suppressColumnVirtualisation: true,
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: [
            {
              headerName: 'Account',
              field: 'accountNumber',
            },
            {
              headerName: 'Name',
              field: 'name',
            },
            {
              headerName: 'Registries',
              field: 'registriesName',
              cellRenderer: 'viewRenderer',
              cellRendererParams: {
                getViewRenderer: (rowIndex: number, node: RowNode, classes: IClasses) =>
                  this.viewRenderer(node.data?.registriesName),
              },
            },
          ],
          defaultColDef: { flex: 1 },
          frameworkComponents: {
            viewRenderer: AgGridViewRenderer,
          },
        },
        getDetailRowData: function(params) {
          params.successCallback(params.data.flightPlanFormatAccounts);
        },
      },
      suppressCellSelection: true,
      suppressRowHoverHighlight: true,
      frameworkComponents: {
        actionRenderer: AgGridActions,
        customCellEditor: AgGridCellEditor,
        customHeader: AgGridGroupHeader,
        statusRenderer: AgGridStatusBadge,
      },
      onFilterChanged: () => this.gridAdvancedSearchFilters.length === 0 && this.loadInitialData(),
      onFilterModified: (filterModified: FilterModifiedEvent) => this.onGridApiFilterModified(filterModified),
      onSortChanged: ({ api }: SortChangedEvent) => {
        this.sortFilters = api.getSortModel() as IGridSortFilter[];
        this.loadInitialData();
      },
    };
  }

  private viewRenderer(registries: string, getTagProps?: AutocompleteGetTagProps): ReactNode {
    return registries
      .split(';')
      .filter(a => a.trim().length)
      .map((registries: string, index) => (
        <Chip
          classes={{ root: this.props.classes.chip }}
          key={index}
          label={registries.toUpperCase()}
          {...(getTagProps instanceof Function ? getTagProps({ index }) : {})}
        />
      ));
  }

  private get rightContent(): ReactNode {
    return (
      <ViewPermission hasPermission={AircraftModuleSecurity.isEditable}>
        <CustomLinkButton
          variant="contained"
          startIcon={<AddIcon />}
          to={`flight-plan/${VIEW_MODE.NEW.toLowerCase()}`}
          title="Add Flight Plan Format"
          disabled={this.isProcessing}
        />
      </ViewPermission>
    );
  }

  public render(): ReactNode {
    return (
      <>
        <SearchHeader
          ref={this.searchHeaderRef}
          searchPlaceHolder={this.searchPlaceHolder}
          searchTypeValue={this.selectedOption}
          searchTypeOptions={this._selectOptions}
          onSearchTypeChange={selectedOption => this._setSelectedOption(selectedOption as FLIGHT_PLAN_FILTERS)}
          onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
          onResetFilterClick={() => this.onFilterResetClickHandler()}
          rightContent={this.rightContent}
          isDisabled={Boolean(this.gridAdvancedSearchFilters.length)}
          expandCollapse={() => this.autoSizeColumns()}
        />
        <CustomAgGridReact
          isRowEditing={this.isRowEditing}
          rowData={this.data}
          gridOptions={this.gridOptions}
          serverPagination={true}
          paginationData={this.pagination}
          onPaginationChange={request => this.loadInitialData(request)}
        />
      </>
    );
  }
}

export default withRouter(withStyles(styles)(FlightPlan));
export { FlightPlan as PureFlightPlan };
