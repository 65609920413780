import React, { ReactNode } from 'react';
import { VIEW_MODE } from '@wings/shared';
import {
  DATE_FORMAT,
  withRouter,
  Utilities,
  UIStore,
  AccessLevelModel,
  SourceTypeModel,
  ISelectOption,
  ViewPermission,
  SearchStore,
  SettingsTypeModel,
  IBaseGridFilterSetup,
  GRID_ACTIONS,
  cellStyle,
} from '@wings-shared/core';
import { ColDef, GridOptions, ValueFormatterParams, ValueGetterParams, ColGroupDef } from 'ag-grid-community';
import {
  EtpScenarioModel,
  EtpScenarioStore,
  EtpSettingsStore,
  ETP_SCENARIO_FILTERS,
  SettingsStore,
  ImportFileData,
  AircraftModuleSecurity,
  updateAircraftSidebarOptions,
} from '../Shared';
import { withTheme } from '@material-ui/core';
import { observer, inject } from 'mobx-react';
import { finalize, takeUntil } from 'rxjs/operators';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { EtpScenarioDetailDialog } from './Components';
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { CustomLinkButton, SidebarStore } from '@wings-shared/layout';
import { SearchHeader } from '@wings-shared/form-controls';
import {
  AgGridCellEditor,
  CustomAgGridReact,
  BaseGrid,
  IActionMenuItem,
  AgGridActions,
  AgGridAutoComplete,
  AgGridGroupHeader,
  AgGridDateTimePicker,
  AgGridStatusBadge,
} from '@wings-shared/custom-ag-grid';

interface Props {
  etpScenarioStore: EtpScenarioStore;
  settingsStore: SettingsStore;
  etpSettingsStore: EtpSettingsStore;
  sidebarStore?:typeof SidebarStore;
}

const filtersSetup: IBaseGridFilterSetup<ETP_SCENARIO_FILTERS> = {
  defaultPlaceHolder: 'Search by ETP Scenario Number',
  filterTypesOptions: Object.values(ETP_SCENARIO_FILTERS),
  defaultFilterType: ETP_SCENARIO_FILTERS.SCENARIO_NUMBER,
};

@inject('etpScenarioStore', 'settingsStore', 'etpSettingsStore','sidebarStore')
@observer
class EtpScenario extends BaseGrid<Props, EtpScenarioModel, ETP_SCENARIO_FILTERS> {
  constructor(props) {
    super(props, filtersSetup);
  }

  componentDidMount() {
    this.props.sidebarStore?.setNavLinks(updateAircraftSidebarOptions('ETP Scenario'), 'aircraft');
    this.loadInitialData();
  }

  private get etpScenarioStore(): EtpScenarioStore {
    return this.props.etpScenarioStore;
  }

  /* istanbul ignore next */
  private loadInitialData() {
    UIStore.setPageLoader(true);
    this.props.etpScenarioStore
      .getEtpScenarios()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(EtpScenarios => {
        this.data = EtpScenarios;
        const { clientSearchValue } = SearchStore;
        const { selectedOption, searchValue } = clientSearchValue;
        if (searchValue) {
          this._setSelectedOption((selectedOption as ETP_SCENARIO_FILTERS) || ETP_SCENARIO_FILTERS.SCENARIO_NUMBER);
          this._setSearchValue(searchValue);
          this.searchHeaderRef.current?.setSearchValue(searchValue);
          SearchStore.clearSearch();
          return;
        }
        SearchStore.clearSearch();
      });
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const { clientSearchValue } = SearchStore;
    if (clientSearchValue.searchValue) {
      return;
    }
    SearchStore.clearSearch();
  }

  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    if (rowIndex === null) {
      return;
    }
    switch (gridAction) {
      case GRID_ACTIONS.EDIT:
        this.showEtpScenarioDialog(VIEW_MODE.EDIT, rowIndex, this._getTableItem(rowIndex));
        break;
      case GRID_ACTIONS.DETAILS:
        this.showEtpScenarioDialog(VIEW_MODE.DETAILS, rowIndex, this._getTableItem(rowIndex));
        break;
      default:
        this.gridApi.stopEditing(true);
        break;
    }
  }

  /* istanbul ignore next */
  private get actionMenus(): IActionMenuItem[] {
    return [
      { title: 'Edit', isHidden: !AircraftModuleSecurity.isEditable, action: GRID_ACTIONS.EDIT },
      { title: 'Details', isHidden: false, action: GRID_ACTIONS.DETAILS },
    ];
  }

  /* istanbul ignore next */
  private columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'ETP Scenario Number',
      field: 'etpScenarioNumber',
      comparator: (current: number, next: number) => current - next,
      filter: true,
    },
    {
      headerName: 'NFP Scenario Number',
      field: 'nfpScenarioNumber',
      comparator: (current: number, next: number) => current - next,
      filter: true,
    },
    {
      headerName: 'Description',
      field: 'description',
      filter: true,
    },
    {
      headerName: 'Comments',
      field: 'comments',
      filter: true,
    },
    {
      headerName: 'ETP Scenario Type',
      field: 'etpScenarioType',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: true,
      filterValueGetter: ({ data }: ValueGetterParams) => data.etpScenarioType.name,
    },
    {
      headerName: 'ETP Scenario Engine',
      field: 'etpScenarioEngine',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: true,
      filterValueGetter: ({ data }: ValueGetterParams) => data.etpScenarioEngine.name,
    },
    {
      headerName: 'Weight UOM',
      field: 'weightUom',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: true,
      filterValueGetter: ({ data }: ValueGetterParams) => data.weightUom.name,
    },
    {
      headerName: 'ETP Time Limit Type',
      field: 'etpTimeLimitType',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: true,
      filterValueGetter: ({ data }: ValueGetterParams) => data.etpTimeLimitType.name,
    },
    {
      headerName: 'ETP Scenario Details',
      groupId: 'etpScenarioDetails',
      children: [
        {
          headerName: 'Modifided Date',
          groupId: 'modifiedOn',
          headerComponent: 'customHeader',
          minWidth: 150,
          filter: true,
          field: 'modifiedOn',
          cellEditor: 'customTimeEditor',
          headerTooltip: 'Modified Date',
          valueFormatter: ({ value }: ValueFormatterParams) =>
            Utilities.getformattedDate(value, DATE_FORMAT.API_DATE_FORMAT),
          filterValueGetter: ({ data }: ValueGetterParams) =>
            Utilities.getformattedDate(data.modifiedOn, DATE_FORMAT.API_DATE_FORMAT),
        },
        {
          headerName: 'Created Date',
          field: 'createdOn',
          columnGroupShow: 'open',
          filter: true,
          cellEditor: 'customTimeEditor',
          headerTooltip: 'Created Date',
          valueFormatter: ({ value }: ValueFormatterParams) =>
            Utilities.getformattedDate(value, DATE_FORMAT.API_DATE_FORMAT),
          filterValueGetter: ({ data }: ValueGetterParams) =>
            Utilities.getformattedDate(data.createdOn, DATE_FORMAT.API_DATE_FORMAT),
        },
        {
          headerName: 'Access Level',
          columnGroupShow: 'open',
          field: 'accessLevel',
          filter: true,
          cellEditor: 'customAutoComplete',
          headerTooltip: 'Access Level',
          comparator: (current: AccessLevelModel, next: AccessLevelModel) =>
            Utilities.customComparator(current, next, 'name'),
          valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
          filterValueGetter: ({ data }: ValueGetterParams) => data.accessLevel.name,
        },
        {
          headerName: 'Source',
          columnGroupShow: 'open',
          field: 'sourceType',
          filter: true,
          cellEditor: 'customAutoComplete',
          headerTooltip: 'Source',
          comparator: (current: SourceTypeModel, next: SourceTypeModel) =>
            Utilities.customComparator(current, next, 'name'),
          valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
          filterValueGetter: ({ data }: ValueGetterParams) => data.sourceType.name,
        },
        {
          headerName: 'Status',
          field: 'status',
          columnGroupShow: 'open',
          filter: true,
          cellEditor: 'customAutoComplete',
          cellRenderer: 'statusRenderer',
          headerTooltip: 'Status',
          comparator: (current: ISelectOption, next: ISelectOption) =>
            Utilities.customComparator(current, next, 'value'),
          valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
          filterValueGetter: ({ data }: ValueGetterParams) => data.status.label,
        },
      ],
    },
    {
      headerName: 'Action',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      sortable: false,
      filter: false,
      minWidth: 150,
      maxWidth: 210,
      suppressSizeToFit: true,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        isActionMenu: true,
        actionMenus: () => this.actionMenus,
        onAction: (action: GRID_ACTIONS, rowIndex: number) => {
          if (this.searchValue) {
            const clientSearchValue = { selectedOption: this.selectedOption, searchValue: this.searchValue as string };
            SearchStore.setclientSearchValue(clientSearchValue);
          }
          this.gridActions(action, rowIndex);
        },
      },
    },
  ];

  /* istanbul ignore next */
  private get gridActionProps(): object {
    return {
      tooltip: 'ETP Scenario',
      getDisabledState: () => this.hasError,
      onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
    };
  }

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: false,
      gridActionProps: this.gridActionProps,
    });

    return {
      ...baseOptions,
      doesExternalFilterPass: node => {
        const { etpScenarioNumber, id, comments, description } = node.data as EtpScenarioModel;
        return (
          !id ||
          this._isFilterPass({
            [ETP_SCENARIO_FILTERS.SCENARIO_NUMBER]: etpScenarioNumber.toString(),
            [ETP_SCENARIO_FILTERS.COMMENT]: comments,
            [ETP_SCENARIO_FILTERS.DESCRIPTION]: description,
          })
        );
      },
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
      },
      frameworkComponents: {
        actionRenderer: AgGridActions,
        customCellEditor: AgGridCellEditor,
        customAutoComplete: AgGridAutoComplete,
        customHeader: AgGridGroupHeader,
        customTimeEditor: AgGridDateTimePicker,
        statusRenderer: AgGridStatusBadge,
      },
    };
  }

  private showEtpScenarioDialog(viewMode: VIEW_MODE, rowIndex: number, etpScenarioModel: EtpScenarioModel): void {
    ModalStore.open(
      <EtpScenarioDetailDialog
        etpScenarioId={etpScenarioModel.id}
        viewMode={viewMode}
        settingsStore={this.props.settingsStore}
        etpSettingsStore={this.props.etpSettingsStore}
        etpScenarioStore={this.props.etpScenarioStore}
        onModelUpdate={updatedModel => this._updateTableItem(rowIndex, updatedModel)}
      />
    );
  }

  private onRequestImportEtpScenario(): void {
    const { etpScenarioStore } = this.props;
    ModalStore.open(
      <ImportFileData
        onImportFileData={file => etpScenarioStore.uploadEtpScenarioData(file)}
        onImportDone={() => this.loadInitialData()}
        title="Import ETP Scenarios"
        successMessage="All ETP Scenarios Imported successfully!"
      />
    );
  }

  private get rightContent(): ReactNode {
    return (
      <ViewPermission hasPermission={AircraftModuleSecurity.isEditable}>
        <>
          <CustomLinkButton
            variant="contained"
            startIcon={<AddIcon />}
            to="new"
            title="Add ETP Scenario"
            disabled={this.isProcessing}
          />
          <PrimaryButton
            variant="contained"
            color="primary"
            startIcon={<CloudUploadIcon />}
            onClick={() => this.onRequestImportEtpScenario()}
            disabled={this.isProcessing}
          >
            Import ETP Scenario
          </PrimaryButton>
        </>
      </ViewPermission>
    );
  }

  public render(): ReactNode {
    return (
      <>
        <SearchHeader
          ref={this.searchHeaderRef}
          searchPlaceHolder={this.searchPlaceHolder}
          searchTypeValue={this.selectedOption}
          searchTypeOptions={this._selectOptions}
          onSearchTypeChange={selectedOption => this._setSelectedOption(selectedOption as ETP_SCENARIO_FILTERS)}
          onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
          rightContent={this.rightContent}
          expandCollapse={() => this.autoSizeColumns()}
        />
        <CustomAgGridReact
          isRowEditing={this.isRowEditing}
          rowData={this.data}
          gridOptions={this.gridOptions}
          disablePagination={this.isRowEditing}
        />
      </>
    );
  }
}

export default withRouter(withTheme(EtpScenario));
export { EtpScenario as PureEtpScenario };
