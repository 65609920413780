import React, { ReactNode } from 'react';
import { VIEW_MODE } from '@wings/shared';
import { withRouter, Utilities, DATE_FORMAT, UIStore, IClasses, IBaseGridFilterSetup } from '@wings-shared/core';
import { ColDef, ColGroupDef, GridOptions, ValueFormatterParams, ValueGetterParams, RowNode } from 'ag-grid-community';
import { withStyles } from '@material-ui/core';
import { styles } from './FlightPlanFormatChangeRecord.styles';
import { inject, observer } from 'mobx-react';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  FlightPlanChangeRecordModel,
  FlightPlanStore,
  FLIGHT_PLAN_CHANGE_RECORDS_FILTERS,
  updateAircraftSidebarOptions,
} from '../../../Shared';
import { Link } from 'react-router-dom';
import { SearchHeader } from '@wings-shared/form-controls';
import { AgGridViewRenderer, CustomAgGridReact, AgGridGroupHeader, BaseGrid } from '@wings-shared/custom-ag-grid';
import { SidebarStore } from '@wings-shared/layout';

interface Props {
  classes?: IClasses;
  flightPlanStore: FlightPlanStore;
  sidebarStore?: typeof SidebarStore;
}

const filtersSetup: IBaseGridFilterSetup<FLIGHT_PLAN_CHANGE_RECORDS_FILTERS> = {
  defaultPlaceHolder: 'Search by format',
  filterTypesOptions: Object.values(FLIGHT_PLAN_CHANGE_RECORDS_FILTERS),
  defaultFilterType: FLIGHT_PLAN_CHANGE_RECORDS_FILTERS.FORMAT,
};

@inject('flightPlanStore', 'sidebarStore')
@observer
class FlightPlanFormatChangeRecord extends BaseGrid<
  Props,
  FlightPlanChangeRecordModel,
  FLIGHT_PLAN_CHANGE_RECORDS_FILTERS
> {
  constructor(props) {
    super(props, filtersSetup);
  }

  componentDidMount() {
    this.props.sidebarStore?.setNavLinks(updateAircraftSidebarOptions('Change Record'), 'aircraft');
    this.loadInitialData();
  }

  private get flightPlanStore(): FlightPlanStore {
    return this.props.flightPlanStore;
  }

  /* istanbul ignore next */
  private loadInitialData() {
    UIStore.setPageLoader(true);
    this.flightPlanStore
      .getFlightPlanChnageRecords()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(flightPlans => (this.data = flightPlans));
  }

  /* istanbul ignore next */
  private columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Format',
      field: 'format',
      cellRenderer: 'viewRenderer',
      cellRendererParams: {
        getViewRenderer: (rowIndex: number, node: RowNode, classes: IClasses) => (
          <Link
            className={this.props.classes?.link}
            to={`/aircraft/flight-plan/${node.data.flightPlanFormatId}/${VIEW_MODE.DETAILS.toLowerCase()}`}
          >
            {node.data?.format}
          </Link>
        ),
      },
    },
    {
      headerName: 'Requested By',
      field: 'requestedBy',
    },
    {
      headerName: 'Changed By',
      field: 'changedBy',
    },
    {
      headerName: 'Notes',
      field: 'notes',
      minWidth: 700,
    },
    {
      headerName: 'Changed Date',
      field: 'changedDate',
      valueFormatter: ({ value }: ValueFormatterParams) =>
        Utilities.getformattedDate(value, DATE_FORMAT.DATE_PICKER_FORMAT),
      filterValueGetter: ({ data }: ValueGetterParams) =>
        Utilities.getformattedDate(data.changedDate, DATE_FORMAT.API_DATE_FORMAT),
    },
    ...this.auditFields,
  ];

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: false,
    });

    return {
      ...baseOptions,
      doesExternalFilterPass: node => {
        const { format, id, requestedBy, changedBy, notes } = node.data as FlightPlanChangeRecordModel;
        return (
          !id ||
          this._isFilterPass({
            [FLIGHT_PLAN_CHANGE_RECORDS_FILTERS.FORMAT]: format,
            [FLIGHT_PLAN_CHANGE_RECORDS_FILTERS.REQUESTED_BY]: requestedBy,
            [FLIGHT_PLAN_CHANGE_RECORDS_FILTERS.CHANGED_BY]: changedBy,
            [FLIGHT_PLAN_CHANGE_RECORDS_FILTERS.NOTES]: notes,
          })
        );
      },
      groupHeaderHeight: 0,
      suppressColumnVirtualisation: true,
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
        filter: true,
      },
      frameworkComponents: {
        customHeader: AgGridGroupHeader,
        viewRenderer: AgGridViewRenderer,
      },
    };
  }

  public render(): ReactNode {
    return (
      <>
        <SearchHeader
          searchPlaceHolder={this.searchPlaceHolder}
          searchTypeValue={this.selectedOption}
          searchTypeOptions={this._selectOptions}
          onSearchTypeChange={selectedOption =>
            this._setSelectedOption(selectedOption as FLIGHT_PLAN_CHANGE_RECORDS_FILTERS)
          }
          onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
          expandCollapse={() => this.autoSizeColumns()}
        />
        <CustomAgGridReact
          isRowEditing={this.isRowEditing}
          rowData={this.data}
          gridOptions={this.gridOptions}
          disablePagination={this.isRowEditing}
        />
      </>
    );
  }
}

export default withRouter(withStyles(styles)(FlightPlanFormatChangeRecord));
export { FlightPlanFormatChangeRecord as PureFlightPlanFormatChangeRecord };
