import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Dialog } from '@uvgo-shared/dialog';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { VariationSearch } from '../index';
import { styles } from './VariationSearchDialog.styles';
import { AircraftVariationStore, AircraftVariationModel } from '../../../Shared';
import { finalize } from 'rxjs/operators';
import { IClasses, UIStore } from '@wings-shared/core';

interface Props {
  classes?: IClasses;
  aircraftVariationStore?: AircraftVariationStore;
  onSelect: (selectedVariation: AircraftVariationModel) => void;
}

@observer
class VariationSearchDialog extends Component<Props> {
  componentDidMount(): void {
    this.loadInitialData();
  }

  /* istanbul ignore next */
  private loadInitialData(): void {
    UIStore.setPageLoader(true);
    this.props.aircraftVariationStore
      ?.getAircraftVariations()
      .pipe(finalize(() => UIStore.setPageLoader(false)))
      .subscribe();
  }

  public render() {
    const { classes, aircraftVariationStore, onSelect } = this.props as Required<Props>;
    return (
      <Dialog
        title={'Select Aircraft Type'}
        open={true}
        isLoading={() => UIStore.pageLoading}
        classes={{
          paperSize: classes.paperSize,
          header: classes.headerWrapper,
        }}
        onClose={() => ModalStore.close()}
        dialogContent={() => <VariationSearch data={aircraftVariationStore.aircraftVariations} onSelect={onSelect} />}
      />
    );
  }
}
export default withStyles(styles)(VariationSearchDialog);
export { VariationSearchDialog as PureVariationSearchDialog };
