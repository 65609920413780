import { createStyles, makeStyles } from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    paperSize: {
      width: 1000,
      height: 'auto',
    },
  });

export const useStyles = makeStyles({
  paperSize: {
    width: 1000,
    height: 'auto',
  },
});
