import React, { ReactNode } from 'react';
import { ColDef, GridOptions, ValueFormatterParams, ICellEditorParams } from 'ag-grid-community';
import { regex, Utilities, SettingsTypeModel } from '@wings-shared/core';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { EtpPenaltyModel } from '../../../Shared/Models';
import { EtpSettingsStore, PENALTY_CATEGORY } from '../../../Shared';
import { AgGridCellEditor, CustomAgGridReact, BaseGrid, AgGridAutoComplete } from '@wings-shared/custom-ag-grid';

interface Props {
  isEditable: boolean;
  rowData: EtpPenaltyModel[];
  etpSettingsStore?: EtpSettingsStore;
  onDataUpdate: (etpPenalties: EtpPenaltyModel[]) => void;
}

@inject('etpSettingsStore')
@observer
class EtpPenaltiesGrid extends BaseGrid<Props, EtpPenaltyModel> {
  private readonly unRequiredCategory: string[] = [
    PENALTY_CATEGORY.ICE_PENALTY_PERCENTAGE,
    PENALTY_CATEGORY.APPLY_ICE_PENALTY,
    PENALTY_CATEGORY.WIND_SPEED_ADJUSTMENT,
  ];

  private readonly validatedCategory: string[] = [
    PENALTY_CATEGORY.APPLY_ICE_PENALTY,
    PENALTY_CATEGORY.ICE_PENALTY_PERCENTAGE,
  ];

  @observable private hasValidBiasField: boolean = false;
  private readonly defaultBiasFieldRule = `numeric|between:0.01,99999|regex:${regex.numberWithTwoDecimal}`;

  constructor(props) {
    super(props);
    this.data = this.props.rowData;
  }

  public resetData(etpPenalties: EtpPenaltyModel[]): void {
    this.data = etpPenalties;
    this.gridApi.redrawRows();
  }

  private get etpSettingsStore(): EtpSettingsStore {
    return this.props.etpSettingsStore as EtpSettingsStore;
  }

  private get isBiasEditable(): boolean {
    const AICData = this._getTableItem(0);
    const EAIUData = this._getTableItem(1);
    return this.hasRequiredData(AICData) || this.hasRequiredData(EAIUData);
  }

  private hasRequiredData(rowData: EtpPenaltyModel): boolean {
    if (!rowData.biasFields) {
      return false;
    }
    const { etpPenaltyBiasType, etpPenaltyApplyTo } = rowData;
    return (
      (Utilities.isEqual(etpPenaltyBiasType.name, 'Rate') || Utilities.isEqual(etpPenaltyBiasType.name, 'Percent')) &&
      Utilities.isEqual(etpPenaltyApplyTo.name, 'Total')
    );
  }

  /* istanbul ignore next */
  private isEditable(colDef: ColDef, rowIndex: number): boolean {
    const rowData = this._getTableItem(rowIndex);
    const categoryName = rowData.etpPenaltyCategory.name; // as PENALTY_CATEGORY;
    if (!this.props.isEditable) {
      return false;
    }
    switch (colDef.field) {
      case 'biasFields':
        return this.validatedCategory.includes(categoryName) ? this.isBiasEditable : true;
      case 'etpPenaltyBiasType':
      case 'etpPenaltyApplyTo':
        return !this.unRequiredCategory.includes(categoryName) && Boolean(rowData.biasFields);
      default:
        return true;
    }
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Name',
      field: 'etpPenaltyCategory',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      filter: false,
      editable: false,
      valueFormatter: ({ value }: ValueFormatterParams) => value?.name,
    },
    {
      headerName: 'Bias',
      field: 'biasFields',
      cellEditorParams: {
        rules: this.defaultBiasFieldRule,
      },
    },
    {
      headerName: 'Type',
      field: 'etpPenaltyBiasType',
      cellEditor: 'customAutoComplete',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      filter: false,
      valueFormatter: ({ value }: ValueFormatterParams) => value?.name,
      cellEditorParams: {
        isRequired: () => this.hasValidBiasField,
        placeHolder: 'Bias Type',
        getAutoCompleteOptions: () => this.etpSettingsStore.ETPPenaltyBias,
        valueGetter: (option: SettingsTypeModel) => option,
      },
    },
    {
      headerName: 'Apply to',
      field: 'etpPenaltyApplyTo',
      cellEditor: 'customAutoComplete',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      filter: false,
      valueFormatter: ({ value }: ValueFormatterParams) => value?.name,
      cellEditorParams: {
        placeHolder: 'Apply',
        getAutoCompleteOptions: () => this.etpSettingsStore.ETPPenaltyApply,
        valueGetter: (option: SettingsTypeModel) => option,
      },
    },
  ];

  /* istanbul ignore next */
  @action
  private onEditingStart(rowIndex: number, colDef: ColDef): void {
    const rowData = this._getTableItem(rowIndex);
    if (Utilities.isEqual(colDef.field || '', 'biasFields')) {
      const cellInstance = this.getComponentInstance('biasFields');
      switch (rowData.etpPenaltyCategory.name) {
        case PENALTY_CATEGORY.ICE_PENALTY_PERCENTAGE:
          cellInstance.setRules('numeric|between:10,99');
          break;
        case PENALTY_CATEGORY.APPLY_ICE_PENALTY:
          cellInstance.setRules('numeric|between:0.1,2');
          break;
        case PENALTY_CATEGORY.WIND_SPEED_ADJUSTMENT:
          cellInstance.setRules('numeric|between:1,99');
          break;
        default:
          cellInstance.setRules(this.defaultBiasFieldRule);
          break;
      }
    }
  }

  private resetGridRow(rowIndex: number, rowData: EtpPenaltyModel): void {
      this.gridApi
        .getRowNode(rowIndex.toString())
        ?.setData(new EtpPenaltyModel({ etpPenaltyCategory: rowData.etpPenaltyCategory, biasFields: null }));
      this.data = this._getAllTableRows();
      this.props.onDataUpdate(this.data);
  }

  /* istanbul ignore next */
  @action
  private onEditingStop(rowIndex: number, colDef: ColDef): void {
    const rowData = this._getTableItem(rowIndex);
    if (Utilities.isEqual(colDef.field || '', 'biasFields')) {
      if (rowData.biasFields) {
        if (this.validatedCategory.includes(rowData.etpPenaltyCategory.name)) {
          {
            const updatingCategory = Utilities.isEqual(
              rowData.etpPenaltyCategory.name,
              PENALTY_CATEGORY.APPLY_ICE_PENALTY
            )
              ? PENALTY_CATEGORY.ICE_PENALTY_PERCENTAGE
              : PENALTY_CATEGORY.APPLY_ICE_PENALTY;
            const updatingIndex = this.data.findIndex(a =>
              Utilities.isEqual(a.etpPenaltyCategory.name, updatingCategory)
            );
            const updatingRowData = this._getTableItem(updatingIndex);
            this.resetGridRow(updatingIndex, updatingRowData);
          }
        }
      } else {
        this.resetGridRow(rowIndex, rowData);
      }
    }
    this.setIppOrAipValidation();
    this.props.onDataUpdate(this.data);
  }

  private setIppOrAipValidation(): void {
    if (this.isBiasEditable) {
      return;
    }
    this.resetRowByCategory(PENALTY_CATEGORY.ICE_PENALTY_PERCENTAGE);
    this.resetRowByCategory(PENALTY_CATEGORY.APPLY_ICE_PENALTY);
  }

  private resetRowByCategory(category: string): void {
    const updatingIndex = this.data.findIndex(a => Utilities.isEqual(a.etpPenaltyCategory.name, category));
    const updatingRowData = this._getTableItem(updatingIndex);
    this.resetGridRow(updatingIndex, updatingRowData);
  }

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: true,
      editType: 'cellOnly',
    });
    return {
      ...baseOptions,
      postSort: () => null,
      suppressClickEdit: true,
      onCellDoubleClicked: ({ rowIndex, colDef }) => {
        if (this.isEditable(colDef, Number(rowIndex))) {
          this.gridApi.startEditingCell({ rowIndex: Number(rowIndex), colKey: colDef.field || '' });
        }
      },
      onCellEditingStarted: ({ rowIndex, colDef }) => {
        this.onEditingStart(Number(rowIndex), colDef);
      },
      onCellEditingStopped: ({ rowIndex, colDef }) => {
        this.onEditingStop(Number(rowIndex), colDef);
      },
      frameworkComponents: {
        customCellEditor: AgGridCellEditor,
        customAutoComplete: AgGridAutoComplete,
      },
    };
  }

  // Called from Ag Grid Component
  @action
  public onInputChange(params: ICellEditorParams, value: string): void {
    const colId: string = params.column.getColId();
    if (Utilities.isEqual(colId, 'biasFields')) {
      const hasBiasError = this.getComponentInstance('biasFields').hasError;
      this.hasValidBiasField = !hasBiasError;
    }
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
  }

  public get hasPenaltyError(): boolean {
    return this.data
      .filter(a => a.biasFields)
      .some(a =>
        this.unRequiredCategory.includes(a.etpPenaltyCategory.name) ? false : !Boolean(a.etpPenaltyBiasType?.id)
      );
  }

  // Called from Ag Grid Component
  @action
  public onDropDownChange(params: ICellEditorParams, value: string): void {
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
  }

  public render(): ReactNode {
    return (
      <CustomAgGridReact rowData={this.data} gridOptions={this.gridOptions} disablePagination={this.isRowEditing} />
    );
  }
}

export default EtpPenaltiesGrid;
