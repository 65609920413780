import React, { ReactNode } from 'react';
import {
  CustomAgGridReact,
  BaseGrid,
  AgGridActions,
  AgGridAutoComplete,
  AgGridCheckBox,
} from '@wings-shared/custom-ag-grid';
import { Utilities, ENTITY_STATE, IClasses, SettingsTypeModel, GRID_ACTIONS, cellStyle } from '@wings-shared/core';
import { SettingsModuleSecurity } from '@wings-shared/security';
import {
  ColDef,
  GridOptions,
  ICellEditorParams,
  RowEditingStartedEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import { observer } from 'mobx-react';
import { AircraftModelMakeModel } from '../../../../../Shared/Models/AircraftModelMake.model';
import { SettingsStore } from '../../../../../Shared';
import { action } from 'mobx';
import { ChildGridWrapper, CollapsibleWithButton } from '@wings-shared/layout';

interface Props {
  classes?: IClasses;
  modelMakes: AircraftModelMakeModel[];
  settingsStore?: SettingsStore;
  onDataSave: (response: AircraftModelMakeModel[]) => void;
  onRowEditing: (isEditing: boolean) => void;
}

@observer
class AircraftModelMakeMasterDetail extends BaseGrid<Props, AircraftModelMakeModel> {
  constructor(props) {
    super(props);
  }

  @action
  // Called from Ag Grid Component
  public onInputChange({ colDef }: ICellEditorParams, value: string): void {
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
  }

  // Called from Ag Grid Component
  @action
  public onDropDownChange(params: ICellEditorParams, value: string): void {
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
  }

  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    const { onRowEditing } = this.props;
    if (rowIndex === null) {
      return;
    }
    switch (gridAction) {
      case GRID_ACTIONS.EDIT:
        this._startEditingCell(rowIndex, this.columnDefs[0].field || '');
        break;
      case GRID_ACTIONS.SAVE:
        this.upsertAircraftModelMake(rowIndex);
        onRowEditing(false);
        break;
      case GRID_ACTIONS.DELETE:
        this.deleteRecord(rowIndex);
        break;
      case GRID_ACTIONS.CANCEL:
        this.canceEditing(rowIndex);
        onRowEditing(false);
        break;
      default:
        this.gridApi.stopEditing(true);
        onRowEditing(false);
        break;
    }
  }

  private addNewRecord(): void {
    const make = new AircraftModelMakeModel({ id: 0 });
    this._addNewItems([ make ], { startEditing: true, colKey: 'make' });
    this.hasError = true;
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Make',
      field: 'make',
      cellEditor: 'customAutoComplete',
      comparator: (current: AircraftModelMakeModel, next: AircraftModelMakeModel) =>
        Utilities.customComparator(current, next, 'name'),
      filter: false,
      valueFormatter: ({ value }: ValueFormatterParams) => value?.name,
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Make',
        getAutoCompleteOptions: () => this.makeOptions,
      },
    },
    {
      headerName: 'Large Aircraft',
      field: 'isLargeAircraft',
      cellRenderer: 'checkBoxRenderer',
      cellEditor: 'checkBoxRenderer',
      cellRendererParams: { readOnly: true },
    },
    {
      headerName: '',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      maxWidth: 130,
      minWidth: 130,
      hide: !SettingsModuleSecurity.isEditable,
      sortable: false,
      filter: false,
      suppressSizeToFit: true,
      suppressNavigable: true,
      cellStyle: { ...cellStyle() },
    },
  ];

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: true,
      gridActionProps: {
        showDeleteButton: true,
        getDisabledState: () => this.hasError,
        onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
      },
    });
    return {
      ...baseOptions,
      onRowEditingStarted: (event: RowEditingStartedEvent) => {
        if (this.isProcessing) {
          this.gridApi.stopEditing();
          return;
        }
        this.hasError = true;
        this._startEditingRow(event);
        this.props.onRowEditing(true);
      },
      groupHeaderHeight: 0,
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
        filter: true,
      },
      frameworkComponents: {
        actionRenderer: AgGridActions,
        customAutoComplete: AgGridAutoComplete,
        checkBoxRenderer: AgGridCheckBox,
      },
    };
  }

  /* istanbul ignore next */
  private upsertAircraftModelMake(rowIndex: number): void {
    this.gridApi.stopEditing();
    this.updateTableData();
  }

  private deleteRecord(rowIndex: number): void {
    const model: AircraftModelMakeModel = this._getTableItem(rowIndex);
    this._removeTableItems([ model ]);
    this.updateTableData();
  }

  /* istanbul ignore next */
  private canceEditing(rowIndex: number): void {
    const data: AircraftModelMakeModel = this._getTableItem(rowIndex);
    const isNewEntry = Utilities.isEqual(data.entityState || '', ENTITY_STATE.UNCHNAGED);
    this._cancelEditing(rowIndex, isNewEntry);
  }

  /* istanbul ignore next */
  private get makeOptions(): SettingsTypeModel[] {
    const { settingsStore } = this.props as Required<Props>;
    return settingsStore.makes.filter(make => !this.data.some(x => x.make?.id === make.id));
  }

  private updateTableData(): void {
    this.data = this._getAllTableRows().map(
      requirement =>
        new AircraftModelMakeModel({
          ...requirement,
          entityState: ENTITY_STATE.NEW,
        })
    );
    this.props.onDataSave(this.data);
  }

  public render(): ReactNode {
    const { classes } = this.props;
    return (
      <div className={classes?.root}>
        <CollapsibleWithButton
          title="Make"
          buttonText="Add Make"
          isButtonDisabled={this.isProcessing || !SettingsModuleSecurity.isEditable}
          onButtonClick={() => this.addNewRecord()}
        >
          <ChildGridWrapper hasAddPermission={false}>
            <CustomAgGridReact
              isRowEditing={this.isRowEditing}
              rowData={this.props.modelMakes}
              gridOptions={this.gridOptions}
              disablePagination={this.isRowEditing}
            />
          </ChildGridWrapper>
        </CollapsibleWithButton>
      </div>
    );
  }
}

export default AircraftModelMakeMasterDetail;
export { AircraftModelMakeMasterDetail as PureAircraftModelMakeMasterDetail };
