import React, { MouseEvent, ReactNode } from 'react';
import { withStyles, Typography, IconButton } from '@material-ui/core';
import { VIEW_MODE, BaseUpsertComponent, ModelStatusOptions } from '@wings/shared';
import {
  EDITOR_TYPES,
  ViewInputControl,
  AuditFields,
  IViewInputControl,
  IGroupInputControls,
} from '@wings-shared/form-controls';
import {
  withRouter,
  Utilities,
  UIStore,
  IClasses,
  IAPIGridRequest,
  IOptionValue,
  ViewPermission,
  SettingsTypeModel,
  GRID_ACTIONS,
} from '@wings-shared/core';
import { inject, observer } from 'mobx-react';
import { styles } from './AircraftVariation.styles';
import {
  AircraftVariationStore,
  AircraftVariationModel,
  PerformanceStore,
  SettingsStore,
  EngineTypeModel,
  SeriesModel,
  AircraftModel,
  SubCategoryModel,
  AircraftVariationPictureModel,
  PerformanceModel,
  AircraftCollapsable,
  AircraftModuleSecurity,
  updateAircraftSidebarOptions
} from '../../../Shared';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { NavigateFunction } from 'react-router';
import { ArrowBack, Cached } from '@material-ui/icons';
import { AlertStore } from '@uvgo-shared/alert';
import { forkJoin, Observable, of } from 'rxjs';
import { fields } from './Fields';
import { action, observable } from 'mobx';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import classNames from 'classnames';
import {
  Collapsable,
  CollapsibleWithButton,
  CustomLinkButton,
  DetailsEditorWrapper,
  EditSaveButtons,
  ImportDialog,
  SidebarStore,
} from '@wings-shared/layout';

interface Props {
  classes?: IClasses;
  aircraftVariationStore?: AircraftVariationStore;
  settingsStore?: SettingsStore;
  viewMode?: VIEW_MODE;
  performanceStore?: PerformanceStore;
  params?: { mode: VIEW_MODE; id: number };
  navigate?: NavigateFunction;
  isModal?: boolean;
  sidebarStore?: typeof SidebarStore;
}

@inject('settingsStore', 'performanceStore', 'aircraftVariationStore', 'sidebarStore')
@observer
class AircraftVariationEditor extends BaseUpsertComponent<Props, AircraftVariationModel> {
  @observable protected aircraftVariation: AircraftVariationModel = new AircraftVariationModel();
  @observable private file: File | null = null;

  constructor(p: Props) {
    super(p, fields);
    const mode: string = this.props.params?.mode.toUpperCase() || '';
    this.setViewMode(VIEW_MODE[mode] || VIEW_MODE.DETAILS);
  }

  public get hasError(): boolean {
    return this.form.hasError;
  }

  /* istanbul ignore next */
  componentDidMount() {
    this.props.sidebarStore?.setNavLinks(updateAircraftSidebarOptions('Aircraft Variation'), 'aircraft');
    UIStore.setPageLoader(true);
    forkJoin([ this.loadAircraftVariationById(), this.settingsStore.getAerodromeRefCodes() ])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(([ response ]) => {
        this.aircraftVariation = new AircraftVariationModel(response);
        this.setFormValues(this.aircraftVariation);
        this.setCappsRecordId();
        this.setCappsModel();
      });
  }

  private setCappsRecordId(): void {
    this.getField('cappsRecordId').set(this.cappsRecordId);
  }

  private setCappsModel(): void {
    this.getField('cappsModel').set(this.cappsModel);
  }

  /* istanbul ignore next */
  private loadAircraftVariationById(): Observable<AircraftVariationModel> {
    if (!this.aircraftVariationId) {
      return of(this.aircraftVariation);
    }
    const request: IAPIGridRequest = {
      filterCollection: JSON.stringify([
        { propertyName: 'AircraftVariationId', propertyValue: this.aircraftVariationId },
      ]),
    };
    return this.aircraftVairationStore.getAircraftVariationById(request);
  }

  /* istanbul ignore next */
  private uploadAircraftVariationPicture(): Observable<AircraftVariationPictureModel> {
    if (!this.file) {
      return of(new AircraftVariationPictureModel({ url: this.pictureUrl }));
    }
    return this.aircraftVairationStore.uploadAircraftVariationPicture(this.file, this.aircraftVariation?.id);
  }

  /* istanbul ignore next */
  private upsertAircraftVariation(aircraftVariation: AircraftVariationModel): void {
    UIStore.setPageLoader(true);
    this.uploadAircraftVariationPicture()
      .pipe(
        switchMap(aircraftVariationPicture =>
          this.aircraftVairationStore.upsertAircraftVariation(
            new AircraftVariationModel({ ...aircraftVariation, pictureUrl: aircraftVariationPicture.url })
          )
        ),
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: AircraftVariationModel) => {
          const viewMode = this.props.params?.mode.toUpperCase();
          if (viewMode === VIEW_MODE.DETAILS) {
            this.viewMode = VIEW_MODE.DETAILS;
            this.form.reset();
            this.aircraftVariation = response;
            this.setFormValues(response);
            return;
          }
          this.navigateToAircraftVariations();
        },
        error: error => AlertStore.critical(error.message),
      });
  }

  /* istanbul ignore next */
  private validateUnique(): void {
    const aircraftVariation: AircraftVariationModel = new AircraftVariationModel({
      ...this.aircraftVariation,
      ...this.form.values(),
    });
    UIStore.setPageLoader(true);
    this.aircraftVairationStore
      .validateUnique(aircraftVariation)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        response => {
          if (response.isValid) {
            this.upsertAircraftVariation(aircraftVariation);
            return;
          }
          UIStore.setPageLoader(false);
          const message = 'A record already exists with the combination selected. Please edit existing record.';
          this.showAlert(message, 'aircraftVariationId');
        },
        () => UIStore.setPageLoader(false)
      );
  }

  private onAction(action: GRID_ACTIONS): void {
    switch (action) {
      case GRID_ACTIONS.EDIT:
        this.setViewMode(VIEW_MODE.EDIT);
        break;
      case GRID_ACTIONS.SAVE:
        this.validateUnique();
        break;
      case GRID_ACTIONS.CANCEL:
        this.onCancel();
        break;
    }
  }

  private get aircraftVariationId(): number {
    return Number(this.props.params?.id);
  }

  /* istanbul ignore next */
  private onFocus(fieldKey: string): void {
    switch (fieldKey) {
      case 'make':
        this.observeSearch(this.settingsStore.getMakes());
        break;
      case 'model':
        this.observeSearch(this.settingsStore.getAircraftModels());
        break;
      case 'series':
        this.observeSearch(this.settingsStore.getSeries());
        break;
      case 'performances':
        this.observeSearch(this.performanceStore.getPerformances());
        break;
      case 'engineType':
        this.observeSearch(this.settingsStore.getEngineTypes());
        break;
      case 'modifications':
        this.observeSearch(this.settingsStore.getAircraftModifications());
        break;
      case 'icaoTypeDesignator':
        this.observeSearch(this.settingsStore.getICAOTypeDesignators());
        break;
      case 'fuelType':
        this.observeSearch(this.settingsStore.getFuelTypeProfile());
        break;
      case 'subCategory':
        this.observeSearch(this.settingsStore.getSubCategories());
        break;
      case 'category':
        this.observeSearch(this.settingsStore.getCategories());
        break;
      case 'fireCategory':
        this.observeSearch(this.settingsStore.getFireCategory());
        break;
      case 'wakeTurbulenceCategory':
        this.observeSearch(this.settingsStore.getWakeTurbulenceCategories());
        break;
      case 'distanceUOM':
        this.observeSearch(this.settingsStore.getDistanceUOMs());
        break;
      case 'rangeUOM':
        this.observeSearch(this.settingsStore.getRangeUOMs());
        break;
      case 'windUOM':
        this.observeSearch(this.settingsStore.getWindUOMs());
        break;
      case 'stcManufactures':
        this.observeSearch(this.settingsStore.getStcManufactures());
        break;
      case 'otherNames':
        this.observeSearch(this.settingsStore.getOtherNames());
        break;
      case 'sourceType':
        this.observeSearch(this.settingsStore.getSourceTypes());
        break;
      case 'popularNames':
        this.observeSearch(this.settingsStore.getPopularNames());
        break;
    }
  }

  /* istanbul ignore next */
  protected get aircraftVairationStore(): AircraftVariationStore {
    return this.props.aircraftVariationStore as AircraftVariationStore;
  }

  /* istanbul ignore next */
  protected get settingsStore(): SettingsStore {
    return this.props.settingsStore as SettingsStore;
  }

  /* istanbul ignore next */
  private get performanceStore(): PerformanceStore {
    return this.props.performanceStore as PerformanceStore;
  }

  @action
  protected onCancel(): void {
    const viewMode = this.props.params?.mode.toUpperCase();
    if (viewMode === VIEW_MODE.DETAILS) {
      this.viewMode = VIEW_MODE.DETAILS;
      this.form.reset();
      this.setFormValues(this.aircraftVariation);
      this.setCappsRecordId();
      this.setCappsModel();
      return;
    }
    this.navigateToAircraftVariations();
  }

  @action
  protected onValueChange(value: IOptionValue, fieldKey: string): void {
    switch (fieldKey) {
      case 'make':
        this.clearFormFields([ 'series', 'model' ]);
        this.getField(fieldKey).set(value);
        break;
      case 'model':
        this.clearFormFields([ 'series' ]);
        this.getField(fieldKey).set(value);
        break;
      case 'series':
        this.clearFormFields([ 'engineType' ]);
        this.getField(fieldKey).set(value);
        break;
      default:
        this.getField(fieldKey).set(value);
    }
    this.setCappsRecordId();
    this.setCappsModel();
  }

  /* istanbul ignore next */
  private navigateToAircraftVariations(): void {
    this.props.navigate && this.props.navigate('/aircraft/aircraft-variation');
  }

  /* istanbul ignore next */
  private get modelOptions(): AircraftModel[] {
    const makeId: number = this.getField('make').value?.value;
    return this.settingsStore.aircraftModels.filter(x => x.modelMakes?.some(y => y.id === makeId));
  }

  /* istanbul ignore next */
  private get seriesOptions(): SeriesModel[] {
    const modelId: number = this.getField('model').value?.value;
    return this.settingsStore.series.filter(x => x.seriesModels?.some(y => y.id === modelId));
  }

  /* istanbul ignore next */
  private get engineTypeOptions(): EngineTypeModel[] {
    const seriesId: number = this.getField('series').value?.value;
    if (!seriesId) {
      return this.settingsStore.engineTypes;
    }
    return this.settingsStore.engineTypes.filter(x => x.engineTypeSeries?.some(y => y.id === seriesId));
  }

  /* istanbul ignore next */
  private get subCategoryOptions(): SubCategoryModel[] {
    const categoryId: number = this.getField('category').value?.value;
    if (!categoryId) {
      return this.settingsStore.subCategories;
    }
    return this.settingsStore.subCategories.filter(x => x.category?.id === categoryId);
  }

  /* istanbul ignore next */
  protected get groupInputControls(): IGroupInputControls[] {
    return [
      {
        title: 'Variation',
        inputControls: [
          {
            fieldKey: 'make',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.makes,
            isLoading: this.loader.isLoading,
          },
          {
            fieldKey: 'model',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.modelOptions,
            isDisabled: !Boolean(this.getField('make').value?.label),
            isLoading: this.loader.isLoading,
          },
          {
            fieldKey: 'series',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.seriesOptions,
            isDisabled: !Boolean(this.getField('model').value?.label),
            isLoading: this.loader.isLoading,
          },
          {
            fieldKey: 'icaoTypeDesignator',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.icaoTypeDesignators,
            isLoading: this.loader.isLoading,
          },

          {
            fieldKey: 'engineType',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.engineTypeOptions,
            isLoading: this.loader.isLoading,
          },
          {
            fieldKey: 'numberOfEngines',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'wakeTurbulenceCategory',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.wakeTurbulenceCategories,
            isLoading: this.loader.isLoading,
          },
          {
            fieldKey: 'popularNames',
            type: EDITOR_TYPES.DROPDOWN,
            multiple: true,
            options: this.settingsStore.popularNames,
            isLoading: this.loader.isLoading,
          },
          {
            fieldKey: 'otherNames',
            type: EDITOR_TYPES.DROPDOWN,
            multiple: true,
            options: this.settingsStore.otherNames,
            isLoading: this.loader.isLoading,
          },
          {
            fieldKey: 'modifications',
            type: EDITOR_TYPES.DROPDOWN,
            multiple: true,
            options: this.settingsStore.aircraftModifications,
            isLoading: this.loader.isLoading,
          },
          {
            fieldKey: 'stcManufactures',
            type: EDITOR_TYPES.DROPDOWN,
            multiple: true,
            options: this.settingsStore.stcManufactures,
            isLoading: this.loader.isLoading,
          },
          {
            fieldKey: 'cappsModel',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: true,
            isHidden: true,
          },
          {
            fieldKey: 'cappsEngineType',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'cappsCruiseSchedule',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'cappsSeries',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'cappsRecordId',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: true,
          },
          {
            fieldKey: 'isUwaFlightPlanSupported',
            type: EDITOR_TYPES.CHECKBOX,
          },
          {
            fieldKey: 'isVerificationComplete',
            type: EDITOR_TYPES.CHECKBOX,
          },
        ],
      },
      {
        title: 'General',
        inputControls: [
          {
            fieldKey: 'fuelType',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.fuelTypeProfile,
            isLoading: this.loader.isLoading,
          },
          {
            fieldKey: 'fireCategory',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.fireCategories.slice()?.sort((a, b) => Number(a.label) - Number(b.label)),
            isLoading: this.loader.isLoading,
          },
          {
            fieldKey: 'category',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.categories,
            isLoading: this.loader.isLoading,
          },
          {
            fieldKey: 'subCategory',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.subCategoryOptions,
            isLoading: this.loader.isLoading,
          },
          {
            fieldKey: 'wingspan',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
        ],
      },
      {
        title: 'Performance',
        inputControls: [
          {
            fieldKey: 'performances',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.performanceStore.performances,
            isLoading: this.loader.isLoading,
            multiple: true,
            isFullFlex: true,
          },
        ],
      },
      {
        title: 'Limitations',
        inputControls: [
          {
            fieldKey: 'minimumRunwayLength',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'range',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'maxCrosswind',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'maxTailWind',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
        ],
      },
      {
        title: 'Comments',
        inputControls: [
          {
            fieldKey: 'comments',
            type: EDITOR_TYPES.RICH_TEXT_EDITOR,
            isFullFlex: true,
            showExpandButton: false,
          },
        ],
      },
      {
        title: 'Picture',
        inputControls: [],
      },
    ];
  }

  private get headerActions(): ReactNode {
    const { isModal } = this.props;
    return (
      <>
        {!this.isEditable && !isModal && (
          <CustomLinkButton to="/aircraft/aircraft-variation" title="Aircraft Variations" startIcon={<ArrowBack />} />
        )}
        <EditSaveButtons
          disabled={this.hasError || UIStore.pageLoading}
          hasEditPermission={!isModal && AircraftModuleSecurity.isEditable}
          isEditMode={this.isEditable}
          onAction={action => this.onAction(action)}
        />
      </>
    );
  }

  /* istanbul ignore next */
  private openUploadPictureModal(): void {
    ModalStore.open(
      <ImportDialog
        title="Select Picture"
        fileType="jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF"
        isLoading={() => this.isLoading}
        onUploadFile={file => {
          this.file = file;
          this.getField('pictureUrl').set('');
          ModalStore.close();
          return;
        }}
      />
    );
  }

  /* istanbul ignore next */
  private refreshPerformance(): void {
    UIStore.setPageLoader(true);
    forkJoin([ this.getPerformanceById(), this.performanceStore.getPerformances(true) ])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(([ performance ]) => {
        if (performance) {
          this.getField('performances').set(performance);
        }
      });
  }

  /* istanbul ignore next */
  private getPerformanceById(): Observable<PerformanceModel | null> {
    const { performance } = this.form.values();
    if (!performance?.id) {
      return of(null);
    }
    const request: IAPIGridRequest = {
      filterCollection: JSON.stringify([ Utilities.getFilter('PerformanceId', performance?.id) ]),
    };
    return this.performanceStore.getPerformanceById(request);
  }

  public get cappsRecordId(): string {
    const { icaoTypeDesignator, make, model, series, engineType } = this.form.values();
    return [ icaoTypeDesignator?.label, make?.label, model?.label, series?.label, engineType?.label ]
      .filter(x => x)
      .join('_');
  }

  private get cappsModel(): string {
    const { popularNames, otherNames, stcManufactures, modifications, model } = this.form.values();
    const result = [
      popularNames?.map((x: SettingsTypeModel) => x.label).join(' - '),
      otherNames?.map((x: SettingsTypeModel) => x.label).join(' - '),
      stcManufactures?.map((x: SettingsTypeModel) => x.label).join(' - '),
      modifications?.map((x: SettingsTypeModel) => x.label).join(' - '),
    ]
      .filter(x => x)
      .join(', ');
    return `${model?.label || ''} ${result ? `( ${result} )` : ''}`;
  }

  private get hasPicture(): boolean {
    const { pictureUrl } = this.form.values();
    return Boolean(this.file || pictureUrl);
  }

  private get pictureUrl(): string {
    return this.getField('pictureUrl').value || '';
  }

  private get aircraftVariationPicture(): ReactNode {
    const { classes, isModal } = this.props as Required<Props>;
    const imageSrc = this.file ? URL.createObjectURL(this.file) : this.aircraftVariation?.pictureAccessTokenUrl;
    return (
      <div key="aircaftVariationPicture">
        <CollapsibleWithButton
          titleVariant="h5"
          title="Picture"
          buttonText="Upload Picture"
          hasPermission={!isModal}
          titleChildren={
            !isModal && this.hasPicture ? (
              <IconButton
                disabled={!this.isEditable}
                classes={{ root: classes.deleteIcon }}
                onClick={(event: MouseEvent<Element>) => {
                  event.stopPropagation();
                  this.file = null;
                  this.getField('pictureUrl').set('');
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            ) : (
              <></>
            )
          }
          onButtonClick={() => this.openUploadPictureModal()}
          isButtonDisabled={this.hasPicture || !this.isEditable || !AircraftModuleSecurity.isEditable}
        >
          <div className={classes.pictureRoot}>
            <ViewPermission hasPermission={this.hasPicture}>
              <img className={classes.picture} src={imageSrc} onClick={() => window.open(imageSrc)} />
            </ViewPermission>
            <ViewPermission hasPermission={!this.hasPicture}>
              <Typography>No image to display.</Typography>
            </ViewPermission>
          </div>
        </CollapsibleWithButton>
      </div>
    );
  }

  private get systemInputControls(): IViewInputControl[] {
    return [
      {
        fieldKey: 'sourceType',
        type: EDITOR_TYPES.DROPDOWN,
        options: this.settingsStore.sourceTypes,
      },
      {
        fieldKey: 'status',
        type: EDITOR_TYPES.DROPDOWN,
        options: ModelStatusOptions,
      },
    ];
  }

  private get systemDataFields(): ReactNode {
    const { classes } = this.props as Required<Props>;
    return (
      <Collapsable title="System">
        <>
          <div className={classes.flexWrap}>
            {this.systemInputControls
              .filter(inputControl => !inputControl.isHidden)
              .map((inputControl: IViewInputControl, index: number) => (
                <ViewInputControl
                  {...inputControl}
                  key={index}
                  field={this.getField(inputControl.fieldKey || '')}
                  isEditable={this.isEditable}
                  onValueChange={(option, _) => this.onValueChange(option, inputControl.fieldKey || '')}
                  onFocus={(fieldKey: string) => this.onFocus(fieldKey)}
                />
              ))}
          </div>
          <AuditFields
            isEditable={this.isEditable}
            fieldControls={this.auditFields}
            onGetField={(fieldKey: string) => this.getField(fieldKey)}
            isNew={this.isAddNew}
          />
        </>
      </Collapsable>
    );
  }

  public render(): ReactNode {
    const { classes, isModal } = this.props as Required<Props>;
    return (
      <DetailsEditorWrapper headerActions={this.headerActions} isEditMode={this.isEditable}>
        <div className={classes.flexRow}>
          {this.groupInputControls.map(groupInputControl => {
            if (groupInputControl.title === 'Picture') {
              return this.aircraftVariationPicture;
            }
            return (
              <AircraftCollapsable
                isWithButton={groupInputControl.title === 'Variation'}
                classes={classes}
                key={groupInputControl.title}
                title={groupInputControl.title}
                onButtonClick={() => this.refreshPerformance()}
                customIconButton={<Cached color="primary" />}
                hasPermission={!isModal}
              >
                <div className={classes.flexWrap}>
                  {groupInputControl.inputControls
                    .filter(inputControl => !inputControl.isHidden)
                    .map((inputControl: IViewInputControl, index: number) => (
                      <ViewInputControl
                        {...inputControl}
                        key={index}
                        customErrorMessage={inputControl.customErrorMessage}
                        field={this.getField(inputControl.fieldKey || '')}
                        isEditable={this.isEditable}
                        isExists={inputControl.isExists}
                        classes={{
                          flexRow: classNames({
                            [classes.inputControl]: true,
                            [classes.fullFlex]: inputControl.isFullFlex,
                          }),
                        }}
                        onValueChange={(option, _) => this.onValueChange(option, inputControl.fieldKey || '')}
                        onFocus={(fieldKey: string) => this.onFocus(fieldKey)}
                      />
                    ))}
                </div>
              </AircraftCollapsable>
            );
          })}
          {this.systemDataFields}
        </div>
      </DetailsEditorWrapper>
    );
  }
}

export default withRouter(withStyles(styles)(AircraftVariationEditor));
export { AircraftVariationEditor as PureAircraftVariationEditor };
export const AircraftVariationWithoutRoute = withStyles(styles)(AircraftVariationEditor);
