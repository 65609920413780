import React, { FC, ReactNode, RefObject, useEffect, useRef } from 'react';
import { ColDef, GridOptions, ICellEditorParams, ValueFormatterParams } from 'ag-grid-community';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { CountryModel } from '@wings/shared';
import { Logger, SettingsModuleSecurity } from '@wings-shared/security';
import { inject, observer } from 'mobx-react';
import {
  AVIONICS_SOFTWARE_VERSION_FILTERS,
  AvionicsSettingsStore,
  AcarsModel,
  AcarsSoftwareVersionModel,
} from '../../../Shared';
import { AlertStore } from '@uvgo-shared/alert';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { finalize, takeUntil } from 'rxjs/operators';
import { AxiosError } from 'axios';
import { forkJoin } from 'rxjs';
import { ISearchHeaderRef, SearchHeaderV2 } from '@wings-shared/form-controls';
import { UIStore, Utilities, GRID_ACTIONS } from '@wings-shared/core';
import { CustomAgGridReact, useAgGrid, useGridState, agGridUtilities } from '@wings-shared/custom-ag-grid';
import { useUnsubscribe } from '@wings-shared/hooks';

interface Props {
  avionicsSettingsStore?: AvionicsSettingsStore;
}

const AcarsSoftwareVersionV2: FC<Props> = ({ avionicsSettingsStore }) => {
  const alertMessageId: string = 'AcarsSoftwareAlertMessage';
  const unsubscribe = useUnsubscribe();
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  const gridState = useGridState();
  const agGrid = useAgGrid<AVIONICS_SOFTWARE_VERSION_FILTERS, AcarsSoftwareVersionModel>([], gridState);
  const _settingsStore = avionicsSettingsStore as AvionicsSettingsStore;

  /* istanbul ignore next */
  useEffect(() => {
    loadInitialData();
  }, []);

  /* istanbul ignore next */
  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    forkJoin([ _settingsStore.getAcarsSoftwareVersions(), _settingsStore.getAcarsModels() ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(([ acarsSoftwareVersions ]) => gridState.setGridData(acarsSoftwareVersions));
  };

  const addNewType = () => {
    agGrid.addNewItems([ new AcarsSoftwareVersionModel({ id: 0 }) ], {
      startEditing: false,
      colKey: 'name',
    });
    gridState.setHasError(true);
  };

  // Called from Ag Grid Component
  const onInputChange = (params: ICellEditorParams, value: string): void => {
    gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi));
  };

  // Called from Ag Grid Component
  const onDropDownChange = (params: ICellEditorParams, value: string): void => {
    gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi));
  };

  const isAlreadyExists = (id: number): boolean => {
    if (agGrid._isAlreadyExists([ 'name' ], id)) {
      agGrid.showAlert('Name should be unique.', alertMessageId);
      return true;
    }
    return false;
  };

  const gridActions = (gridAction: GRID_ACTIONS, rowIndex: number): void => {
    if (rowIndex === null) {
      return;
    }
    switch (gridAction) {
      case GRID_ACTIONS.EDIT:
        agGrid._startEditingCell(rowIndex, columnDefs[0].field || '');
        break;
      case GRID_ACTIONS.SAVE:
        upsertAcarsSoftwareVersion(rowIndex);
        break;
      case GRID_ACTIONS.CANCEL:
      default:
        agGrid.cancelEditing(rowIndex);
        break;
    }
  };

  /* istanbul ignore next */
  const upsertAcarsSoftwareVersion = (rowIndex: number): void => {
    const model = agGrid._getTableItem(rowIndex);
    if (isAlreadyExists(model.id)) {
      return;
    }
    gridState.gridApi.stopEditing();
    UIStore.setPageLoader(true);
    _settingsStore
      .upsertAscarSoftwareVersion(model)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: AcarsSoftwareVersionModel) => {
          agGrid._sortColumns(rowIndex, response, 'name');
        },
        error: (error: AxiosError) => {
          AlertStore.critical(error.message);
          Logger.error(error.message);
        },
      });
  };

  /* istanbul ignore next */
  const columnDefs: ColDef[] = [
    {
      headerName: 'Name',
      field: 'name',
      cellEditorParams: {
        rules: 'required|string|between:1,50',
      },
    },
    {
      headerName: 'Model',
      field: 'acarsModel',
      cellEditor: 'customAutoComplete',
      comparator: (current: AcarsModel, next: AcarsModel) => Utilities.customComparator(current, next, 'name'),
      filter: false,
      valueFormatter: ({ value }: ValueFormatterParams) => value?.name,
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'ACARS Model',
        getAutoCompleteOptions: () => _settingsStore.acarsModels,
        valueGetter: (option: CountryModel) => option,
      },
    },
    {
      ...agGrid.actionColumn({
        minWidth: 150,
        maxWidth: 210,
        hide: !SettingsModuleSecurity.isEditable,
      }),
    },
  ];

  /* istanbul ignore next */
  const gridOptions = (): GridOptions => {
    const baseOptions: Partial<GridOptions> = agGrid.gridOptionsBase({
      context: { onInputChange, onDropDownChange },
      columnDefs,
      isEditable: SettingsModuleSecurity.isEditable,
      gridActionProps: {
        showDeleteButton: false,
        getDisabledState: () => gridState.hasError,
        onAction: gridActions,
      },
    });
    return {
      ...baseOptions,
      isExternalFilterPresent: () => searchHeaderRef.current?.hasSearchValue || false,
      doesExternalFilterPass: node => {
        const searchHeader = searchHeaderRef.current;
        const { id, name, acarsModel } = node.data as AcarsSoftwareVersionModel;
        if (!searchHeader) {
          return false;
        }
        return (
          !id ||
          agGrid.isFilterPass(
            {
              [AVIONICS_SOFTWARE_VERSION_FILTERS.NAME]: name,
              [AVIONICS_SOFTWARE_VERSION_FILTERS.MODEL]: acarsModel.name,
            },
            searchHeader.searchValue,
            searchHeader.selectedOption
          )
        );
      },
    };
  };

  const rightContent = (): ReactNode => {
    if (!SettingsModuleSecurity.isEditable) {
      return null;
    }
    return (
      <PrimaryButton
        variant="contained"
        startIcon={<AddIcon />}
        disabled={gridState.isRowEditing || UIStore.pageLoading}
        onClick={addNewType}
      >
        Add ACARS Software Version
      </PrimaryButton>
    );
  };

  return (
    <>
      <SearchHeaderV2
        ref={searchHeaderRef as RefObject<ISearchHeaderRef>}
        selectInputs={[
          agGridUtilities.createSelectOption(AVIONICS_SOFTWARE_VERSION_FILTERS, AVIONICS_SOFTWARE_VERSION_FILTERS.NAME),
        ]}
        onFilterChange={() => gridState.gridApi.onFilterChanged()}
        rightContent={rightContent}
        disableControls={gridState.isRowEditing}
      />
      <CustomAgGridReact
        isRowEditing={gridState.isRowEditing}
        rowData={gridState.data}
        gridOptions={gridOptions()}
        disablePagination={gridState.isRowEditing}
      />
    </>
  );
};

export default inject('avionicsSettingsStore')(observer(AcarsSoftwareVersionV2));
