import React, { ReactNode } from 'react';
import { VIEW_MODE, BaseUpsertComponent, ModelStatusOptions } from '@wings/shared';
import {
  AuditFields,
  EDITOR_TYPES,
  ViewInputControl,
  IViewInputControl,
  IGroupInputControls,
} from '@wings-shared/form-controls';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { fields } from './Fields';
import { styles } from './EtpPolicyEditor.style';
import {
  SettingsStore,
  EtpPolicyModel,
  EtpPolicyStore,
  EtpScenarioStore,
  AircraftModuleSecurity,
  updateAircraftSidebarOptions
} from '../../../Shared';
import { finalize, takeUntil } from 'rxjs/operators';
import { NavigateFunction } from 'react-router';
import { ArrowBack } from '@material-ui/icons';
import { AlertStore } from '@uvgo-shared/alert';
import { forkJoin, Observable, of } from 'rxjs';
import { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete';
import { Chip, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import {
  withRouter,
  Utilities,
  UIStore,
  IClasses,
  ISelectOption,
  SettingsTypeModel,
  GRID_ACTIONS,
} from '@wings-shared/core';
import { CustomLinkButton, EditSaveButtons, DetailsEditorWrapper, SidebarStore } from '@wings-shared/layout';

interface Props {
  classes?: IClasses;
  etpPolicyStore?: EtpPolicyStore;
  settingsStore?: SettingsStore;
  etpScenarioStore?: EtpScenarioStore;
  viewMode?: VIEW_MODE;
  params?: { mode: VIEW_MODE; id: number };
  navigate?: NavigateFunction;
  sidebarStore?: typeof SidebarStore;
}

@inject('etpPolicyStore', 'settingsStore', 'etpScenarioStore', 'sidebarStore')
@observer
class EtpPolicyEditor extends BaseUpsertComponent<Props, EtpPolicyModel> {
  @observable private etpPolicy: EtpPolicyModel = new EtpPolicyModel();

  constructor(p: Props) {
    super(p, fields);
    const mode: string = this.props.params?.mode?.toUpperCase() || '';
    this.setViewMode(VIEW_MODE[mode] || VIEW_MODE.DETAILS);
  }

  public get hasError(): boolean {
    return this.form.hasError;
  }

  private get etpPolicyStore(): EtpPolicyStore {
    return this.props.etpPolicyStore as EtpPolicyStore;
  }

  private get settingsStore(): SettingsStore {
    return this.props.settingsStore as SettingsStore;
  }

  private get etpScenarioStore(): EtpScenarioStore {
    return this.props.etpScenarioStore as EtpScenarioStore;
  }

  /* istanbul ignore next */
  componentDidMount() {
    this.props.sidebarStore?.setNavLinks(updateAircraftSidebarOptions('ETP Policy'), 'aircraft');
    UIStore.setPageLoader(true);
    forkJoin([
      this.getEtpPolicyById(),
      this.etpPolicyStore.getEtpPolicies(),
      this.settingsStore.getAccessLevels(),
      this.settingsStore.getSourceTypes(),
      this.etpScenarioStore.getEtpScenarios(),
    ])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(([ etpPolicy ]) => {
        this.etpPolicy = new EtpPolicyModel(etpPolicy);
        this.setFormValues(this.etpPolicy);
      });
  }

  private getEtpPolicyById(): Observable<EtpPolicyModel> {
    if (!this.etpPolicyId) {
      return of(this.etpPolicy);
    }
    return this.etpPolicyStore.getEtpPolicyById(this.etpPolicyId);
  }

  /* istanbul ignore next */
  private upsertEtpPolicy(): void {
    const etpPolicy: EtpPolicyModel = this.getUpdatedModel();
    if (this.etpPolicyStore.isAlreadyExists(etpPolicy)) {
      this.showAlert('Policy Code should be unique.', 'etpPolicyId');
      return;
    }
    UIStore.setPageLoader(true);
    this.etpPolicyStore
      .upsertEtpPolicy(etpPolicy)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: () => this.navigateToEtpPolicy(),
        error: error => AlertStore.critical(error.message),
      });
  }

  private getUpdatedModel(): EtpPolicyModel {
    return new EtpPolicyModel({
      ...this.etpPolicy,
      ...this.form.values(),
    });
  }

  @action
  protected onCancel(): void {
    const viewMode = this.props.params?.mode.toUpperCase() || VIEW_MODE.DETAILS;
    if (!Utilities.isEqual(viewMode, VIEW_MODE.DETAILS)) {
      this.navigateToEtpPolicy();
      return;
    }
    this.setViewMode(VIEW_MODE.DETAILS);
    this.form.reset();
    this.setFormValues(this.etpPolicy);
  }

  private onAction(action: GRID_ACTIONS) {
    switch (action) {
      case GRID_ACTIONS.EDIT:
        this.setViewMode(VIEW_MODE.EDIT);
        break;
      case GRID_ACTIONS.SAVE:
        this.upsertEtpPolicy();
        break;
      case GRID_ACTIONS.CANCEL:
        this.onCancel();
        break;
    }
  }

  /* istanbul ignore next */
  private navigateToEtpPolicy(): void {
    this.props.navigate && this.props.navigate('/aircraft/etp-policy');
  }

  private get etpPolicyId(): number {
    return Number(this.props.params?.id);
  }

  /* istanbul ignore next */
  private get groupInputControls(): IGroupInputControls {
    return {
      title: '',
      inputControls: [
        {
          fieldKey: 'code',
          type: EDITOR_TYPES.TEXT_FIELD,
        },
        {
          fieldKey: 'etpScenarios',
          type: EDITOR_TYPES.DROPDOWN,
          options: this.etpScenarioStore.etpScenarios,
          isLoading: true,
          multiple: true,
        },
        {
          fieldKey: 'description',
          type: EDITOR_TYPES.TEXT_FIELD,
          isFullFlex: true,
          multiline: true,
          rows: 5,
        },
        {
          fieldKey: 'comments',
          type: EDITOR_TYPES.TEXT_FIELD,
          isFullFlex: true,
          multiline: true,
          rows: 5,
        },
        {
          fieldKey: 'accessLevel',
          type: EDITOR_TYPES.DROPDOWN,
          options: this.settingsStore.accessLevels,
          isLoading: true,
        },
        {
          fieldKey: 'sourceType',
          type: EDITOR_TYPES.DROPDOWN,
          options: this.settingsStore.sourceTypes,
          isLoading: true,
        },
        {
          fieldKey: 'status',
          type: EDITOR_TYPES.DROPDOWN,
          options: ModelStatusOptions,
        },
      ],
    };
  }

  private get title(): string {
    const { code } = this.form.values();
    return code || 'Policy Code';
  }

  private viewRenderer(etpScenario: SettingsTypeModel[], getTagProps?: AutocompleteGetTagProps): ReactNode {
    return etpScenario
      .sort((a, b) => Number(a.label) - Number(b.label))
      .map((etpScenario: SettingsTypeModel, index) => (
        <Chip
          classes={{ root: this.props.classes?.chip }}
          key={etpScenario.id}
          label={etpScenario.label}
          {...(getTagProps instanceof Function ? getTagProps({ index }) : {})}
        />
      ));
  }

  private getOptionDisabled(option: ISelectOption, value: ISelectOption[]): boolean {
    return value?.length > 5 && !value.some(x => x.label === option?.label);
  }

  private get headerActions(): ReactNode {
    return (
      <>
        {!this.isEditable && (
          <CustomLinkButton to="/aircraft/etp-policy" title="ETP Policy" startIcon={<ArrowBack />} />
        )}
        <EditSaveButtons
          disabled={this.hasError || UIStore.pageLoading}
          hasEditPermission={AircraftModuleSecurity.isEditable}
          isEditMode={this.isEditable}
          onAction={action => this.onAction(action)}
        />
      </>
    );
  }

  public render(): ReactNode {
    const { classes } = this.props as Required<Props>;
    return (
      <DetailsEditorWrapper headerActions={this.headerActions} isEditMode={this.isEditable}>
        <div className={classes.container}>
          <Typography className={classes.typography} variant="h5">
            {this.title}
          </Typography>
          <div className={classes.flexWrap}>
            {this.groupInputControls.inputControls
              .filter(inputControl => !inputControl.isHidden)
              .map((inputControl: IViewInputControl, index: number) => (
                <ViewInputControl
                  {...inputControl}
                  key={index}
                  field={this.getField(inputControl.fieldKey || '')}
                  isEditable={this.isEditable}
                  getOptionDisabled={(option: ISelectOption, selectedOption: ISelectOption | ISelectOption[]) => {
                    return this.getOptionDisabled(option, selectedOption as ISelectOption[]);
                  }}
                  classes={{
                    flexRow: classNames({
                      [classes.inputControl]: true,
                      [classes.fullFlex]: inputControl.isFullFlex,
                    }),
                    autoCompleteInputRoot: classNames({
                      [classes.autoCompleteInputRoot]: inputControl.multiple,
                    }),
                  }}
                  renderTags={(values, getTagProps: AutocompleteGetTagProps) =>
                    this.viewRenderer(values as SettingsTypeModel[], getTagProps)
                  }
                  onValueChange={(option, fieldKey) => this.onValueChange(option, inputControl.fieldKey || '')}
                />
              ))}
          </div>
          <AuditFields
            isEditable={this.isEditable}
            fieldControls={this.auditFields}
            onGetField={(fieldKey: string) => this.getField(fieldKey)}
            isNew={this.isAddNew}
          />
        </div>
      </DetailsEditorWrapper>
    );
  }
}

export default withRouter(withStyles(styles)(EtpPolicyEditor));
export { EtpPolicyEditor as PureEtpPolicyEditor };
