import React, { ReactNode } from 'react';
import { ColDef, GridOptions, ValueFormatterParams } from 'ag-grid-community';
import { Theme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { VIEW_MODE } from '@wings/shared';
import { SettingsModuleSecurity } from '@wings-shared/security';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import { AIRCRAFT_MODEL_FILTERS, SettingsStore } from '../../../Shared';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { AircraftModel, AircraftModelMakeModel } from '../../../Shared/Models';
import { finalize, takeUntil } from 'rxjs/operators';
import { AircraftModelUpsert } from './Components';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { forkJoin } from 'rxjs';
import { SearchHeader } from '@wings-shared/form-controls';
import { IClasses, UIStore, Utilities, GRID_ACTIONS, IBaseGridFilterSetup, cellStyle } from '@wings-shared/core';
import {
  AgGridCellEditor,
  CustomAgGridReact,
  BaseGrid,
  AgGridActions,
  AgGridCheckBox,
} from '@wings-shared/custom-ag-grid';

interface Props {
  settingsStore?: SettingsStore;
  classes?: IClasses;
  theme?: Theme;
}

const filtersSetup: IBaseGridFilterSetup<AIRCRAFT_MODEL_FILTERS> = {
  defaultPlaceHolder: 'Search by Name',
  filterTypesOptions: Object.values(AIRCRAFT_MODEL_FILTERS),
  defaultFilterType: AIRCRAFT_MODEL_FILTERS.NAME,
};

@inject('settingsStore')
@observer
class AircraftModels extends BaseGrid<Props, AircraftModel, AIRCRAFT_MODEL_FILTERS> {
  constructor(props) {
    super(props, filtersSetup);
  }

  componentDidMount() {
    this.loadInitialData();
  }

  private get settingsStore(): SettingsStore {
    return this.props.settingsStore as SettingsStore;
  }

  /* istanbul ignore next */
  private loadInitialData() {
    UIStore.setPageLoader(true);
    forkJoin([ this.settingsStore.getAircraftModels(), this.settingsStore.getMakes() ])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(([ aircraftModels ]) => (this.data = aircraftModels));
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Model',
      field: 'name',
      cellRenderer: 'agGroupCellRenderer',
    },
    {
      headerName: '',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      sortable: false,
      filter: false,
      hide: !SettingsModuleSecurity.isEditable,
      suppressSizeToFit: true,
      minWidth: 150,
      maxWidth: 210,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        isActionMenu: true,
        actionMenus: () => [
          {
            title: 'Edit',
            action: GRID_ACTIONS.EDIT,
          },
        ],
        onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
      },
    },
  ];

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: false,
      gridActionProps: {
        showDeleteButton: false,
        getDisabledState: () => this.hasError,
      },
    });
    return {
      ...baseOptions,
      doesExternalFilterPass: node => {
        const { name, id, modelMakes } = node.data as AircraftModel;
        return (
          !id ||
          this._isFilterPass({
            [AIRCRAFT_MODEL_FILTERS.NAME]: name,
            [AIRCRAFT_MODEL_FILTERS.MAKE]: modelMakes.map(x => x.name).toString(),
          })
        );
      },
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: [
            {
              headerName: 'Make',
              field: 'make',
              cellEditor: 'customAutoComplete',
              comparator: (current: AircraftModelMakeModel, next: AircraftModelMakeModel) =>
                Utilities.customComparator(current, next, 'name'),
              filter: false,
              valueFormatter: ({ value }: ValueFormatterParams) => value?.name,
            },
            {
              headerName: 'Large Aircraft',
              field: 'isLargeAircraft',
              cellRenderer: 'checkBoxRenderer',
              cellRendererParams: { readOnly: true },
            },
          ],
          defaultColDef: { flex: 1 },
          frameworkComponents: {
            checkBoxRenderer: AgGridCheckBox,
          },
        },
        getDetailRowData: function(params) {
          params.successCallback(params.data.modelMakes);
        },
      },
      frameworkComponents: {
        actionRenderer: AgGridActions,
        customCellEditor: AgGridCellEditor,
      },
    };
  }

  /* istanbul ignore next */
  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    const aircraftModel: AircraftModel = rowIndex !== null ? this._getTableItem(rowIndex) : new AircraftModel();
    ModalStore.open(
      <AircraftModelUpsert
        settingsStore={this.settingsStore}
        model={aircraftModel}
        viewMode={gridAction === GRID_ACTIONS.CREATE ? VIEW_MODE.NEW : VIEW_MODE.EDIT}
        onUpsert={response => {
          if (rowIndex === null) {
            this.gridApi.applyTransaction({ add: [ response ], addIndex: 0 });
            this.gridApi.redrawRows();
            this.setAircraftMmodels();
            return;
          }
          this._updateTableItem(rowIndex, response);
          this.setAircraftMmodels();
        }}
      />
    );
  }

  @action
  private setAircraftMmodels(): void {
    this.settingsStore.aircraftModels = this._getAllTableRows();
  }

  private get rightContent(): ReactNode {
    if (!SettingsModuleSecurity.isEditable) {
      return null;
    }
    return (
      <PrimaryButton
        variant="contained"
        startIcon={<AddIcon />}
        disabled={this.isProcessing}
        onClick={() => this.gridActions(GRID_ACTIONS.CREATE, null)}
      >
        Add Aircraft Model
      </PrimaryButton>
    );
  }

  public render(): ReactNode {
    return (
      <>
        <SearchHeader
          searchPlaceHolder={this.searchPlaceHolder}
          searchTypeValue={this.selectedOption}
          searchTypeOptions={this._selectOptions}
          onSearchTypeChange={selectedOption => this._setSelectedOption(selectedOption as AIRCRAFT_MODEL_FILTERS)}
          onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
          rightContent={this.rightContent}
          isDisabled={this.isRowEditing}
        />
        <CustomAgGridReact
          isRowEditing={this.isRowEditing}
          rowData={this.data}
          gridOptions={this.gridOptions}
          disablePagination={this.isRowEditing}
        />
      </>
    );
  }
}

export default AircraftModels;
