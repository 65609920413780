import React, { Component } from 'react';
import { RegistrySequenceBaseModel } from '../../../Shared';
import { Checkbox, FormControlLabel, withStyles } from '@material-ui/core';
import { styles } from './RegistryCheckbox.styles';
import { observer } from 'mobx-react';
import { IClasses } from '@wings-shared/core';

type Props = {
  classes?: IClasses;
  options: RegistrySequenceBaseModel[];
  values: RegistrySequenceBaseModel[];
  onValueChange: (option: RegistrySequenceBaseModel[]) => void;
  isEditable: boolean;
};

@observer
class RegistryCheckbox extends Component<Props> {
  private isChecked(option: RegistrySequenceBaseModel): boolean {
    const { values } = this.props;
    return values.some(a => a.id === option.id);
  }

  private onValueChange(option: RegistrySequenceBaseModel): void {
    const { onValueChange, values } = this.props;
    if (values.some(a => a.id === option.id)) {
      const newValue = values.filter(a => a.id !== option.id);
      onValueChange([ ...newValue ]);
      return;
    }
    onValueChange([ ...values, option ]);
  }

  render() {
    const { classes, options, isEditable } = this.props as Required<Props>;
    return (
      <div className={classes.flexWrap}>
        {options.map((inputOption: RegistrySequenceBaseModel, index: number) => (
          <FormControlLabel
            label={inputOption.name}
            classes={{ root: classes.checkboxRoot }}
            disabled={!isEditable}
            control={
              <Checkbox
                name={inputOption.name}
                checked={this.isChecked(inputOption)}
                onChange={(_, checked: boolean) => this.onValueChange(inputOption)}
              />
            }
          />
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(RegistryCheckbox);
export { RegistryCheckbox as PureRegistryCheckbox };
