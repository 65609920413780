import React, { ReactNode } from 'react';
import { VIEW_MODE, BaseUpsertComponent, ModelStatusOptions } from '@wings/shared';
import {
  EDITOR_TYPES,
  ViewInputControl,
  AuditFields,
  ChipViewInputControl,
  IViewInputControl,
  IGroupInputControls,
} from '@wings-shared/form-controls';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { fields } from './Fields';
import { styles } from './AirframeEditor.style';
import {
  SettingsStore,
  AirframeModel,
  AirframeStore,
  AvionicsSettingsStore,
  AcarsModel,
  AcarsSoftwareVersionModel,
  AcarsMessageSetModel,
  FmsModel,
  FmsSoftwareVersionModel,
  AircraftVariationStore,
  PerformanceStore,
  AircraftVariationModel,
  AircraftCollapsable,
  AircraftModuleSecurity,
  updateAircraftSidebarOptions
} from '../../../Shared';
import { finalize, map, switchMap, takeUntil } from 'rxjs/operators';
import { NavigateFunction } from 'react-router';
import { ArrowBack, Cached } from '@material-ui/icons';
import { Observable, of, forkJoin } from 'rxjs';
import { withStyles, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import { AlertStore } from '@uvgo-shared/alert';
import { SelectVariationView, VariationSearchDialog } from '../index';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Dialog } from '@uvgo-shared/dialog';
import { AircraftVariationWithoutRoute } from '../../../AircraftVariation';
import {
  withRouter,
  Utilities,
  UIStore,
  IClasses,
  IAPIGridRequest,
  IOptionValue,
  SelectOption,
  GRID_ACTIONS,
} from '@wings-shared/core';
import {
  Collapsable,
  CollapsibleWithButton,
  CustomLinkButton,
  DetailsEditorWrapper,
  EditSaveButtons,
  SidebarStore,
} from '@wings-shared/layout';

interface Props {
  classes?: IClasses;
  airframeStore?: AirframeStore;
  settingsStore?: SettingsStore;
  avionicsSettingsStore?: AvionicsSettingsStore;
  aircraftVariationStore: AircraftVariationStore;
  performanceStore: PerformanceStore;
  viewMode?: VIEW_MODE;
  params?: { mode: VIEW_MODE; id: number };
  navigate?: NavigateFunction;
  sidebarStore?: typeof SidebarStore;
}

@inject(
  'airframeStore',
  'settingsStore',
  'avionicsSettingsStore',
  'aircraftVariationStore',
  'performanceStore',
  'sidebarStore'
)
@observer
class AirframeEditor extends BaseUpsertComponent<Props, AirframeModel> {
  @observable private airframe: AirframeModel = new AirframeModel({ id: 0 });

  constructor(p: Props) {
    super(p, fields);
    const mode: string = this.props.params?.mode?.toUpperCase() || '';
    this.setViewMode(VIEW_MODE[mode] || VIEW_MODE.DETAILS);
  }

  public get hasError(): boolean {
    return this.form.hasError;
  }

  /* istanbul ignore next */
  private get airframeStore(): AirframeStore {
    return this.props.airframeStore as AirframeStore;
  }

  /* istanbul ignore next */
  private get settingsStore(): SettingsStore {
    return this.props.settingsStore as SettingsStore;
  }

  /* istanbul ignore next */
  private get avionicsSettingsStore(): AvionicsSettingsStore {
    return this.props.avionicsSettingsStore as AvionicsSettingsStore;
  }

  /* istanbul ignore next */
  private get acarsModelOptions(): AcarsModel[] {
    const acarsManufacturerId: number = this.getField('rdbSpecs.acarsManufacturer').value?.value;
    return this.avionicsSettingsStore.acarsModels.filter(x => x.acarsManufacturer.id === acarsManufacturerId);
  }

  /* istanbul ignore next */
  private get acarsSoftwareVersionOptions(): AcarsSoftwareVersionModel[] {
    const acarsModelId: number = this.getField('rdbSpecs.acarsModel').value?.value;
    return this.avionicsSettingsStore.acarsSoftwareVersions.filter(x => x.acarsModel.id === acarsModelId);
  }

  /* istanbul ignore next */
  private get acarsMessageSetOptions(): AcarsMessageSetModel[] {
    const acarsMessageSetId: number = this.getField('rdbSpecs.acarsSoftwareVersion').value?.value;
    return this.avionicsSettingsStore.acarsMessageSets.filter(x => x.acarsSoftwareVersion.id === acarsMessageSetId);
  }

  /* istanbul ignore next */
  private get fmsModelOptions(): FmsModel[] {
    const fmsManufacturerId: number = this.getField('rdbSpecs.fmsManufacturer').value?.value;
    return this.avionicsSettingsStore.fmsModels.filter(x => x.fmsManufacturer.id === fmsManufacturerId);
  }

  /* istanbul ignore next */
  private get fmsSoftwareVersionOptions(): FmsSoftwareVersionModel[] {
    const fmsModelId: number = this.getField('rdbSpecs.fmsModel').value?.value;
    return this.avionicsSettingsStore.fmsSoftwareVersions.filter(x => x.fmsModel.id === fmsModelId);
  }

  /* istanbul ignore next */
  componentDidMount() {
    this.props.sidebarStore?.setNavLinks(updateAircraftSidebarOptions('Airframe'), 'aircraft');
    UIStore.setPageLoader(true);
    forkJoin([ this.getAirframById(), this.airframeStore.getAirframes() ])
      .pipe(
        switchMap(([ airframe ]) => {
          if (airframe.aircraftVariation?.id) {
            const request: IAPIGridRequest = {
              filterCollection: JSON.stringify([
                { propertyName: 'AircraftVariationId', propertyValue: airframe.aircraftVariation?.id },
              ]),
            };
            return this.props.aircraftVariationStore
              .getAircraftVariationById(request)
              .pipe(map(aircraftVariation => new AirframeModel({ ...airframe, aircraftVariation })));
          }
          return of(airframe);
        }),
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(airframe => {
        this.airframe = airframe;
        this.setFormValues(this.airframe);
      });
  }

  /* istanbul ignore next */
  private getAirframById(): Observable<AirframeModel> {
    if (!this.airframeId) {
      return of(this.airframe);
    }
    return this.airframeStore.getAirframById(this.airframeId);
  }

  /* istanbul ignore next */
  private getUpdatedModel(): AirframeModel {
    return new AirframeModel({
      ...this.airframe,
      ...this.form.values(),
    });
  }

  /* istanbul ignore next */
  private isAlreadyExists(airframe: AirframeModel): boolean {
    const { airframes } = this.airframeStore;
    return airframes.some(
      x => Utilities.isEqual(x.serialNumber, airframe.serialNumber) && !Utilities.isEqual(x.id, airframe.id)
    );
  }

  /* istanbul ignore next */
  private upsertAirframe(): void {
    const airframeData: AirframeModel = this.getUpdatedModel();
    if (this.isAlreadyExists(airframeData)) {
      this.showAlert('Serial number should be unique', 'airframeAlert');
      return;
    }
    UIStore.setPageLoader(true);
    this.airframeStore
      .upsertAirframe(airframeData)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: () => {
          this.form.reset();
          this.navigateToAirframe();
        },
        error: error => AlertStore.critical(error.message),
      });
  }

  @action
  protected onValueChange(value: IOptionValue, fieldKey: string): void {
    switch (fieldKey) {
      case 'rdbSpecs.acarsManufacturer':
        this.clearFormFields([ 'rdbSpecs.acarsModel', 'rdbSpecs.acarsSoftwareVersion', 'rdbSpecs.acarsMessageSet' ]);
        this.getField(fieldKey).set(value);
        break;
      case 'rdbSpecs.acarsModel':
        this.clearFormFields([ 'rdbSpecs.acarsSoftwareVersion', 'rdbSpecs.acarsMessageSet' ]);
        this.getField(fieldKey).set(value);
        break;
      case 'rdbSpecs.acarsSoftwareVersion':
        this.clearFormFields([ 'rdbSpecs.acarsMessageSet' ]);
        this.getField(fieldKey).set(value);
        break;
      case 'rdbSpecs.fmsManufacturer':
        this.clearFormFields([ 'rdbSpecs.fmsModel', 'rdbSpecs.fmsSoftwareVersion' ]);
        this.getField(fieldKey).set(value);
        break;
      case 'rdbSpecs.fmsModel':
        this.clearFormFields([ 'rdbSpecs.fmsSoftwareVersion' ]);
        this.getField(fieldKey).set(value);
        break;
      default:
        this.getField(fieldKey).set(value);
    }
  }

  @action
  protected onCancel(): void {
    const viewMode = this.props.params?.mode.toUpperCase() || VIEW_MODE.DETAILS;
    if (!Utilities.isEqual(viewMode, VIEW_MODE.DETAILS)) {
      this.navigateToAirframe();
      return;
    }
    this.setViewMode(VIEW_MODE.DETAILS);
    this.form.reset();
    this.setFormValues(this.airframe);
  }

  private onAction(action: GRID_ACTIONS) {
    switch (action) {
      case GRID_ACTIONS.EDIT:
        this.setViewMode(VIEW_MODE.EDIT);
        break;
      case GRID_ACTIONS.SAVE:
        this.upsertAirframe();
        break;
      case GRID_ACTIONS.CANCEL:
        this.onCancel();
        break;
    }
  }

  private onFocus(fieldKey: string): void {
    switch (fieldKey) {
      case 'noiseChapter':
        this.observeSearch(this.settingsStore.getNoiseChapters());
        break;
      case 'airframeStatus':
        this.observeSearch(this.settingsStore.getAirframeStatus());
        break;
      case 'acarsModel':
        this.observeSearch(this.avionicsSettingsStore.getAcarsModels());
        break;
      case 'acarsSoftwareVersion':
        this.observeSearch(this.avionicsSettingsStore.getAcarsSoftwareVersions());
        break;
      case 'acarsManufacturer':
        this.observeSearch(this.avionicsSettingsStore.getAcarsManufacturers());
        break;
      case 'acarsMessageSet':
        this.observeSearch(this.avionicsSettingsStore.getAcarsMessageSets());
        break;
      case 'fmsModel':
        this.observeSearch(this.avionicsSettingsStore.getFmsModels());
        break;
      case 'fmsManufacturer':
        this.observeSearch(this.avionicsSettingsStore.getFmsManufacturers());
        break;
      case 'fmsSoftwareVersion':
        this.observeSearch(this.avionicsSettingsStore.getFmsSoftwareVersions());
        break;
      case 'sourceType':
        this.observeSearch(this.settingsStore.getSourceTypes());
        break;
      case 'militaryDesignations':
        this.observeSearch(this.settingsStore.getMilitaryDesignations());
        break;
      default:
        return;
    }
  }

  /* istanbul ignore next */
  private navigateToAirframe(): void {
    this.props.navigate && this.props.navigate('/aircraft/airframe');
  }

  /* istanbul ignore next */
  private get airframeId(): number {
    return Number(this.props.params?.id);
  }

  /* istanbul ignore next */
  private get verificationOptions(): SelectOption[] {
    return [ new SelectOption({ name: 'Yes', value: true }), new SelectOption({ name: 'No', value: false }) ];
  }

  /* istanbul ignore next */
  private get groupInputControls(): IGroupInputControls[] {
    return [
      {
        title: 'Airframe',
        inputControls: [
          {
            fieldKey: 'serialNumber',
            type: EDITOR_TYPES.TEXT_FIELD,
            isFullFlex: true,
          },
          {
            fieldKey: 'manufactureDate',
            type: EDITOR_TYPES.DATE,
          },
          {
            fieldKey: 'airworthinessRecentDate',
            type: EDITOR_TYPES.DATE,
          },
          {
            fieldKey: 'airworthinessCertificateDate',
            type: EDITOR_TYPES.DATE,
            showTooltip: true,
          },
          {
            fieldKey: 'temporaryEngineDate',
            type: EDITOR_TYPES.DATE,
          },
          {
            fieldKey: 'crewSeatCap',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'paxSeatCap',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'noiseChapter',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.noiseChapters,
          },
          {
            fieldKey: 'airframeStatus',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.airframeStatus,
          },
          {
            fieldKey: 'militaryDesignations',
            type: EDITOR_TYPES.DROPDOWN,
            multiple: true,
            options: this.settingsStore.militaryDesignations,
          },
          {
            fieldKey: 'isVerificationComplete',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.verificationOptions,
          },
        ],
      },
      {
        title: 'Aircraft Variation',
        inputControls: [],
      },
      {
        title: 'ACARS',
        inputControls: [
          {
            fieldKey: 'rdbSpecs.acarsMsgDescription',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'rdbSpecs.acarsManufacturer',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.avionicsSettingsStore.acarsManufacturers,
          },
          {
            fieldKey: 'rdbSpecs.acarsModel',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.acarsModelOptions,
            isDisabled: !Boolean(this.getField('rdbSpecs.acarsManufacturer').value?.label),
          },
          {
            fieldKey: 'rdbSpecs.acarsSoftwareVersion',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.acarsSoftwareVersionOptions,
            isDisabled: !Boolean(this.getField('rdbSpecs.acarsModel').value?.label),
          },
          {
            fieldKey: 'rdbSpecs.acarsMessageSet',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.acarsMessageSetOptions,
            isDisabled: !Boolean(this.getField('rdbSpecs.acarsSoftwareVersion').value?.label),
          },
        ],
      },
      {
        title: 'FMS',
        inputControls: [
          {
            fieldKey: 'rdbSpecs.fmsManufacturer',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.avionicsSettingsStore.fmsManufacturers,
          },
          {
            fieldKey: 'rdbSpecs.fmsModel',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.fmsModelOptions,
            isDisabled: !Boolean(this.getField('rdbSpecs.fmsManufacturer').value?.label),
          },
          {
            fieldKey: 'rdbSpecs.fmsSoftwareVersion',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.fmsSoftwareVersionOptions,
            isDisabled: !Boolean(this.getField('rdbSpecs.fmsManufacturer').value?.label),
          },
        ],
      },
    ];
  }

  private get systemInputControls(): IViewInputControl[] {
    return [
      {
        fieldKey: 'sourceType',
        type: EDITOR_TYPES.DROPDOWN,
        options: this.settingsStore.sourceTypes,
      },
      {
        fieldKey: 'status',
        type: EDITOR_TYPES.DROPDOWN,
        options: ModelStatusOptions,
      },
    ];
  }

  private get headerActions(): ReactNode {
    return (
      <>
        {!this.isEditable && <CustomLinkButton to="/aircraft/airframe" title="Airframe" startIcon={<ArrowBack />} />}
        <EditSaveButtons
          disabled={this.hasError || UIStore.pageLoading}
          hasEditPermission={AircraftModuleSecurity.isEditable}
          isEditMode={this.isEditable}
          onAction={action => this.onAction(action)}
        />
      </>
    );
  }

  private get systemDataFields(): ReactNode {
    const { classes } = this.props as Required<Props>;
    return (
      <Collapsable title="System">
        <>
          <div className={classes.flexWrap}>
            {this.systemInputControls
              .filter(inputControl => !inputControl.isHidden)
              .map((inputControl: IViewInputControl, index: number) => (
                <ViewInputControl
                  {...inputControl}
                  key={index}
                  field={this.getField(inputControl.fieldKey || '')}
                  isEditable={this.isEditable}
                  onValueChange={(option, _) => this.onValueChange(option, inputControl.fieldKey || '')}
                  onFocus={(fieldKey: string) => this.onFocus(fieldKey)}
                />
              ))}
          </div>
          <AuditFields
            isEditable={this.isEditable}
            fieldControls={this.auditFields}
            onGetField={(fieldKey: string) => this.getField(fieldKey)}
            isNew={this.isAddNew}
          />
        </>
      </Collapsable>
    );
  }

  /* istanbul ignore next */
  private get aircraftVariationDialogContent(): ReactNode {
    const { aircraftVariation } = this.form.values();
    return (
      <AircraftVariationWithoutRoute
        key="aircraftVariationDetail"
        settingsStore={this.props.settingsStore}
        aircraftVariationStore={this.props.aircraftVariationStore}
        performanceStore={this.props.performanceStore}
        params={{ id: aircraftVariation?.id, mode: VIEW_MODE.DETAILS }}
        isModal={true}
      />
    );
  }

  /* istanbul ignore next */
  private showAircraftVariationDetail(): void {
    const { classes } = this.props as Required<Props>;
    ModalStore.open(
      <Dialog
        key="Dialog"
        title={'Aircraft Variation'}
        open={true}
        classes={{
          paperSize: classes.paperSize,
        }}
        onClose={() => ModalStore.close()}
        dialogContent={() => this.aircraftVariationDialogContent}
      />
    );
  }

  /* istanbul ignore next */
  private refreshAircraftVariation(): void {
    const { aircraftVariation } = this.form.values();
    if (aircraftVariation?.id) {
      UIStore.setPageLoader(true);
      const request: IAPIGridRequest = {
        filterCollection: JSON.stringify([
          { propertyName: 'AircraftVariationId', propertyValue: aircraftVariation?.id },
        ]),
      };
      this.props.aircraftVariationStore
        .getAircraftVariationById(request)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe(aircraftVariation => {
          this.getField('aircraftVariation').set(aircraftVariation);
        });
    }
  }

  /* istanbul ignore next */
  private get aircraftVariation(): ReactNode {
    const { aircraftVariation } = this.form.values();
    const { classes } = this.props as Required<Props>;
    return (
      <div key="aircraftVariation">
        <CollapsibleWithButton
          key="collapsableWithButton"
          titleVariant="h5"
          title="Aircraft Variation*"
          buttonText="Select Aircraft Variation"
          titleChildren={
            aircraftVariation?.id ? (
              <IconButton
                classes={{ root: classes.infoIcon }}
                onClick={event => {
                  event.stopPropagation();
                  this.showAircraftVariationDetail();
                }}
              >
                <InfoOutlinedIcon />
              </IconButton>
            ) : (
              <></>
            )
          }
          onButtonClick={() => {
            ModalStore.open(
              <VariationSearchDialog
                key="variationSearchDialog"
                aircraftVariationStore={this.props.aircraftVariationStore}
                onSelect={value => {
                  this.onValueChange(value, 'aircraftVariation');
                  ModalStore.close();
                }}
              />
            );
          }}
          isButtonDisabled={!this.isEditable || !AircraftModuleSecurity.isEditable}
        >
          <SelectVariationView
            key="selectVariationView"
            aircraftVariation={this.getField('aircraftVariation').value || new AircraftVariationModel()}
            isEditable={this.isEditable}
          />
        </CollapsibleWithButton>
      </div>
    );
  }

  public render(): ReactNode {
    const { classes } = this.props as Required<Props>;
    return (
      <DetailsEditorWrapper headerActions={this.headerActions} isEditMode={this.isEditable}>
        <div className={classes.container}>
          {this.groupInputControls.map(({ title, inputControls }) => {
            if (Utilities.isEqual('Aircraft Variation', title)) {
              return this.aircraftVariation;
            }
            return (
              <AircraftCollapsable
                isWithButton={title === 'Airframe'}
                classes={classes}
                key={title}
                title={title}
                onButtonClick={() => this.refreshAircraftVariation()}
                customIconButton={<Cached color="primary" />}
              >
                <div className={classes.flexWrap}>
                  {inputControls
                    .filter(inputControl => !inputControl.isHidden)
                    .map((inputControl: IViewInputControl, index: number) => {
                      if (inputControl.type === EDITOR_TYPES.CHIP_INPUT) {
                        return (
                          <ChipViewInputControl
                            key={index}
                            field={this.getField(inputControl.fieldKey || '')}
                            onChipAddOrRemove={option => this.getField(inputControl.fieldKey || '').set(option)}
                            isEditable={this.isEditable}
                            isLeftIndent={inputControl.isIndent}
                            customErrorMessage={inputControl.customErrorMessage}
                          />
                        );
                      }
                      return (
                        <ViewInputControl
                          {...inputControl}
                          key={index}
                          field={this.getField(inputControl.fieldKey || '')}
                          isEditable={this.isEditable}
                          classes={{
                            flexRow: classNames({
                              [classes.inputControl]: true,
                              [classes.fullFlex]: inputControl.isFullFlex,
                            }),
                            textInput: classNames({
                              [classes.textInput]: inputControl.isFullFlex,
                            }),
                          }}
                          onValueChange={(option, fieldKey) => this.onValueChange(option, inputControl.fieldKey || '')}
                          onFocus={(fieldKey: string) => this.onFocus(fieldKey)}
                        />
                      );
                    })}
                </div>
              </AircraftCollapsable>
            );
          })}
          {this.systemDataFields}
        </div>
      </DetailsEditorWrapper>
    );
  }
}

export default withRouter(withStyles(styles)(AirframeEditor));
export { AirframeEditor as PureAirframeEditor };
