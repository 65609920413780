import { createStyles, makeStyles } from '@material-ui/core';

export const styles = createStyles(() => ({
  root: {
    flex: 1,
  },
}));

export const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
  },
}));
