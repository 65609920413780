import { BaseUpsertComponent, VIEW_MODE } from '@wings/shared';
import { fields } from './Fields';
import { NavigateFunction } from 'react-router';
import { AircraftRegistryModel, AircraftRegistryStore, AirframeStore, SettingsStore } from '../../../Shared';
import { IClasses } from '@wings-shared/core';
import { EDITOR_TYPES, IGroupInputControls } from '@wings-shared/form-controls';

export interface BaseProps {
  classes?: IClasses;
  aircraftRegistryStore?: AircraftRegistryStore;
  airframeStore?: AirframeStore;
  settingsStore?: SettingsStore;
  viewMode?: VIEW_MODE;
  params?: { mode: VIEW_MODE; id: number };
  navigate?: NavigateFunction;
}

class AircraftRegistryEditorBase<Props extends BaseProps> extends BaseUpsertComponent<
  BaseProps,
  AircraftRegistryModel
> {
  constructor(p: Props) {
    super(p, fields);
  }

  protected get aircraftRegistryStore(): AircraftRegistryStore {
    return this.props.aircraftRegistryStore as AircraftRegistryStore;
  }

  protected get settingsStore(): SettingsStore {
    return this.props.settingsStore as SettingsStore;
  }

  protected get airframeStore(): AirframeStore {
    return this.props.airframeStore as AirframeStore;
  }

  /* istanbul ignore next */
  protected get groupInputControls(): IGroupInputControls[] {
    return [
      {
        title: 'General',
        inputControls: [
          {
            fieldKey: 'registry',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'registryStartDate',
            type: EDITOR_TYPES.DATE,
          },
          {
            fieldKey: 'isDummyRegistry',
            type: EDITOR_TYPES.CHECKBOX,
          },
          {
            fieldKey: 'wakeTurbulenceGroup',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.wakeTurbulenceGroups,
          },
          {
            fieldKey: 'registryEndDate',
            type: EDITOR_TYPES.DATE,
          },
          {
            fieldKey: 'acas',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.acases,
          },
          {
            fieldKey: 'airframe',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.airframeStore.airframes,
          },
          {
            fieldKey: 'isOceanicClearanceEnabled',
            type: EDITOR_TYPES.CHECKBOX,
          },
          {
            fieldKey: 'isPDCRegistered',
            type: EDITOR_TYPES.CHECKBOX,
          },
        ],
      },
      {
        title: 'US Custom Decals',
        inputControls: [
          {
            fieldKey: 'usCustomsDecal.number',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'usCustomsDecal.expirationDate',
            type: EDITOR_TYPES.DATE,
          },
        ],
      },
      {
        title: 'Weights',
        inputControls: [
          {
            fieldKey: 'weights.bow',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'weights.maxLandingWeight',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'weights.tankCapacity',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'weights.zeroFuelWeight',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'weights.mtow',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
        ],
      },
      {
        title: 'Distance',
        inputControls: [
          {
            fieldKey: 'distance.minimumRunwayLength',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
        ],
      },
      {
        title: 'Item 10a-Navigation,Communication,Approach, Aids & Capabilities  ',
        inputControls: [
          {
            fieldKey: 'item10A',
            type: EDITOR_TYPES.CUSTOM_COMPONENT,
            options: [],
          },
        ],
      },
      {
        title: 'Item 10b- Survillience Equipment & Capabilities',
        inputControls: [
          {
            fieldKey: 'transponderType',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.transponders,
            isFullFlex: true,
          },
          {
            fieldKey: 'adsb',
            type: EDITOR_TYPES.DROPDOWN,
            options: [],
          },
          {
            fieldKey: 'uat',
            type: EDITOR_TYPES.DROPDOWN,
            options: [],
          },
          {
            fieldKey: 'udlModel4',
            type: EDITOR_TYPES.DROPDOWN,
            options: [],
          },
          {
            fieldKey: 'item10B',
            type: EDITOR_TYPES.CUSTOM_COMPONENT,
            options: this.settingsStore.radios,
          },
        ],
      },
      {
        title: 'Item 18-Other information',
        inputControls: [
          {
            fieldKey: 'item18.aircraftAddressCode',
            type: EDITOR_TYPES.CUSTOM_COMPONENT,
            options: [], // need to add deserializeList for RegistrySequenceBaseModel,
          },
        ],
      },
      {
        title: 'Item 19-Other information',
        inputControls: [
          {
            fieldKey: 'item19',
            type: EDITOR_TYPES.CUSTOM_COMPONENT,
            options: this.settingsStore.radios, // need to add deserializeList for RegistrySequenceBaseModel,
          },
        ],
      },
      {
        title: 'word Limitation',
        inputControls: [
          {
            fieldKey: 'wordLimitation.maximumCrosswind',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'wordLimitation.maximumTailwind',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
        ],
      },
      {
        title: 'Audit details',
        inputControls: [ ...(this.isDetailView ? this.auditFields : []) ],
      },
    ];
  }
}

export default AircraftRegistryEditorBase;
