import React, { ReactNode, Ref, RefObject } from 'react';
import { withStyles, Paper } from '@material-ui/core';
import { VIEW_MODE, BaseUpsertComponent } from '@wings/shared';
import {
  AuditFields,
  EDITOR_TYPES,
  ViewInputControl,
  IViewInputControl,
  IGroupInputControls,
} from '@wings-shared/form-controls';
import { IClasses, IOptionValue, ISelectOption, Utilities } from '@wings-shared/core';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import { EtpScenarioStore, EtpSettingsStore, SettingsStore } from '../../../Shared/Stores';
import { fields } from './Fields';
import { styles } from './EtpScenarioEditor.style';
import { EtpScenarioDetailModel, LEVEL_OFF, MAIN_DESCENT } from '../../../Shared';
import classNames from 'classnames';
import EtpPenaltiesGrid from '../EtpPenaltiesGrid/EtpPenaltiesGrid';
import { Collapsable } from '@wings-shared/layout';

interface Props {
  ref?: Ref<any>;
  classes?: IClasses;
  etpScenarioDetailModel: EtpScenarioDetailModel;
  etpSettingsStore: EtpSettingsStore;
  etpScenarioStore: EtpScenarioStore;
  settingsStore: SettingsStore;
  viewMode: VIEW_MODE;
  onChange: (model: EtpScenarioDetailModel) => void;
}

@observer
class EtpScenarioEditor extends BaseUpsertComponent<Props, EtpScenarioDetailModel> {
  private etpScenarioPenaltyGridRef: RefObject<EtpPenaltiesGrid> = React.createRef<EtpPenaltiesGrid>();
  constructor(p: Props) {
    super(p, fields);
  }

  componentDidMount() {
    const { etpScenarioDetailModel } = this.props;
    this.setFormValues(etpScenarioDetailModel);
    this.setFormValidationRules(etpScenarioDetailModel);
  }

  private setFormValidationRules(etpScenarioDetail: EtpScenarioDetailModel): void {
    if (this.etpScenarioDetailModel?.id) {
      const { etpInitialDescent, etpHold, etpApuBurn, cruiseEtpProfile, etpFinalDescentBurn } = etpScenarioDetail;
      this.setCruiseEtpProfileRules(
        cruiseEtpProfile.maxFlightLevelIncrement?.toString(),
        'cruiseEtpProfile.maxFlightLevelIncrement'
      );
      this.setCruiseEtpProfileRules(
        cruiseEtpProfile.additionalMaxFlightLevel1?.toString(),
        'cruiseEtpProfile.additionalMaxFlightLevel1'
      );
      this.setCruiseEtpProfileRules(
        cruiseEtpProfile.additionalMaxFlightLevel2?.toString(),
        'cruiseEtpProfile.additionalMaxFlightLevel2'
      );
      this.setFinalDescentRules(
        etpFinalDescentBurn.etpFinalDescentBurnMethod as IOptionValue,
        'etpFinalDescentBurn.etpFinalDescentBurnMethod'
      );
      this.setEtpHoldRules(etpHold.etpHoldMethod as IOptionValue, 'etpHold.etpHoldMethod');
      this.setEtpApuBurnRules(etpApuBurn.etpApuBurnMethod, 'etpApuBurn.etpApuBurnMethod');
      this.setInitialDescentRules(etpInitialDescent.etpMainDescent, 'etpInitialDescent.etpMainDescent');
      this.setInitialDescentRules(etpInitialDescent.etpLevelOff, 'etpInitialDescent.etpLevelOff');
      this.setInitialDescentRules(etpInitialDescent.etpAltDescent, 'etpInitialDescent.etpAltDescent');
    }
  }

  public setInitialFormValues(etpScenarioDetailModel: EtpScenarioDetailModel): void {
    this.setFormValues(etpScenarioDetailModel);
    this.setFormValidationRules(etpScenarioDetailModel);
    this.etpScenarioPenaltyGridRef.current?.resetData(etpScenarioDetailModel.etpPenalties);
  }

  protected get isEditable(): boolean {
    return this.props.viewMode === VIEW_MODE.EDIT || this.props.viewMode === VIEW_MODE.NEW;
  }

  private get etpScenarioDetailModel(): EtpScenarioDetailModel {
    return this.props.etpScenarioDetailModel;
  }

  private get etpSettingsStore(): EtpSettingsStore {
    return this.props.etpSettingsStore;
  }

  private get etpScenarioStore(): EtpScenarioStore {
    return this.props.etpScenarioStore;
  }

  private get settingsStore(): SettingsStore {
    return this.props.settingsStore;
  }

  // needs to access using ref
  public get hasError(): boolean {
    if (this.form.hasError || this.etpScenarioPenaltyGridRef.current?.hasPenaltyError) {
      return true;
    }
    return false;
  }

  /* istanbul ignore next */
  private get groupInputControls(): IGroupInputControls[] {
    return [
      {
        title: 'General',
        inputControls: [
          {
            fieldKey: 'etpScenarioEngine',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.etpSettingsStore.ETPScenarioEngines,
            isHalfFlex: true,
          },
          {
            fieldKey: 'etpScenarioType',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.etpSettingsStore.etpScenarioTypes,
            isHalfFlex: true,
          },
          {
            fieldKey: 'etpTimeLimitType',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.etpSettingsStore.etpTimeLimitTypes,
            isHalfFlex: true,
          },
          {
            fieldKey: 'weightUom',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore.weightUOMs,
            isHalfFlex: true,
          },
          {
            fieldKey: 'etpScenarioNumber',
            type: EDITOR_TYPES.TEXT_FIELD,
            isExists: this.isEtpScenarioNumberExists,
          },
          {
            fieldKey: 'nfpScenarioNumber',
            type: EDITOR_TYPES.TEXT_FIELD,
            isExists: this.isNfpScenarioNumberExists,
          },
          {
            fieldKey: 'description',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
        ],
      },
      {
        title: 'Initial Descent',
        inputControls: [
          {
            fieldKey: 'etpInitialDescent.etpMainDescent',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.etpSettingsStore.ETPMainDescents,
            isHalfFlex: true,
          },
          {
            fieldKey: 'etpInitialDescent.etpAltDescent',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.etpSettingsStore.etpAltDescentProfiles,
            isHalfFlex: true,
            isDisabled: !this.isDriftDown,
          },
          {
            fieldKey: 'etpInitialDescent.normalProfile',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: this.isInstantaneous || this.isFixed,
            isHalfFlex: true,
          },
          {
            fieldKey: 'etpInitialDescent.icingProfile',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: this.isInstantaneous || this.isFixed,
            isHalfFlex: true,
          },
          {
            fieldKey: 'etpInitialDescent.fixedTime',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: !this.isInitialFixedReq,
          },
          {
            fieldKey: 'etpInitialDescent.fixedBurn',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: !this.isInitialFixedReq,
          },
          {
            fieldKey: 'etpInitialDescent.fixedDistance',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: !this.isInitialFixedReq,
          },
          {
            fieldKey: 'etpInitialDescent.etpLevelOff',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.etpLevelOptions,
            isHalfFlex: true,
            isDisabled: this.isLevelOff,
          },
          {
            fieldKey: 'etpInitialDescent.flightLevel',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: !this.isLevelOffFixed,
            isHalfFlex: true,
          },
        ],
      },
      {
        title: 'ETP Cruise Profile',
        inputControls: [
          {
            fieldKey: 'cruiseEtpProfile.etpCruise',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.etpSettingsStore.ETPCruiseProfiles,
            isFullFlex: true,
          },
          {
            fieldKey: 'cruiseEtpProfile.maxFlightLevel',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'cruiseEtpProfile.maxFlightLevelIncrement',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'cruiseEtpProfile.maxFlightLevelIncrementLimit',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'cruiseEtpProfile.speed',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'cruiseEtpProfile.additionalMaxFlightLevel1',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
          {
            fieldKey: 'cruiseEtpProfile.additionalTime1',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: !this.additionalMaxFlightLevel1,
            isHalfFlex: true,
          },
          {
            fieldKey: 'cruiseEtpProfile.additionalMaxFlightLevel2',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
            isDisabled: !this.isAdditionalMFL2Disabled,
          },
          {
            fieldKey: 'cruiseEtpProfile.additionalTime2',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: !this.additionalMaxFlightLevel2,
            isHalfFlex: true,
          },
        ],
      },
      {
        title: 'Final Descent Burn',
        inputControls: [
          {
            fieldKey: 'etpFinalDescentBurn.etpFinalDescentBurnMethod',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.etpSettingsStore.ETPFinalDescents,
            isFullFlex: true,
          },
          {
            fieldKey: 'etpFinalDescentBurn.time',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: !this.isFinalDescentFixed,
          },
          {
            fieldKey: 'etpFinalDescentBurn.burn',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: !this.isFinalDescentFixed,
          },
          {
            fieldKey: 'etpFinalDescentBurn.distance',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: !this.isFinalDescentFixed,
          },
        ],
      },
      {
        title: 'Hold',
        inputControls: [
          {
            fieldKey: 'etpHold.etpHoldMethod',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.etpSettingsStore.ETPHoldMethods,
            isFullFlex: true,
          },
          {
            fieldKey: 'etpHold.flightLevel',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'etpHold.time',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'etpHold.burn',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: !this.isEtpHoldFixed,
          },
        ],
      },
      {
        title: 'APU Burn',
        inputControls: [
          {
            fieldKey: 'etpApuBurn.etpApuBurnMethod',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.etpSettingsStore.ETPAPUBurnMethods,
            isHalfFlex: true,
          },
          {
            fieldKey: 'etpApuBurn.time',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: !this.isApuBurnFixed,
          },
          {
            fieldKey: 'etpApuBurn.burn',
            type: EDITOR_TYPES.TEXT_FIELD,
            isDisabled: !this.isApuBurnFixed,
          },
        ],
      },
      {
        title: 'Missed Approach',
        inputControls: [
          {
            fieldKey: 'etpMissedApproach.time',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'etpMissedApproach.burn',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
          {
            fieldKey: 'etpMissedApproach.distance',
            type: EDITOR_TYPES.TEXT_FIELD,
          },
        ],
      },
      {
        title: 'Other',
        inputControls: [
          {
            fieldKey: 'extRangeEntryPointRadius',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
          {
            fieldKey: 'comments',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
        ],
      },
    ];
  }

  @action
  protected onValueChange(value: IOptionValue, fieldKey: string): void {
    const etpEntity = fieldKey.split('.')[0];
    switch (etpEntity) {
      case 'etpInitialDescent':
        this.setInitialDescentRules(value, fieldKey);
        break;
      case 'cruiseEtpProfile':
        this.setCruiseEtpProfileRules(value, fieldKey);
        break;
      case 'etpFinalDescentBurn':
        this.setFinalDescentRules(value, fieldKey);
        break;
      case 'etpHold':
        this.setEtpHoldRules(value, fieldKey);
        break;
      case 'etpApuBurn':
        this.setEtpApuBurnRules(value, fieldKey);
        break;
    }
    this.updateFormValues(value, fieldKey);
  }

  @action
  private setEtpApuBurnRules(value: IOptionValue, fieldKey: string): void {
    const field = fieldKey.split('.')[1];
    if (field === 'etpApuBurnMethod') {
      const option = value as ISelectOption;
      switch (option?.label) {
        case 'Fixed':
          this.setRules('etpApuBurn.burn', true, 'Burn');
          break;
        default:
          this.setRules('etpApuBurn.burn', false, 'Burn');
          this.clearFields([ 'etpApuBurn.time', 'etpApuBurn.burn' ]);
      }
    }
  }

  @action
  private setEtpHoldRules(value: IOptionValue, fieldKey: string): void {
    const field = fieldKey.split('.')[1];
    if (Utilities.isEqual(field, 'etpHoldMethod')) {
      const option = value as ISelectOption;
      const isRequired = Utilities.isEqual(option?.label, 'Fixed');
      this.setRules('etpHold.burn', isRequired, 'Burn');
      this.setRules('etpHold.time', !isRequired, 'Time');
      if (!isRequired) {
        this.clearFields([ 'etpHold.burn' ]);
      }
    }
  }

  @action
  private setFinalDescentRules(value: IOptionValue, fieldKey: string) {
    const field = fieldKey.split('.')[1];
    if (field === 'etpFinalDescentBurnMethod') {
      const option = value as ISelectOption;
      const isRequired = option?.label === 'Fixed';
      this.setRules('etpFinalDescentBurn.time', isRequired, 'Time');
      this.setRules('etpFinalDescentBurn.burn', isRequired, 'Burn');
      this.setRules('etpFinalDescentBurn.distance', isRequired, 'Distance');
      if (!isRequired) {
        this.clearFields([ 'etpFinalDescentBurn.time', 'etpFinalDescentBurn.burn', 'etpFinalDescentBurn.distance' ]);
      }
    }
  }

  @action
  private setCruiseEtpProfileRules(value: IOptionValue, fieldKey: string): void {
    const field = fieldKey.split('.')[1];
    switch (field) {
      case 'maxFlightLevelIncrement':
        this.setRules(
          'cruiseEtpProfile.maxFlightLevelIncrementLimit',
          Boolean(value),
          'Max Flight Level Increment Limit'
        );
        break;
      case 'additionalMaxFlightLevel1':
        this.setRules('cruiseEtpProfile.additionalTime1', Boolean(value), 'Additional 1 Max Flight Time');
        if (!Boolean(value)) {
          this.setRules('cruiseEtpProfile.additionalTime2', false, 'Additional 2 Max Flight Time');
          this.clearFields([
            'cruiseEtpProfile.additionalTime1',
            'cruiseEtpProfile.additionalMaxFlightLevel2',
            'cruiseEtpProfile.additionalTime2',
          ]);
        }
        break;
      case 'additionalTime1':
        if (!Boolean(value)) {
          this.setRules('cruiseEtpProfile.additionalTime2', false, 'Additional 2 Max Flight Time');
          this.clearFields([ 'cruiseEtpProfile.additionalMaxFlightLevel2', 'cruiseEtpProfile.additionalTime2' ]);
        }
        break;
      case 'additionalMaxFlightLevel2':
        this.setRules('cruiseEtpProfile.additionalTime2', Boolean(value), 'Additional 2 Max Flight Time');
        if (!Boolean(value)) {
          this.clearFields([ 'cruiseEtpProfile.additionalTime2' ]);
        }
        break;
    }
  }

  @action
  private setInitialDescentRules(value: IOptionValue, fieldKey: string): void {
    this.getField(fieldKey).set(value);
    const field = fieldKey.split('.')[1];
    if (field === 'etpMainDescent') {
      const option = value as ISelectOption;
      switch (option?.label) {
        case MAIN_DESCENT.DRIFT_DOWN:
          this.setInitialDescentFixedRules();
          this.setRules('etpInitialDescent.etpAltDescent', true, 'Alt Descent');
          this.setRules('etpInitialDescent.etpLevelOff', true, 'Level Off');
          this.setRules('etpInitialDescent.flightLevel', false, 'Flight Level');
          if (this.isLevelOffFixed)
            this.clearFields([
              'etpInitialDescent.etpLevelOff',
              'etpInitialDescent.flightLevel',
              'etpInitialDescent.etpAltDescent',
            ]);
          else {
            this.clearFields([ 'etpInitialDescent.flightLevel', 'etpInitialDescent.etpAltDescent' ]);
          }
          break;
        case MAIN_DESCENT.INSTANTANEOUS:
          this.setRules('etpInitialDescent.etpAltDescent', false, 'Alt Descent');
          this.setRules('etpInitialDescent.etpLevelOff', false, 'Level Off');
          this.clearFields([
            'etpInitialDescent.normalProfile',
            'etpInitialDescent.icingProfile',
            'etpInitialDescent.etpAltDescent',
          ]);
          this.setInitialDescentFixedRules();
          if (this.isLevelOffDriftDown) {
            this.clearFields([ 'etpInitialDescent.etpLevelOff' ]);
          }
          break;
        case MAIN_DESCENT.FIXED:
          this.setInitialDescentFixedRules();
          this.setRules('etpInitialDescent.etpAltDescent', false, 'Alt Descent');
          this.setRules('etpInitialDescent.etpLevelOff', false, 'Level Off');
          this.clearFields([
            'etpInitialDescent.normalProfile',
            'etpInitialDescent.icingProfile',
            'etpInitialDescent.etpAltDescent',
          ]);
          if (this.isLevelOffDriftDown) {
            this.clearFields([ 'etpInitialDescent.etpLevelOff' ]);
          }
          break;
        case MAIN_DESCENT.LEVEL_OFF:
          this.setRules('etpInitialDescent.etpAltDescent', false, 'Alt Descent');
          this.setRules('etpInitialDescent.flightLevel', true, 'Flight Level');
          this.clearFields([ 'etpInitialDescent.etpAltDescent' ]);
          const levelOff = this.getField('etpInitialDescent.etpLevelOff');
          const levelOffFixed = this.etpSettingsStore.ETPLevels.find(x => x.name === LEVEL_OFF.FIXED);
          levelOff.set(levelOffFixed);
          this.setInitialDescentFixedRules();
          break;
      }
    }
    if (field === 'etpLevelOff') {
      const option = value as ISelectOption;
      switch (option?.label) {
        case LEVEL_OFF.FIXED:
          this.setRules('etpInitialDescent.flightLevel', true, 'Flight Level');
          break;
        default:
          this.setRules('etpInitialDescent.flightLevel', false, 'Flight Level');
          this.clearFields([ 'etpInitialDescent.flightLevel' ]);
      }
    }

    if (field === 'etpAltDescent') {
      const option = value as ISelectOption;
      if (option?.label) {
        this.setInitialDescentFixedRules();
      }
    }
  }

  private setInitialDescentFixedRules(): void {
    const required = this.isInitialFixedReq;

    this.setRules('etpInitialDescent.fixedTime', required, 'Fixed Time');
    this.setRules('etpInitialDescent.fixedBurn', required, 'Fixed Burn');
    this.setRules('etpInitialDescent.fixedDistance', required, 'Fixed Distance');
    if (!required) {
      this.clearFields([
        'etpInitialDescent.fixedTime',
        'etpInitialDescent.fixedBurn',
        'etpInitialDescent.fixedDistance',
      ]);
    }
  }

  private get isApuBurnFixed(): boolean {
    return this.getField('etpApuBurn.etpApuBurnMethod').value?.label === 'Fixed';
  }

  private get additionalMaxFlightLevel1(): boolean {
    return this.getField('cruiseEtpProfile.additionalMaxFlightLevel1').value;
  }

  private get isAdditionalMFL2Disabled(): boolean {
    return (
      this.getField('cruiseEtpProfile.additionalMaxFlightLevel1').value &&
      this.getField('cruiseEtpProfile.additionalTime1').value
    );
  }

  private get additionalMaxFlightLevel2(): boolean {
    return this.getField('cruiseEtpProfile.additionalMaxFlightLevel2').value;
  }

  private get isDriftDown(): boolean {
    return this.getField('etpInitialDescent.etpMainDescent').value?.label === MAIN_DESCENT.DRIFT_DOWN;
  }

  private get isLevelOff(): boolean {
    return this.getField('etpInitialDescent.etpMainDescent').value?.label === MAIN_DESCENT.LEVEL_OFF;
  }

  private get isLevelOffDriftDown(): boolean {
    const levelOffValue = this.getField('etpInitialDescent.etpLevelOff').value?.label;
    return Utilities.isEqual(levelOffValue, 'Drift Down');
  }

  private get isLevelOffFixed(): boolean {
    return this.getField('etpInitialDescent.etpLevelOff').value?.label === LEVEL_OFF.FIXED;
  }

  private get isInitialFixedReq(): boolean {
    return (
      this.getField('etpInitialDescent.etpAltDescent').value?.label === 'Fixed' ||
      this.getField('etpInitialDescent.etpMainDescent').value?.label === MAIN_DESCENT.FIXED
    );
  }

  private get isInstantaneous(): boolean {
    return this.getField('etpInitialDescent.etpMainDescent').value?.label === MAIN_DESCENT.INSTANTANEOUS;
  }

  private get isFixed(): boolean {
    return this.getField('etpInitialDescent.etpMainDescent').value?.label === MAIN_DESCENT.FIXED;
  }

  private get isFinalDescentFixed(): boolean {
    return this.getField('etpFinalDescentBurn.etpFinalDescentBurnMethod').value?.label === 'Fixed';
  }

  private get isEtpHoldFixed(): boolean {
    return this.getField('etpHold.etpHoldMethod').value?.label === 'Fixed';
  }

  private get isEtpScenarioNumberExists(): boolean {
    const etpScenarioScenarioNumber = this.getField('etpScenarioNumber').value;
    return this.etpScenarioStore.etpScenarios?.some(
      x => x.etpScenarioNumber === Number(etpScenarioScenarioNumber) && x.id != this.etpScenarioDetailModel?.id
    );
  }

  private get isNfpScenarioNumberExists(): boolean {
    const _nfpScenarioNumber = this.getField('nfpScenarioNumber').value;
    return this.etpScenarioStore.etpScenarios?.some(
      ({ nfpScenarioNumber, id }) =>
        Utilities.isEqual(nfpScenarioNumber, Number(_nfpScenarioNumber)) &&
        !Utilities.isEqual(id, this.etpScenarioDetailModel?.id)
    );
  }

  private get etpLevelOptions(): ISelectOption[] {
    const { ETPLevels } = this.etpSettingsStore;
    if (this.isDriftDown) {
      return ETPLevels.filter(x => x.name !== LEVEL_OFF.FIXED);
    }
    if (this.isInstantaneous || this.isFixed) {
      return ETPLevels.filter(x => x.name !== LEVEL_OFF.DRIFT_DOWN);
    }
    return ETPLevels;
  }

  private setRules(fieldKey: string, required: boolean, label: string): void {
    const field = this.getField(fieldKey);
    const rules: string[] = field.rules?.split('|').filter(x => x) || [];
    const newRules = required
      ? rules.includes('required')
        ? rules
        : [ 'required', ...rules ]
      : rules.filter(x => x !== 'required');
    field.set('rules', newRules.join('|'));
    field.set('label', `${label}${required ? '*' : ''}`);
  }

  private clearFields(fieldKeys: string[]): void {
    fieldKeys.forEach(fieldKey => this.getField(fieldKey).set(''));
  }

  // update from Values
  @action
  private updateFormValues(value: IOptionValue, fieldKey: string): EtpScenarioDetailModel {
    this.getField(fieldKey).set(value);
    const model = this.getUpdatedModel();
    this.props.onChange(model);
    return model;
  }

  //   Get updated airport hours Model
  private getUpdatedModel(): EtpScenarioDetailModel {
    const formValues: EtpScenarioDetailModel = this.form.values();
    const updatedModel = new EtpScenarioDetailModel({
      ...this.etpScenarioDetailModel,
      ...formValues,
      etpPenalties: this.etpScenarioDetailModel?.etpPenalties,
    });
    return updatedModel;
  }

  private get etpPenalties(): ReactNode {
    return (
      <>
        {this.etpScenarioDetailModel?.etpPenalties && (
          <EtpPenaltiesGrid
            ref={this.etpScenarioPenaltyGridRef}
            isEditable={this.isEditable}
            etpSettingsStore={this.etpSettingsStore}
            rowData={this.etpScenarioDetailModel?.etpPenalties}
            onDataUpdate={updatedData => {
              this.etpScenarioDetailModel.etpPenalties = updatedData;
              const model = this.getUpdatedModel();
              this.props.onChange(model);
            }}
          />
        )}
      </>
    );
  }

  public render(): ReactNode {
    const { classes } = this.props as Required<Props>;
    return (
      <Paper className={classes.root}>
        <div className={classes.flexContainer}>
          <div className={classes.flexRow}>
            {this.groupInputControls.map(({ title, inputControls }, index) => (
              <Collapsable key={index} title={title}>
                <div className={classes.flexWrap}>
                  {inputControls
                    .filter(inputControl => !inputControl.isHidden)
                    .map((inputControl: IViewInputControl, index: number) => (
                      <ViewInputControl
                        {...inputControl}
                        key={index}
                        isExists={inputControl.isExists}
                        classes={{
                          flexRow: classNames({
                            [classes.halfFlex]: inputControl.isHalfFlex,
                            [classes.inputControl]: !inputControl.isHalfFlex,
                            [classes.fullFlex]: inputControl.isFullFlex,
                          }),
                          autoCompleteRoot: classNames({
                            [classes.labelRoot]: inputControl.isFullFlex,
                          }),
                        }}
                        customErrorMessage={inputControl.customErrorMessage}
                        field={this.getField(inputControl.fieldKey || '')}
                        isEditable={this.isEditable}
                        onValueChange={(option, fieldKey) => this.onValueChange(option, inputControl.fieldKey || '')}
                      />
                    ))}
                </div>
              </Collapsable>
            ))}
            <Collapsable title="ETP Penalties">
              <div className={classes.etpPenaltiesGrid}>{this.etpPenalties}</div>
            </Collapsable>
            <AuditFields
              isEditable={this.isEditable}
              fieldControls={this.auditFields}
              onGetField={(fieldKey: string) => this.getField(fieldKey)}
              isNew={this.isAddNew}
            />
          </div>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(EtpScenarioEditor);
export { EtpScenarioEditor as PureEtpScenarioEditor };
