import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Routes, Route } from 'react-router-dom';
import { IBaseModuleProps } from '@wings/shared';
import {
  AircraftRegistryV2,
  sidebarMenu,
  Settings,
  AirframeV2,
  AirframeEditorV2,
  AircraftRegistryEditorV2,
  AircraftVariationV2,
  AircraftVariationEditorV2,
  EtpPolicyV2,
  EtpPolicyEditorV2,
  EtpScenarioV2,
  AddEtpScenarioV2,
  FlightPlanV2,
  FlightPlanEditorV2,
  FlightPlanFormatChangeRecordV2,
  FlightPlanningServiceV2,
  GenericRegistryV2,
  PerformanceV2,
  PerformanceEditorV2,
} from './Modules/index';
import { AircraftModuleSecurity } from './Modules/Shared';
import { ProtectedRoute } from '@wings-shared/layout';
import { NotFoundPage, SearchStore } from '@wings-shared/core';
import { useModeStore } from '@wings-shared/mode-store';

const AircraftApp = (props: IBaseModuleProps) => {
  const redirectPath: string = '/aircraft';
  const modeStore = useModeStore();

  /* istanbul ignore next */
  useEffect(() => {
    return () => {
      SearchStore.clearSearch();
    };
  }, []);

  return (
    <Routes>
      <Route path="aircraft/*">
        <Route index element={<FlightPlanV2 />} />
        <Route path="etp-scenario" element={<EtpScenarioV2 />} />
        <Route
          path="etp-scenario/new"
          element={
            <ProtectedRoute
              element={<AddEtpScenarioV2 />}
              hasPermission={AircraftModuleSecurity.isEditable}
              redirectPath={redirectPath}
            />
          }
        />
        <Route path="settings" element={<Settings />} />
        <Route
          path="flight-plan/:mode"
          element={
            <ProtectedRoute
              element={<FlightPlanEditorV2 key={'flight-plan-add'} />}
              hasPermission={AircraftModuleSecurity.isEditable}
              redirectPath={redirectPath}
            />
          }
        />
        <Route path="flight-plan/:id/:mode" element={<FlightPlanEditorV2 key={'flight-plan-edit'} />} />
        <Route
          path="change-records"
          element={<FlightPlanFormatChangeRecordV2 key={'flight-plan--format-change-record'} />}
        />
        <Route path="performance" element={<PerformanceV2 />} />
        <Route
          path="performance/:mode"
          element={
            <ProtectedRoute
              element={<PerformanceEditorV2 key={'performance-add'} />}
              hasPermission={AircraftModuleSecurity.isEditable}
              redirectPath={redirectPath}
            />
          }
        />
        <Route path="performance/:id/:mode" element={<PerformanceEditorV2 key={'performance-edit'} />} />
        <Route path="etp-policy" element={<EtpPolicyV2 key={'etp-policy'} />} />
        <Route
          path="etp-policy/:mode"
          element={
            <ProtectedRoute
              element={<EtpPolicyEditorV2 key={'etp-policy-add'} />}
              hasPermission={AircraftModuleSecurity.isEditable}
              redirectPath={redirectPath}
            />
          }
        />
        <Route path="etp-policy/:id/:mode" element={<EtpPolicyEditorV2 key={'etp-policy-edit'} />} />
        <Route path="aircraft-variation" element={<AircraftVariationV2 key="aircraftVariation" />} />
        <Route
          path="aircraft-variation/:mode"
          element={
            <ProtectedRoute
              element={<AircraftVariationEditorV2 key={'aircraft-variation-add'} />}
              hasPermission={AircraftModuleSecurity.isEditable}
              redirectPath={redirectPath}
            />
          }
        />
        <Route
          path="aircraft-variation/:id/:mode"
          element={<AircraftVariationEditorV2 key={'aircraft-variation-edit'} />}
        />
        <Route path="airframe" element={<AirframeV2 />} />
        <Route
          path="airframe/:mode"
          element={
            <ProtectedRoute
              element={<AirframeEditorV2 key={'airframe-add'} />}
              hasPermission={AircraftModuleSecurity.isEditable}
              redirectPath={redirectPath}
            />
          }
        />
        <Route path="airframe/:id/:mode" element={<AirframeEditorV2 key={'airframe-edit'} />} />
        <Route
          path="aircraft-registry"
          element={
            <ProtectedRoute
              element={<AircraftRegistryV2 />}
              hasPermission={modeStore.isDevModeEnabled}
              redirectPath={redirectPath}
            />
          }
        />
        <Route
          path="aircraft-registry/:mode"
          element={
            <ProtectedRoute
              element={<AircraftRegistryEditorV2 key={'aircraft-add'} />}
              hasPermission={AircraftModuleSecurity.isEditable}
              redirectPath={redirectPath}
            />
          }
        />
        <Route
          path="aircraft-registry/:id/:mode"
          element={
            <ProtectedRoute
              element={<AircraftRegistryEditorV2 key={'aircraft-edit'} />}
              hasPermission={AircraftModuleSecurity.isEditable}
              redirectPath={redirectPath}
            />
          }
        />
        <Route path="generic-registry" element={<GenericRegistryV2 />} />
        <Route path="flight-planning-service" element={<FlightPlanningServiceV2 />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default inject('sidebarStore')(observer(AircraftApp));
