import {
  AirplaneIcon, 
  ClockIcon,
  SettingIcon,
  AirframeIcon
} from '@uvgo-shared/icons';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ShowChartOutlinedIcon from '@material-ui/icons/ShowChartOutlined';
import TrackChangesOutlined from '@material-ui/icons/TrackChangesOutlined';
import { Utilities } from '@wings-shared/core';
import { INavigationLink } from '@wings-shared/layout';
import React from 'react';

export const sidebarMenu: INavigationLink[] = [
  { to: '', title: 'Flight Plan Format', icon: <AirplaneIcon /> },
  { to: 'change-records', title: 'Change Record', icon: <ChangeHistoryIcon /> },
  { to: 'etp-scenario', title: 'ETP Scenario', icon: <ClockIcon /> },
  { to: 'etp-policy', title: 'ETP Policy', icon: <AvTimerIcon /> },
  { to: 'performance', title: 'Performance', icon: <ShowChartOutlinedIcon /> },
  {
    to: 'aircraft-variation',
    title: 'Aircraft Variation',
    icon: <TrackChangesOutlined />,
  },
  {
    to: 'airframe',
    title: 'Airframe',
    icon: <AirframeIcon />,
  },
  {
    to: 'aircraft-registry',
    title: 'Aircraft Registry',
    isHidden: true,
    icon: <TrackChangesOutlined />,
  },
  {
    to: 'generic-registry',
    title: 'Generic Registry',
    icon: <ListAltIcon />,
  },
  {
    to: 'flight-planning-service',
    title: 'Flight Planning Services',
    icon: <ListAltIcon />,
  },
  {
    to: 'settings',
    title: 'Settings',
    icon: <SettingIcon />,
  },
];

export const updateAircraftSidebarOptions = (tabQuery: string): INavigationLink[] =>
  Utilities.updateSidebarOptions(sidebarMenu, tabQuery);
