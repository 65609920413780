import React, { ReactNode } from 'react';
import {
  regex,
  withRouter,
  Utilities,
  UIStore,
  IAPIGridRequest,
  ViewPermission,
  SettingsTypeModel,
  IBaseGridFilterSetup,
  GRID_ACTIONS,
  cellStyle,
} from '@wings-shared/core';
import { ColDef, GridOptions, ValueFormatterParams, ICellEditorParams } from 'ag-grid-community';
import {
  GenericRegistryModel,
  GenericRegistryStore,
  GENERIC_REGISTRY_FILTER,
  SettingsStore,
  AircraftModuleSecurity,
  updateAircraftSidebarOptions,
} from '../Shared';
import { withTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { inject, observer } from 'mobx-react';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { action } from 'mobx';
import { forkJoin, of } from 'rxjs';
import { AxiosError } from 'axios';
import { ALERT_TYPES } from '@uvgo-shared/alert';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { ConfirmDialog, SidebarStore } from '@wings-shared/layout';
import { SearchHeader } from '@wings-shared/form-controls';
import {
  AgGridCellEditor,
  CustomAgGridReact,
  BaseGrid,
  IActionMenuItem,
  AgGridCheckBox,
} from '@wings-shared/custom-ag-grid';

interface Props {
  settingsStore: SettingsStore;
  genericRegistryStore: GenericRegistryStore;
  sidebarStore?: typeof SidebarStore;
}

const filtersSetup: IBaseGridFilterSetup<GENERIC_REGISTRY_FILTER> = {
  defaultPlaceHolder: 'Search by Name',
  filterTypesOptions: Object.values(GENERIC_REGISTRY_FILTER),
  defaultFilterType: GENERIC_REGISTRY_FILTER.GENERIC_REGISTRY,
};

@inject('genericRegistryStore', 'settingsStore', 'sidebarStore')
@observer
class GenericRegistry extends BaseGrid<Props, GenericRegistryModel, GENERIC_REGISTRY_FILTER> {
  constructor(props) {
    super(props, filtersSetup);
  }

  componentDidMount() {
    this.props.sidebarStore?.setNavLinks(updateAircraftSidebarOptions('Generic Registry'), 'aircraft');
    this.loadInitialData();
  }

  private get genericRegistryStore(): GenericRegistryStore {
    return this.props.genericRegistryStore;
  }

  private get settingsStore(): SettingsStore {
    return this.props.settingsStore;
  }

  /* istanbul ignore next */
  private loadInitialData() {
    UIStore.setPageLoader(true);
    forkJoin([ this.genericRegistryStore.getGenericRegistries(true), this.settingsStore.getWeightUOMs() ])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(([ genericRegistries ]) => (this.data = genericRegistries.results));
  }

  /* istanbul ignore next */
  private get actionMenus(): IActionMenuItem[] {
    return [
      {
        title: 'Edit',
        isHidden: !AircraftModuleSecurity.isEditable,
        action: GRID_ACTIONS.EDIT,
      },
      {
        title: 'Refresh',
        isHidden: !AircraftModuleSecurity.isEditable,
        action: GRID_ACTIONS.EVENT,
      },
    ];
  }

  @action
  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    if (rowIndex === null) {
      return;
    }
    switch (gridAction) {
      case GRID_ACTIONS.EDIT:
        this._startEditingCell(rowIndex, this.columnDefs[0].field || '');
        break;
      case GRID_ACTIONS.SAVE:
        this.upsertGenericRegistry(rowIndex);
        break;
      case GRID_ACTIONS.EVENT:
        this.confirmRecordRefresh(rowIndex);
        break;
      case GRID_ACTIONS.CANCEL:
      default:
        this._cancelEditing(rowIndex);
        break;
    }
  }

  /* istanbul ignore next */
  private confirmRecordRefresh(rowIndex: number): void {
    ModalStore.open(
      <ConfirmDialog
        title="Confirm Refresh"
        message="Are you sure you want to refresh this record?"
        yesButton="Refresh"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => this.refreshRecord(rowIndex)}
      />
    );
  }

  /* istanbul ignore next */
  private refreshRecord(rowIndex: number): void {
    ModalStore.close();
    const { genericRegistryStore } = this.props;
    const model = this._getTableItem(rowIndex);
    UIStore.setPageLoader(true);
    genericRegistryStore
      .refreshGenericRegistry(model)
      .pipe(
        switchMap(isSucces => {
          if (isSucces) {
            const request: IAPIGridRequest = {
              filterCollection: JSON.stringify([ Utilities.getFilter('NavBlueGenericRegistryId', model.id) ]),
            };
            return genericRegistryStore.getGenericRegistryById(request);
          }
          return of(new GenericRegistryModel(model));
        }),
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(response => {
        this._updateTableItem(rowIndex, response);
      });
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Generic Registry',
      field: 'name',
      cellEditorParams: {
        rules: `required|string|regex:${regex.alphaNumeric}|between:0,7`,
        ignoreNumber: true,
      },
    },
    {
      headerName: 'Weight UOM',
      field: 'weightUOM',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      filter: false,
      editable: false,
      valueFormatter: ({ value }: ValueFormatterParams) => value?.name,
    },
    {
      headerName: 'Ramp Weight',
      field: 'rampWeight',
      editable: false,
    },
    {
      headerName: 'MTOW',
      field: 'mtow',
      editable: false,
    },
    {
      headerName: 'Zero Fuel Weight',
      field: 'zeroFuelWeight',
      editable: false,
    },
    {
      headerName: 'Max Landing Weight',
      field: 'maxLandingWeight',
      editable: false,
    },
    {
      headerName: 'Tank Capacity',
      field: 'tankCapacity',
      editable: false,
    },
    {
      headerName: 'BOW',
      field: 'bow',
      editable: false,
    },
    {
      headerName: 'Performance',
      field: 'performance',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      filter: false,
      editable: false,
      valueFormatter: ({ value }: ValueFormatterParams) => value?.name,
    },
    {
      headerName: 'Restrict External Use',
      field: 'restrictExternalUse',
      cellEditor: 'checkBoxRenderer',
      cellRenderer: 'checkBoxRenderer',
      cellRendererParams: { readOnly: true },
      filter: false,
    },
    {
      columnGroupShow: 'open',
      ...this.auditFields[0],
    },
    {
      headerName: '',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      sortable: false,
      filter: false,
      suppressSizeToFit: true,
      hide: !AircraftModuleSecurity.isEditable,
      minWidth: 150,
      maxWidth: 210,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        isActionMenu: true,
        actionMenus: () => this.actionMenus,
      },
    },
  ];

  // Called from Ag Grid Component
  @action
  public onInputChange(params: ICellEditorParams, value: string): void {
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
  }

  // Called from Ag Grid Component
  @action
  public onDropDownChange(params: ICellEditorParams, value: string): void {
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
  }

  /* istanbul ignore next */
  private get gridActionProps(): object {
    return {
      tooltip: 'Generic registry',
      showDeleteButton: false,
      getDisabledState: () => this.hasError,
      onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
    };
  }

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: true,
      gridActionProps: this.gridActionProps,
    });

    return {
      ...baseOptions,
      suppressClickEdit: true,
      doesExternalFilterPass: node => {
        const { name, id, weightUOM, rampWeight, tankCapacity } = node.data as GenericRegistryModel;
        return (
          !id ||
          this._isFilterPass({
            [GENERIC_REGISTRY_FILTER.GENERIC_REGISTRY]: name,
            [GENERIC_REGISTRY_FILTER.WEIGHT_UOM]: weightUOM.name,
            [GENERIC_REGISTRY_FILTER.RAMP_WEIGHT]: rampWeight?.toString(),
            [GENERIC_REGISTRY_FILTER.TANK_CAPACITY]: tankCapacity?.toString(),
          })
        );
      },
      groupHeaderHeight: 0,
      suppressColumnVirtualisation: true,
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
      },
      frameworkComponents: {
        ...baseOptions.frameworkComponents,
        customCellEditor: AgGridCellEditor,
        checkBoxRenderer: AgGridCheckBox,
      },
    };
  }

  private addNewRegistry() {
    this._addNewItems([ new GenericRegistryModel({ id: 0 }) ], {
      startEditing: true,
      colKey: 'name',
    });
    this.hasError = true;
  }

  /* istanbul ignore next */
  private upsertGenericRegistry(rowIndex: number): void {
    this.gridApi.stopEditing();
    const model = this._getTableItem(rowIndex);
    UIStore.setPageLoader(true);
    this.genericRegistryStore
      .upsertGenericRegistry(model)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: GenericRegistryModel) => this._updateTableItem(rowIndex, response),
        error: (error: AxiosError) => this.showAlert(error.message, 'GenericRegistry', ALERT_TYPES.CRITICAL),
      });
  }

  private get rightContent(): ReactNode {
    return (
      <ViewPermission hasPermission={false}>
        <PrimaryButton
          variant="contained"
          startIcon={<AddIcon />}
          disabled={this.isProcessing}
          onClick={() => this.addNewRegistry()}
        >
          Add Generic Registry
        </PrimaryButton>
      </ViewPermission>
    );
  }

  public render(): ReactNode {
    return (
      <>
        <SearchHeader
          searchPlaceHolder={this.searchPlaceHolder}
          searchTypeValue={this.selectedOption}
          searchTypeOptions={this._selectOptions}
          onSearchTypeChange={selectedOption => this._setSelectedOption(selectedOption as GENERIC_REGISTRY_FILTER)}
          onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
          rightContent={this.rightContent}
          expandCollapse={() => this.autoSizeColumns()}
        />
        <CustomAgGridReact
          isRowEditing={this.isRowEditing}
          rowData={this.data}
          gridOptions={this.gridOptions}
          disablePagination={this.isRowEditing}
        />
      </>
    );
  }
}

export default withRouter(withTheme(GenericRegistry));
export { GenericRegistry as PureGenericRegistry };
