import React, { ReactNode } from 'react';
import { BaseUpsertComponent, VIEW_MODE } from '@wings/shared';
import { ViewInputControl } from '@wings-shared/form-controls';
import { observer } from 'mobx-react';
import { Dialog } from '@uvgo-shared/dialog';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { withStyles } from '@material-ui/core';
import { fields } from './Fields';
import { styles } from './AircraftModelUpsert.styles';
import { PrimaryButton, SecondaryButton } from '@uvgo-shared/buttons';
import { AircraftModel, AircraftModelMakeModel, SettingsStore } from '../../../../../index';
import { AircraftModelMakeMasterDetail } from '../index';
import { observable } from 'mobx';
import { AxiosError } from 'axios';
import { takeUntil, finalize } from 'rxjs/operators';
import { AlertStore } from '@uvgo-shared/alert';
import { IClasses, UIStore, Utilities } from '@wings-shared/core';

interface Props {
  viewMode?: VIEW_MODE;
  classes?: IClasses;
  settingsStore?: SettingsStore;
  model?: AircraftModel;
  onUpsert?: (response: AircraftModel) => void;
}

@observer
class AircraftModelUpsert extends BaseUpsertComponent<Props, AircraftModel> {
  @observable private isRowEditing: boolean = false;
  constructor(props: Props) {
    super(props, fields);
  }

  /* istanbul ignore next */
  componentDidMount() {
    const { model } = this.props as Required<Props>;
    this.setFormValues(model);
  }

  /* istanbul ignore next */
  private upsertAircraftModel(): void {
    const model = new AircraftModel({ ...this.props.model, ...this.form.values() });
    UIStore.setPageLoader(true);
    this.props.settingsStore
      ?.upsertAircraftModel(model)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: AircraftModel) => {
          this.props.onUpsert && this.props.onUpsert(response);
          ModalStore.close();
        },
        error: (error: AxiosError) => {
          AlertStore.critical(error.message);
        },
      });
  }

  private get isExist(): boolean {
    const { name } = this.form.values();
    const { settingsStore, model } = this.props as Required<Props>;
    return settingsStore.aircraftModels.some(x => Utilities.isEqual(x.name, name) && x.id !== model.id);
  }

  private get hasError(): boolean {
    return this.form.hasError || this.isRowEditing || this.isExist;
  }

  private get modelMakes(): AircraftModelMakeModel[] {
    return this.getField('modelMakes').value;
  }

  private get modelMakesGrid(): ReactNode {
    return (
      <AircraftModelMakeMasterDetail
        settingsStore={this.props.settingsStore}
        modelMakes={this.modelMakes}
        onDataSave={(modelMakes: AircraftModelMakeModel[]) => this.getField('modelMakes').set(modelMakes)}
        onRowEditing={isRowEditing => {
          this.isRowEditing = isRowEditing;
        }}
      />
    );
  }

  /* istanbul ignore next */
  private get dialogContent(): ReactNode {
    const { classes } = this.props as Required<Props>;
    return (
      <div className={classes.modalDetail}>
        <ViewInputControl
          key="name"
          field={this.getField('name')}
          isEditable={this.isEditable}
          isExists={this.isExist}
          onValueChange={(option, _) => this.getField('name').set(option)}
        />
        {this.modelMakesGrid}
      </div>
    );
  }

  private get dialogActions(): ReactNode {
    return (
      <>
        <PrimaryButton disabled={this.isRowEditing} variant="contained" onClick={() => ModalStore.close()}>
          Cancel
        </PrimaryButton>
        <SecondaryButton variant="contained" disabled={this.hasError} onClick={() => this.upsertAircraftModel()}>
          Save
        </SecondaryButton>
      </>
    );
  }

  render(): ReactNode {
    const { classes, viewMode } = this.props as Required<Props>;
    return (
      <Dialog
        title={`${viewMode === VIEW_MODE.NEW ? 'Add' : 'Edit'} Aircraft Model`}
        open={true}
        classes={{
          paperSize: classes.paperSize,
        }}
        onClose={() => ModalStore.close()}
        dialogContent={() => this.dialogContent}
        dialogActions={() => this.dialogActions}
      />
    );
  }
}

export default withStyles(styles)(AircraftModelUpsert);
