import React, { ReactNode } from 'react';
import { IClasses, Utilities, ENTITY_STATE, GRID_ACTIONS, cellStyle } from '@wings-shared/core';
import {
  ColDef,
  GridOptions,
  ICellEditorParams,
  RowEditingStartedEvent,
  GridReadyEvent,
  RowNode,
} from 'ag-grid-community';
import { inject, observer } from 'mobx-react';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { AircraftModuleSecurity, FlightPlanFormatDocumentModel, FlightPlanStore } from '../../../Shared';
import { withStyles, withTheme } from '@material-ui/core';
import { styles } from './FlightPlanDocumentGrid.styles';
import { ChildGridWrapper, CollapsibleWithButton, ConfirmDialog } from '@wings-shared/layout';
import {
  AgGridCellEditor,
  AgGridViewRenderer,
  CustomAgGridReact,
  BaseGrid,
  AgGridActions,
} from '@wings-shared/custom-ag-grid';
interface Props {
  classes?: IClasses;
  isEditable?: boolean;
  flightPlanFormatDocuments: FlightPlanFormatDocumentModel[];
  flightPlanStore?: FlightPlanStore;
  onDataSave: (response: FlightPlanFormatDocumentModel[]) => void;
  onRowEditing: (isEditing: boolean) => void;
}

@inject('flightPlanStore')
@observer
class FlightPlanDocumentGrid extends BaseGrid<Props, FlightPlanFormatDocumentModel> {
  // Called from Ag Grid Component
  public onInputChange({ colDef }: ICellEditorParams, value: string): void {
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
  }

  componentDidUpdate(prevProps: Props) {
    const { isEditable } = this.props as Required<Props>
    if (this.props.isEditable !== prevProps.isEditable) {
      this._setColumnVisible('actionRenderer', isEditable);
    }
  }

  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    const { onRowEditing } = this.props;
    if (rowIndex === null) {
      return;
    }
    switch (gridAction) {
      case GRID_ACTIONS.EDIT:
        this._startEditingCell(rowIndex, this.columnDefs[0].field || '');
        break;
      case GRID_ACTIONS.SAVE:
        this._setColumnVisible('link', false);
        this.upsertFlightPlanFormatDocument(rowIndex);
        onRowEditing(false);
        break;
      case GRID_ACTIONS.CANCEL:
        this._setColumnVisible('link', false);
        this.canceEditing(rowIndex);
        onRowEditing(false);
        break;
      case GRID_ACTIONS.DELETE:
        this.confirmRemoveDocument(rowIndex);
        break;
      default:
        this.gridApi.stopEditing(true);
        onRowEditing(false);
        break;
    }
  }

  private addNewDocument(): void {
    const document = new FlightPlanFormatDocumentModel({ id: 0 });
    this._addNewItems([ document ], { startEditing: true, colKey: 'name' });
  }

  private confirmRemoveDocument(rowIndex: number): void {
    const model: FlightPlanFormatDocumentModel = this._getTableItem(rowIndex);
    if (model.id === 0) {
      this.deleteFlightPlanFormatDocument(model);
      return;
    }

    ModalStore.open(
      <ConfirmDialog
        title="Confirm Delete"
        message="Are you sure you want to remove this Document?"
        yesButton="Delete"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => this.deleteFlightPlanFormatDocument(model)}
      />
    );
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Name',
      field: 'name',
      cellRenderer: 'viewRenderer',
      cellRendererParams: {
        getViewRenderer: (rowIndex: number, node: RowNode, classes: IClasses) => (
          <a className={this.props.classes?.link} href={node.data?.link} target="_blank">
            {node.data?.name}
          </a>
        ),
      },
      cellEditorParams: {
        rules: 'required|string|between:1,100',
        ignoreNumber: true,
      },
    },
    {
      headerName: 'Link',
      field: 'link',
      cellEditorParams: {
        rules: 'required|string|between:1,255',
        ignoreNumber: true,
      },
    },
    {
      headerName: '',
      field: 'actionRenderer',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      maxWidth: 130,
      minWidth: 130,
      sortable: false,
      filter: false,
      suppressSizeToFit: true,
      suppressNavigable: true,
      cellStyle: { ...cellStyle() },
    },
  ];

  /* istanbul ignore next */
  private get gridActionProps(): object {
    return {
      showDeleteButton: true,
      getDisabledState: () => this.hasError,
      getEditableState: () => this.props.isEditable,
      onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
    };
  }

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: AircraftModuleSecurity.isEditable,
      gridActionProps: this.gridActionProps,
    });

    return {
      ...baseOptions,
      suppressClickEdit: true,
      onCellDoubleClicked: ({ rowIndex, colDef }) => {
        const { isEditable } = this.props;
        if (!isEditable) {
          return;
        }
        this._startEditingCell(Number(rowIndex), colDef.field || '');
      },
      onGridReady: (param: GridReadyEvent) => {
        this._onGridReady(param);
        this._setColumnVisible('actionRenderer', Boolean(this.props.isEditable));
        this._setColumnVisible('link', false);
      },
      onRowEditingStarted: (event: RowEditingStartedEvent) => {
        if (this.isProcessing) {
          this.gridApi.stopEditing();
          return;
        }
        this.hasError = true;
        this._startEditingRow(event);
        this.props.onRowEditing(true);
        this._setColumnVisible('link', true);
      },
      groupHeaderHeight: 0,
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
        filter: true,
      },

      frameworkComponents: {
        actionRenderer: AgGridActions,
        customCellEditor: AgGridCellEditor,
        viewRenderer: AgGridViewRenderer,
      },
    };
  }

  /* istanbul ignore next */
  private canceEditing(rowIndex: number): void {
    const data: FlightPlanFormatDocumentModel = this._getTableItem(rowIndex);
    const isNewEntry = Utilities.isEqual(data.entityState || '', ENTITY_STATE.UNCHNAGED);
    this._cancelEditing(rowIndex, isNewEntry);
  }

  /* istanbul ignore next */
  private upsertFlightPlanFormatDocument(rowIndex: number): void {
    this.gridApi.stopEditing();
    this.updateTableData();
  }

  /* istanbul ignore next */
  private deleteFlightPlanFormatDocument(model: FlightPlanFormatDocumentModel): void {
    ModalStore.close();
    this._removeTableItems([ model ]);
    this.updateTableData();
  }

  private updateTableData(): void {
    this.data = this._getAllTableRows().map(
      requirement =>
        new FlightPlanFormatDocumentModel({
          ...requirement,
          id: requirement.id || Utilities.getTempId(true),
          entityState: ENTITY_STATE.NEW,
        })
    );
    this.props.onDataSave(this.data);
  }

  public render(): ReactNode {
    const { isEditable, classes } = this.props as Required<Props>;
    return (
      <div className={classes.root}>
        <CollapsibleWithButton
          title="Document"
          buttonText="Add Document"
          isButtonDisabled={this.isProcessing || !(AircraftModuleSecurity.isEditable && isEditable)}
          onButtonClick={() => this.addNewDocument()}
        >
          <ChildGridWrapper hasAddPermission={false}>
            <CustomAgGridReact
              isRowEditing={this.isRowEditing}
              rowData={this.props.flightPlanFormatDocuments}
              gridOptions={this.gridOptions}
              disablePagination={this.isRowEditing}
            />
          </ChildGridWrapper>
        </CollapsibleWithButton>
      </div>
    );
  }
}

export default withTheme(withStyles(styles)(FlightPlanDocumentGrid));
export { FlightPlanDocumentGrid as PureFlightPlanDocumentGrid };
