import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
  createStyles({
    flexRow: {
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(3),
      flexBasis: '33.3%',
    },
    flexWrap: {
      flexWrap: 'wrap',
      display: 'flex',
    },
  });

export const useStyles = makeStyles((theme: Theme) => ({
  flexRow: {
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    flexBasis: '33.3%',
  },
  flexWrap: {
    flexWrap: 'wrap',
    display: 'flex',
  },
}));
