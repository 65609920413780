import React, { ReactNode } from 'react';
import { ColDef, GridOptions, ICellEditorParams } from 'ag-grid-community';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { Logger, SettingsModuleSecurity } from '@wings-shared/security';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import {
  CustomResponseDialog,
  PerformanceStore,
  SETTINGS_PROFILE_FILTERS,
  SpeedScheduleSettingsStore,
} from '../../../Shared';
import { AlertStore } from '@uvgo-shared/alert';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { SettingsProfileModel } from '../../../Shared/Models';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { AxiosError } from 'axios';
import { Observable, of } from 'rxjs';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { IAPIGridRequest, UIStore, Utilities, GRID_ACTIONS, IBaseGridFilterSetup, cellStyle } from '@wings-shared/core';
import { ConfirmDialog } from '@wings-shared/layout';
import { SearchHeader } from '@wings-shared/form-controls';
import { AgGridCellEditor, CustomAgGridReact, BaseGrid, AgGridActions } from '@wings-shared/custom-ag-grid';

interface Props {
  getSettings?: () => Observable<SettingsProfileModel[]>;
  upsertSettings?: (object: SettingsProfileModel) => Observable<SettingsProfileModel>;
  settingsData?: SettingsProfileModel[];
  performanceStore?: PerformanceStore;
  speedScheduleSettingsStore?: SpeedScheduleSettingsStore;
  type?: string;
  typeKey: string;
}

const filtersSetup: IBaseGridFilterSetup<SETTINGS_PROFILE_FILTERS> = {
  defaultPlaceHolder: 'Search by Description',
  filterTypesOptions: Object.values(SETTINGS_PROFILE_FILTERS),
  defaultFilterType: SETTINGS_PROFILE_FILTERS.DESCRIPTION,
};

@inject('performanceStore', 'speedScheduleSettingsStore')
@observer
class UpsertSettingsProfile extends BaseGrid<Props, SettingsProfileModel, SETTINGS_PROFILE_FILTERS> {
  private readonly alertMessageId: string = 'UpsertProfileAlertMessage';

  constructor(props) {
    super(props, filtersSetup);
  }

  componentDidMount() {
    this.loadInitialData();
  }

  /* istanbul ignore next */
  private loadInitialData() {
    UIStore.setPageLoader(true);
    this.props.getSettings &&
      this.props
        .getSettings()
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe(response => (this.data = response));
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Schedule',
      field: 'profile',
      cellEditorParams: {
        rules: 'required|string|between:1,15',
      },
    },
    {
      headerName: 'Description',
      field: 'description',
      cellEditorParams: {
        rules: 'string|between:1,300',
      },
    },
    {
      headerName: '',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      sortable: false,
      filter: false,
      hide: !SettingsModuleSecurity.isEditable,
      suppressSizeToFit: true,
      minWidth: 150,
      maxWidth: 210,
      cellStyle: { ...cellStyle() },
    },
  ];

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: SettingsModuleSecurity.isEditable,
      gridActionProps: {
        getDisabledState: () => this.hasError,
        onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
      },
    });
    return {
      ...baseOptions,
      doesExternalFilterPass: node => {
        const { id, profile, description } = node.data as SettingsProfileModel;
        return (
          !id ||
          this._isFilterPass({
            [SETTINGS_PROFILE_FILTERS.PROFILE]: profile,
            [SETTINGS_PROFILE_FILTERS.DESCRIPTION]: description,
          })
        );
      },
      frameworkComponents: {
        actionRenderer: AgGridActions,
        customCellEditor: AgGridCellEditor,
      },
    };
  }

  private addNewType() {
    this._addNewItems(
      [
        new SettingsProfileModel({
          id: 0,
        }),
      ],
      {
        startEditing: true,
        colKey: 'profile',
      }
    );
    this.hasError = true;
  }

  // Called from Ag Grid Component
  @action
  public onInputChange(params: ICellEditorParams, value: string): void {
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
  }

  private isAlreadyExists(id: number): boolean {
    if (this._isAlreadyExists([ 'profile' ], id)) {
      this.showAlert('Profile should be unique.', this.alertMessageId);
      return true;
    }
    return false;
  }

  private showAlertInformation(): void {
    ModalStore.open(
      <CustomResponseDialog
        heading="Alert!"
        message="Record cannot be deleted as it is associated with a Performance record."
      />
    );
  }

  private getFilterPropertyName(): string {
    const { typeKey } = this.props;
    switch (typeKey) {
      case 'climbSchedule':
        return 'ClimbSchedules.ClimbScheduleId';
      case 'holdSchedule':
        return 'HoldSchedules.HoldScheduleId';
      default:
        return 'DescentSchedules.DescentScheduleId';
    }
  }

  /* istanbul ignore next */
  private getPerformanceByScheduleId(model: SettingsProfileModel): void {
    const { performanceStore, speedScheduleSettingsStore, typeKey, type } = this.props;
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      filterCollection: JSON.stringify([ Utilities.getFilter(this.getFilterPropertyName(), model.id) ]),
    };
    performanceStore
      ?.getPerformanceById(request)
      .pipe(
        switchMap(response => {
          if (response?.id) {
            this.showAlertInformation();
            return of('');
          }
          return speedScheduleSettingsStore?.deleteSchedule(model.id, typeKey, type) || of('');
        }),
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: response => {
          if (response) {
            this._removeTableItems([ model ]);
            this.data = this.data.filter(({ id }) => model.id !== id);
          }
        },
        error: (error: AxiosError) => AlertStore.critical(error.message),
      });
  }

  private confirmDeleteAction(rowIndex: number): void {
    const model: SettingsProfileModel = this._getTableItem(rowIndex);
    ModalStore.open(
      <ConfirmDialog
        title="Confirm Delete"
        message="Are you sure you want to remove this record?"
        yesButton="Delete"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => {
          ModalStore.close();
          this.getPerformanceByScheduleId(model);
        }}
      />
    );
  }

  @action
  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    if (rowIndex === null) {
      return;
    }
    switch (gridAction) {
      case GRID_ACTIONS.EDIT:
        this._startEditingCell(rowIndex, this.columnDefs[0].field || '');
        break;
      case GRID_ACTIONS.SAVE:
        this.upsertSettingsProfile(rowIndex);
        break;
      case GRID_ACTIONS.DELETE:
        this.confirmDeleteAction(rowIndex);
        break;
      case GRID_ACTIONS.CANCEL:
      default:
        this._cancelEditing(rowIndex);
        break;
    }
  }

  private get rightContent(): ReactNode {
    const { type } = this.props;
    if (!SettingsModuleSecurity.isEditable) {
      return null;
    }
    return (
      <PrimaryButton
        variant="contained"
        startIcon={<AddIcon />}
        disabled={this.isProcessing}
        onClick={() => this.addNewType()}
      >
        Add {type}
      </PrimaryButton>
    );
  }

  /* istanbul ignore next */
  private upsertSettingsProfile(rowIndex: number): void {
    const model = this._getTableItem(rowIndex);
    if (this.isAlreadyExists(model.id)) {
      return;
    }
    this.gridApi.stopEditing();
    UIStore.setPageLoader(true);
    this.props.upsertSettings && this.props
      .upsertSettings(model)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: SettingsProfileModel) => this._updateTableItem(rowIndex, response),
        error: (error: AxiosError) => {
          AlertStore.critical(error.message);
          Logger.error(error.message);
        },
      });
  }

  public render(): ReactNode {
    return (
      <>
        <SearchHeader
          searchPlaceHolder={this.searchPlaceHolder}
          searchTypeValue={this.selectedOption}
          searchTypeOptions={this._selectOptions}
          onSearchTypeChange={selectedOption => this._setSelectedOption(selectedOption as SETTINGS_PROFILE_FILTERS)}
          onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
          rightContent={this.rightContent}
          isDisabled={this.isRowEditing}
        />
        <CustomAgGridReact
          isRowEditing={this.isRowEditing}
          rowData={this.data}
          gridOptions={this.gridOptions}
          disablePagination={this.isRowEditing}
        />
      </>
    );
  }
}

export default UpsertSettingsProfile;
