import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ChipControlStyles } from '@wings/shared';

export const styles = (theme: Theme) =>
  createStyles({
    ...ChipControlStyles(theme),
    container: {
      display: 'flex',
      flex: 1,
      width: '100%',
      overflowY: 'auto',
      flexDirection: 'column',
    },
    flexWrap: {
      flexWrap: 'wrap',
      display: 'flex',
    },
    inputControl: {
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(3),
      flexBasis: '33%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    fullFlex: {
      flexBasis: '100%',
    },
    typography: {
      fontWeight: 'bolder',
      padding: '15px 0',
    },
  });

export const useStyles = makeStyles((theme: Theme) => ({
  ...ChipControlStyles(theme),
  container: {
    display: 'flex',
    flex: 1,
    width: '100%',
    overflowY: 'auto',
    flexDirection: 'column',
  },
  flexWrap: {
    flexWrap: 'wrap',
    display: 'flex',
  },
  inputControl: {
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    flexBasis: '33%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  fullFlex: {
    flexBasis: '100%',
  },
  typography: {
    fontWeight: 'bolder',
    padding: '15px 0',
  },
}));
