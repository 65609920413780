import React, { ReactNode } from 'react';
import { ColDef, GridOptions, ICellEditorParams, ValueFormatterParams } from 'ag-grid-community';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { Logger, SettingsModuleSecurity } from '@wings-shared/security';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import { NOISE_CHAPTER_CONFIGURATION_TYPE, SettingsStore } from '../../../Shared';
import { AlertStore } from '@uvgo-shared/alert';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { NoiseChapterConfigurationModel } from '../../../Shared/Models';
import { finalize, takeUntil } from 'rxjs/operators';
import { AxiosError } from 'axios';
import { forkJoin } from 'rxjs';
import { SearchHeader } from '@wings-shared/form-controls';
import {
  UIStore,
  Utilities,
  SettingsTypeModel,
  GRID_ACTIONS,
  IBaseGridFilterSetup,
  cellStyle,
} from '@wings-shared/core';
import {
  AgGridCellEditor,
  CustomAgGridReact,
  BaseGrid,
  AgGridActions,
  AgGridAutoComplete,
} from '@wings-shared/custom-ag-grid';

interface Props {
  settingsStore?: SettingsStore;
}

const filtersSetup: IBaseGridFilterSetup<NOISE_CHAPTER_CONFIGURATION_TYPE> = {
  defaultPlaceHolder: 'Search by Noise Chapter',
  filterTypesOptions: Object.values(NOISE_CHAPTER_CONFIGURATION_TYPE),
  defaultFilterType: NOISE_CHAPTER_CONFIGURATION_TYPE.CHAPTER,
};

@inject('settingsStore')
@observer
class NoiseChapterConfiguration extends BaseGrid<
  Props,
  NoiseChapterConfigurationModel,
  NOISE_CHAPTER_CONFIGURATION_TYPE
> {
  private readonly alertMessageId = 'NoiseChapterConfiguration';

  constructor(props) {
    super(props, filtersSetup);
  }

  componentDidMount() {
    this.loadInitialData();
  }

  private get settingsStore(): SettingsStore {
    return this.props.settingsStore as SettingsStore;
  }

  /* istanbul ignore next */
  private loadInitialData() {
    UIStore.setPageLoader(true);
    forkJoin([
      this.settingsStore.getNoiseChapterConfigurations(),
      this.settingsStore.getNoiseChapters(),
      this.settingsStore.getNoiseDateTypeCertifications(),
      this.settingsStore.getAircraftNoiseTypes(),
    ])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(([ noiseChapterConfigurations ]) => (this.data = noiseChapterConfigurations));
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Noise Chapter',
      field: 'noiseChapter',
      cellEditor: 'customAutoComplete',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Noise Chapter',
        getAutoCompleteOptions: () => this.settingsStore.noiseChapters,
        valueGetter: (option: SettingsTypeModel) => option,
      },
    },
    {
      headerName: 'Noise Type',
      field: 'aircraftNoiseType',
      cellEditor: 'customAutoComplete',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Noise Type',
        getAutoCompleteOptions: () => this.settingsStore.aircraftNoiseTypes,
        valueGetter: (option: SettingsTypeModel) => option,
      },
    },
    {
      headerName: 'Noise Date Type Certification',
      field: 'noiseDateTypeCertification',
      cellEditor: 'customAutoComplete',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Noise Date Type Certification',
        getAutoCompleteOptions: () => this.settingsStore.noiseDateTypeCertifications,
        valueGetter: (option: SettingsTypeModel) => option,
      },
    },
    {
      headerName: '',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      sortable: false,
      filter: false,
      hide: !SettingsModuleSecurity.isEditable,
      suppressSizeToFit: true,
      minWidth: 150,
      maxWidth: 210,
      cellStyle: { ...cellStyle() },
    },
  ];

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: SettingsModuleSecurity.isEditable,
      gridActionProps: {
        showDeleteButton: false,
        getDisabledState: () => this.hasError,
        onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
      },
    });
    return {
      ...baseOptions,
      doesExternalFilterPass: node => {
        const {
          id,
          noiseChapter,
          noiseDateTypeCertification,
          aircraftNoiseType,
        } = node.data as NoiseChapterConfigurationModel;
        return (
          !id ||
          this._isFilterPass({
            [NOISE_CHAPTER_CONFIGURATION_TYPE.CHAPTER]: noiseChapter.name,
            [NOISE_CHAPTER_CONFIGURATION_TYPE.TYPE]: aircraftNoiseType.name,
            [NOISE_CHAPTER_CONFIGURATION_TYPE.CERTIFICATION]: noiseDateTypeCertification.name,
          })
        );
      },
      frameworkComponents: {
        actionRenderer: AgGridActions,
        customCellEditor: AgGridCellEditor,
        customAutoComplete: AgGridAutoComplete,
      },
    };
  }

  private addNewType() {
    const noiseChapterConfiguration = new NoiseChapterConfigurationModel({
      id: 0,
    });
    this._addNewItems([ noiseChapterConfiguration ], { startEditing: true, colKey: 'noiseChapter' });
    this.hasError = true;
  }

  // Called from Ag Grid Component
  @action
  public onInputChange(params: ICellEditorParams, value: string): void {
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
  }

  // Called from Ag Grid Component
  @action
  public onDropDownChange(params: ICellEditorParams, value: string): void {
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
  }

  private isAlreadyExists(id: number): boolean {
    if (this._isAlreadyExists([ 'noiseDateTypeCertification', 'aircraftNoiseType', 'noiseChapter' ], id)) {
      this.showAlert(
        'Noise Chapter, Noise Type and Noise Date Type Certification should be unique.',
        this.alertMessageId
      );
      return true;
    }
    return false;
  }

  @action
  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    if (rowIndex === null) {
      return;
    }
    switch (gridAction) {
      case GRID_ACTIONS.EDIT:
        this._startEditingCell(rowIndex, this.columnDefs[0].field || '');
        break;
      case GRID_ACTIONS.SAVE:
        this.upsertRegistryIdentifierCountry(rowIndex);
        break;
      case GRID_ACTIONS.CANCEL:
      default:
        this._cancelEditing(rowIndex);
        break;
    }
  }

  private get rightContent(): ReactNode {
    if (!SettingsModuleSecurity.isEditable) {
      return null;
    }
    return (
      <PrimaryButton
        variant="contained"
        startIcon={<AddIcon />}
        disabled={this.isProcessing}
        onClick={() => this.addNewType()}
      >
        Add Noise Chapter Configuration
      </PrimaryButton>
    );
  }

  /* istanbul ignore next */
  private upsertRegistryIdentifierCountry(rowIndex: number): void {
    const model = this._getTableItem(rowIndex);
    if (this.isAlreadyExists(model.id)) {
      return;
    }
    this.gridApi.stopEditing();
    UIStore.setPageLoader(true);
    this.settingsStore
      .upsertNoiseChapterConfiguration(model)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: NoiseChapterConfigurationModel) => this._updateTableItem(rowIndex, response),
        error: (error: AxiosError) => {
          AlertStore.critical(error.message);
          Logger.error(error.message);
        },
      });
  }

  public render(): ReactNode {
    return (
      <>
        <SearchHeader
          searchPlaceHolder={this.searchPlaceHolder}
          searchTypeValue={this.selectedOption}
          searchTypeOptions={this._selectOptions}
          onSearchTypeChange={selectedOption =>
            this._setSelectedOption(selectedOption as NOISE_CHAPTER_CONFIGURATION_TYPE)
          }
          onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
          rightContent={this.rightContent}
          isDisabled={this.isRowEditing}
        />
        <CustomAgGridReact
          isRowEditing={this.isRowEditing}
          rowData={this.data}
          gridOptions={this.gridOptions}
          disablePagination={this.isRowEditing}
        />
      </>
    );
  }
}

export default NoiseChapterConfiguration;
