import React, { ReactNode } from 'react';
import { withStyles } from '@material-ui/core';
import { VIEW_MODE } from '@wings/shared';
import { EDITOR_TYPES, ViewInputControl, IViewInputControl } from '@wings-shared/form-controls';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';

import {
  AircraftRegistryModel,
  RegistrySequenceBaseModel,
  AircraftCollapsable,
  AircraftModuleSecurity,
} from '../../../Shared';
import { ArrowBack, Cached } from '@material-ui/icons';
import { styles } from './AircraftRegistryEditor.styles';
import AircraftRegistryEditorBase, { BaseProps } from './AircraftRegistryEditorBase';
import { RegistryCheckbox } from '../index';
import { finalize, takeUntil } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { IOptionValue, UIStore, withRouter, GRID_ACTIONS } from '@wings-shared/core';
import { CustomLinkButton, EditSaveButtons, DetailsEditorWrapper } from '@wings-shared/layout';

@inject('aircraftRegistryStore', 'settingsStore', 'airframeStore')
@observer
class AircraftRegistryEditor extends AircraftRegistryEditorBase<BaseProps> {
  @observable private aircraftRegistry: AircraftRegistryModel = new AircraftRegistryModel();

  constructor(p: BaseProps) {
    super(p);
    const mode: string = this.props.params?.mode?.toUpperCase() || '';
    this.setViewMode(VIEW_MODE[mode] || VIEW_MODE.DETAILS);
  }

  public get hasError(): boolean {
    return this.form.hasError;
  }

  private get aircraftRegistryId(): number {
    return Number(this.props.params?.id);
  }

  /* istanbul ignore next */
  componentDidMount() {
    UIStore.setPageLoader(false);
    forkJoin([ this.getAircraftRegistryById(), this.settingsStore.getRadios() ])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(([ registry ]) => {
        this.aircraftRegistry = registry;
        this.setFormValues(this.aircraftRegistry);
      });
  }

  private getAircraftRegistryById(): Observable<AircraftRegistryModel> {
    if (!this.aircraftRegistryId) {
      return of(this.aircraftRegistry);
    }
    return this.aircraftRegistryStore.getAircraftRegistryById(this.aircraftRegistryId);
  }

  @action
  protected onCancel(model?: AircraftRegistryModel): void {
    this.navigateToAircraftRegistry();
  }

  private onAction(action: GRID_ACTIONS) {
    switch (action) {
      case GRID_ACTIONS.EDIT:
        this.viewMode = VIEW_MODE.EDIT;
        break;
      case GRID_ACTIONS.SAVE:
        break;
      case GRID_ACTIONS.CANCEL:
        this.onCancel();
        break;
    }
  }

  /* istanbul ignore next */
  private navigateToAircraftRegistry(): void {
    this.props.navigate && this.props.navigate('/aircraft/aircraft-registry');
  }

  private refreshAirframe(): void {
    const { airframe } = this.form.values();
    UIStore.setPageLoader(false);
    this.airframeStore
      .getAirframes()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(airframes => {
        if (airframe?.id) {
          const selectedAirframe = airframes.find(x => x.id === airframe.id);
          this.getField('airframe').set(selectedAirframe);
        }
      });
  }

  @action
  protected onValueChange(value: IOptionValue | IOptionValue[], fieldKey: string): void {
    this.getField(fieldKey).set(value);
  }

  /* istanbul ignore next */
  private onFocus(fieldKey: string): void {
    switch (fieldKey) {
      case 'acas':
        this.observeSearch(this.settingsStore.getAcases());
        break;
      case 'airframe':
        this.observeSearch(this.airframeStore.getAirframes());
        break;
      case 'transponderType':
        this.observeSearch(this.settingsStore.getTransponders());
        break;
      case 'wakeTurbulenceGroup':
        this.observeSearch(this.settingsStore.getWakeTurbulenceGroups());
        break;
    }
  }

  private get headerActions(): ReactNode {
    return (
      <>
        {!this.isEditable && (
          <CustomLinkButton to="/aircraft/aircraft-registry" title="Aircraft Registry" startIcon={<ArrowBack />} />
        )}
        <EditSaveButtons
          disabled={this.hasError || UIStore.pageLoading}
          hasEditPermission={AircraftModuleSecurity.isEditable}
          isEditMode={this.isEditable}
          onAction={action => this.onAction(action)}
        />
      </>
    );
  }

  public render(): ReactNode {
    const { classes } = this.props as Required<BaseProps>;
    return (
      <DetailsEditorWrapper headerActions={this.headerActions} isEditMode={this.isEditable}>
        <div className={classes.flexRow}>
          {this.groupInputControls.map((groupInputControl,index) => (
            <AircraftCollapsable
              isWithButton={groupInputControl.title === 'General'}
              classes={classes}
              key={groupInputControl.title}
              title={groupInputControl.title}
              onButtonClick={() => this.refreshAirframe()}
              customIconButton={<Cached color="primary" />}
            >
              <div className={classes.flexWrap}>
                {groupInputControl.inputControls
                  .filter(inputControl => !inputControl.isHidden)
                  .map((inputControl: IViewInputControl, index: number) => {
                    if (inputControl.type === EDITOR_TYPES.CUSTOM_COMPONENT) {
                      return (
                        <RegistryCheckbox
                          options={inputControl.options?.map(a => new RegistrySequenceBaseModel(a)) || []}
                          onValueChange={option => this.onValueChange(option, inputControl.fieldKey || '')}
                          values={this.getField(inputControl.fieldKey || '').values()}
                          isEditable={this.isEditable}
                        />
                      );
                    }
                    return (
                      <ViewInputControl
                        {...inputControl}
                        key={index}
                        customErrorMessage={inputControl.customErrorMessage}
                        field={this.getField(inputControl.fieldKey || '')}
                        isEditable={this.isEditable}
                        isExists={inputControl.isExists}
                        classes={{
                          flexRow: classes.inputControl,
                        }}
                        onValueChange={(option, fieldKey) => this.onValueChange(option, inputControl.fieldKey || '')}
                        onFocus={(fieldKey: string) => this.onFocus(fieldKey)}
                      />
                    );
                  })}
              </div>
            </AircraftCollapsable>
          ))}
        </div>
      </DetailsEditorWrapper>
    );
  }
}

export default withRouter(withStyles(styles)(AircraftRegistryEditor));
export { AircraftRegistryEditor as PureAircraftRegistryEditor };
