import React, { ReactNode } from 'react';
import { VIEW_MODE } from '@wings/shared';
import {
  regex,
  withRouter,
  DATE_FORMAT,
  Utilities,
  UIStore,
  IClasses,
  AccessLevelModel,
  SourceTypeModel,
  ISelectOption,
  IAPIGridRequest,
  ViewPermission,
  SearchStore,
  SettingsTypeModel,
  IBaseGridFilterSetup,
  GRID_ACTIONS,
  cellStyle,
  IGridSortFilter,
  GridPagination,
} from '@wings-shared/core';
import {
  ColDef,
  ColGroupDef,
  GridOptions,
  ValueFormatterParams,
  ValueGetterParams,
  FilterModifiedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import {
  AircraftVariationStore,
  CustomResponseDialog,
  PerformanceModel,
  PerformanceStore,
  SettingsStore,
  AircraftModuleSecurity,
  PERFORMANCE_FILTERS,
  updateAircraftSidebarOptions,
} from '../Shared';
import { withStyles } from '@material-ui/core';
import { observer, inject } from 'mobx-react';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { AxiosError } from 'axios';
import { AlertStore } from '@uvgo-shared/alert';
import { styles } from './Performance.styles';
import { of } from 'rxjs';
import { CustomLinkButton, ConfirmDialog, SidebarStore } from '@wings-shared/layout';
import { SearchHeader } from '@wings-shared/form-controls';
import {
  AgGridCellEditor,
  CustomAgGridReact,
  BaseGrid,
  AgGridActions,
  AgGridGroupHeader,
  AgGridDateTimePicker,
  AgGridStatusBadge,
} from '@wings-shared/custom-ag-grid';
import { performanceGridFilters } from './fields';

interface Props {
  settingsStore: SettingsStore;
  performanceStore: PerformanceStore;
  aircraftVariationStore: AircraftVariationStore;
  classes?: IClasses;
  sidebarStore?: typeof SidebarStore;
}

const filtersSetup: IBaseGridFilterSetup<PERFORMANCE_FILTERS> = {
  defaultPlaceHolder: 'Search by Name',
  filterTypesOptions: Object.values(PERFORMANCE_FILTERS),
  defaultFilterType: PERFORMANCE_FILTERS.NAME,
  apiFilterDictionary: performanceGridFilters,
};

@inject('performanceStore', 'settingsStore', 'aircraftVariationStore', 'sidebarStore')
@observer
class Performance extends BaseGrid<Props, PerformanceModel, PERFORMANCE_FILTERS> {
  constructor(props) {
    super(props, filtersSetup);
  }

  componentDidMount() {
    this.props.sidebarStore?.setNavLinks(updateAircraftSidebarOptions('Performance'), 'aircraft');
    this.loadInitialData();
    this.onAdvanceSearch$().subscribe(() => this.loadInitialData());
    this.setMiniFilterTextDebounce();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const { clientSearchValue } = SearchStore;
    if (clientSearchValue.searchValue) {
      return;
    }
    SearchStore.clearSearch();
  }

  /* istanbul ignore next */
  private loadInitialData(pageRequest?: IAPIGridRequest) {
    const request: IAPIGridRequest = {
      pageNumber: 1,
      pageSize: this.pagination.pageSize,
      ...pageRequest,
      ...this._searchFilters,
      ...this._sortFilters,
      ...this._gridAPIAdvancedFilterCollection,
      ...this._gridAPIAdvancedSearchCollection,
    };
    UIStore.setPageLoader(true);
    this.props.performanceStore
      .getPerformances(true, request)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(performances => {
        this.pagination = new GridPagination({ ...performances });
        this.data = performances.results;
        this.gridAdvancedSearchFilterApplied();
        const { clientSearchValue } = SearchStore;
        const { selectedOption, searchValue } = clientSearchValue;
        if (searchValue) {
          this._setSelectedOption((selectedOption as PERFORMANCE_FILTERS) || PERFORMANCE_FILTERS.NAME);
          this._setSearchValue(searchValue);
          this.searchHeaderRef.current?.setSearchValue(searchValue);
          SearchStore.clearSearch();
          return;
        }
        SearchStore.clearSearch();
      });
  }

  /* istanbul ignore next */
  private columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Name',
      field: 'name',
      minWidth: 250,
      headerTooltip: 'Name',
      filter: 'agTextColumnFilter',
      filterParams: this.nameSearchFilterParams('contains', 'name', 2),
    },
    {
      headerName: 'Max Flight Level',
      field: 'maxFlightLevel',
      headerTooltip: 'Max Flight Level',
      filter: 'agTextColumnFilter',
      filterParams: this.nameSearchFilterParams('contains', 'maxFlightLevel', 2),
    },
    {
      headerName: 'Default Cruise Schedule',
      field: 'defaultCruiseSchedule',
      headerTooltip: 'Default Cruise Schedule',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filterValueGetter: ({ data }: ValueGetterParams) => data.defaultCruiseSchedule.name,
      filter: 'agTextColumnFilter',
      filterParams: this.nameSearchFilterParams('contains', 'defaultCruiseSchedule', 2),
    },
    {
      headerName: 'Max Performance Weight(Pounds)',
      field: 'mtowInPounds',
      headerTooltip: 'Max Performance Weight(Pounds)',
    },
    {
      headerName: 'Max Performance Weight(Kilos)',
      field: 'mtowInKilos',
      filter: 'agTextColumnFilter',
      filterParams: this.nameSearchFilterParams('contains', 'mtowInKilos', 2),
      headerTooltip: 'Max Performance Weight(Kilos)',
    },
    {
      headerName: 'Performance Details',
      groupId: 'performanceDetails',
      children: [
        {
          headerName: 'Modified Date',
          groupId: 'modifiedOn',
          headerComponent: 'customHeader',
          minWidth: 150,
          field: 'modifiedOn',
          cellEditor: 'customTimeEditor',
          headerTooltip: 'Modified Date',
          valueFormatter: ({ value }: ValueFormatterParams) =>
            Utilities.getformattedDate(value, DATE_FORMAT.API_DATE_FORMAT),
        },
        {
          headerName: 'Default Descent Schedule',
          field: 'defaultDescentSchedule',
          columnGroupShow: 'open',
          headerTooltip: 'Default Descent Schedule',
          comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
            Utilities.customComparator(current, next, 'name'),
          valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
          filter: 'agTextColumnFilter',
          filterParams: this.nameSearchFilterParams('contains', 'defaultDescentSchedule', 2),
          filterValueGetter: ({ data }: ValueGetterParams) => data.defaultDescentSchedule.name,
        },
        {
          headerName: 'Default Climb Schedule',
          field: 'defaultClimbSchedule',
          columnGroupShow: 'open',
          comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
            Utilities.customComparator(current, next, 'name'),
          valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
          filter: 'agTextColumnFilter',
          filterParams: this.nameSearchFilterParams('contains', 'defaultClimbSchedule', 2),
          filterValueGetter: ({ data }: ValueGetterParams) => data.defaultClimbSchedule.name,
          headerTooltip: 'Default Climb Schedule',
        },
        {
          headerName: 'Default Hold Schedule',
          field: 'defaultHoldSchedule',
          columnGroupShow: 'open',
          headerTooltip: 'Default Hold Schedule',
          comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
            Utilities.customComparator(current, next, 'name'),
          valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
          filter: 'agTextColumnFilter',
          filterParams: this.nameSearchFilterParams('contains', 'defaultHoldSchedule', 2),
          filterValueGetter: ({ data }: ValueGetterParams) => data.defaultHoldSchedule.name,
        },
        {
          headerName: 'Created Date',
          field: 'createdOn',
          columnGroupShow: 'open',
          cellEditor: 'customTimeEditor',
          headerTooltip: 'Created Date',
          valueFormatter: ({ value }: ValueFormatterParams) =>
            Utilities.getformattedDate(value, DATE_FORMAT.API_DATE_FORMAT),
        },
        {
          headerName: 'Access Level',
          columnGroupShow: 'open',
          field: 'accessLevel',
          cellEditor: 'customAutoComplete',
          headerTooltip: 'Access Level',
          comparator: (current: AccessLevelModel, next: AccessLevelModel) =>
            Utilities.customComparator(current, next, 'name'),
          valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
          filterValueGetter: ({ data }: ValueGetterParams) => data.accessLevel.name,
          filter: 'agTextColumnFilter',
          filterParams: this.nameSearchFilterParams('contains', 'accessLevel', 2),
        },
        {
          headerName: 'Source',
          columnGroupShow: 'open',
          field: 'sourceType',
          cellEditor: 'customAutoComplete',
          headerTooltip: 'Source',
          comparator: (current: SourceTypeModel, next: SourceTypeModel) =>
            Utilities.customComparator(current, next, 'name'),
          valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
          filterValueGetter: ({ data }: ValueGetterParams) => data.sourceType.name,
          filter: 'agTextColumnFilter',
          filterParams: this.nameSearchFilterParams('contains', 'sourceType', 2),
        },
        {
          headerName: 'Status',
          field: 'status',
          columnGroupShow: 'open',
          cellEditor: 'customAutoComplete',
          cellRenderer: 'statusRenderer',
          headerTooltip: 'Status',
          comparator: (current: ISelectOption, next: ISelectOption) =>
            Utilities.customComparator(current, next, 'value'),
          valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
          filterValueGetter: ({ data }: ValueGetterParams) => data.status.name,
          filter: 'agTextColumnFilter',
          filterParams: this.nameSearchFilterParams('start', 'status', 2),
        },
      ],
    },
    {
      headerName: 'Action',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      sortable: false,
      filter: false,
      minWidth: 150,
      maxWidth: 210,
      suppressSizeToFit: true,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        isActionMenu: true,
        actionMenus: () => [
          {
            title: 'Edit',
            isHidden: !AircraftModuleSecurity.isEditable,
            action: GRID_ACTIONS.EDIT,
            to: node => `/aircraft/performance/${node?.data.id}/${VIEW_MODE.EDIT.toLowerCase()}`,
          },
          {
            title: 'Details',
            isHidden: false,
            action: GRID_ACTIONS.DETAILS,
            to: node => `/aircraft/performance/${node?.data.id}/${VIEW_MODE.DETAILS.toLowerCase()}`,
          },
          {
            title: 'Delete',
            isHidden: !AircraftModuleSecurity.isEditable,
            action: GRID_ACTIONS.DELETE,
          },
        ],
        onAction: (action: GRID_ACTIONS, rowIndex: number) => {
          if (this.searchValue) {
            const clientSearchValue = { selectedOption: this.selectedOption, searchValue: this.searchValue as string };
            SearchStore.setclientSearchValue(clientSearchValue);
          }
          this.onAction(action, rowIndex);
        },
      },
    },
  ];

  private onAction(action: GRID_ACTIONS, rowIndex: number): void {
    const model: PerformanceModel = this._getTableItem(rowIndex);
    if (Utilities.isEqual(action, GRID_ACTIONS.DELETE)) {
      this.confirmDeleteAction(model);
    }
  }

  private getAircraftVariationByPerformanceId(model: PerformanceModel): void {
    UIStore.setPageLoader(true);
    const request: IAPIGridRequest = {
      filterCollection: JSON.stringify([{ propertyName: 'Performance.PerformanceId', propertyValue: model.id }]),
    };
    this.props.aircraftVariationStore
      .getAircraftVariationById(request)
      .pipe(
        switchMap(response => {
          if (response?.id) {
            this.showAlertInformation();
            return of('');
          }
          return this.props.performanceStore.deletePerformanceRecord(model.id);
        }),
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: response => {
          if (response) {
            this._removeTableItems([ model ]);
            this.data = this.data.filter(({ id }) => model.id !== id);
          }
        },
        error: (error: AxiosError) => AlertStore.critical(error.message),
      });
  }

  private confirmDeleteAction(model: PerformanceModel): void {
    ModalStore.open(
      <ConfirmDialog
        title="Confirm Delete"
        message="Are you sure you want to remove this record?"
        yesButton="Delete"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => {
          ModalStore.close();
          this.getAircraftVariationByPerformanceId(model);
        }}
      />
    );
  }

  private showAlertInformation(): void {
    ModalStore.open(
      <CustomResponseDialog
        heading="Alert!"
        message="Performance record cannot be deleted as it is associated with an AircraftVariation."
        classes={{ modalWidth: this.props.classes?.modalWidth }}
      />
    );
  }

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: false,
      gridActionProps: {
        tooltip: 'Performance',
        getDisabledState: () => this.hasError,
      },
    });

    return {
      ...baseOptions,
      pagination: false,
      suppressCellSelection: true,
      suppressRowHoverHighlight: true,
      suppressClickEdit: true,
      isExternalFilterPresent: () => false,
      onFilterChanged: () => this.gridAdvancedSearchFilters.length === 0 && this.loadInitialData(),
      onFilterModified: (filterModified: FilterModifiedEvent) => this.onGridApiFilterModified(filterModified),
      onSortChanged: ({ api }: SortChangedEvent) => {
        this.sortFilters = api.getSortModel() as IGridSortFilter[];
        this.loadInitialData();
      },
      frameworkComponents: {
        actionRenderer: AgGridActions,
        customCellEditor: AgGridCellEditor,
        customTimeEditor: AgGridDateTimePicker,
        customHeader: AgGridGroupHeader,
        statusRenderer: AgGridStatusBadge,
      },
    };
  }

  private get rightContent(): ReactNode {
    return (
      <ViewPermission hasPermission={AircraftModuleSecurity.isEditable}>
        <CustomLinkButton
          variant="contained"
          startIcon={<AddIcon />}
          to={`performance/${VIEW_MODE.NEW.toLowerCase()}`}
          title="Add Performance"
          disabled={this.isProcessing}
        />
      </ViewPermission>
    );
  }

  public render(): ReactNode {
    return (
      <>
        <SearchHeader
          ref={this.searchHeaderRef}
          searchPlaceHolder={this.searchPlaceHolder}
          searchTypeValue={this.selectedOption}
          searchTypeOptions={this._selectOptions}
          onSearchTypeChange={selectedOption => this._setSelectedOption(selectedOption as PERFORMANCE_FILTERS)}
          onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
          rightContent={this.rightContent}
          onResetFilterClick={() => this.onFilterResetClickHandler()}
          isDisabled={Boolean(this.gridAdvancedSearchFilters.length)}
          expandCollapse={() => this.autoSizeColumns()}
        />
        <CustomAgGridReact
          isRowEditing={this.isRowEditing}
          rowData={this.data}
          gridOptions={this.gridOptions}
          serverPagination={true}
          paginationData={this.pagination}
          onPaginationChange={request => this.loadInitialData(request)}
        />
      </>
    );
  }
}

export default withRouter(withStyles(styles)(Performance));
export { Performance as PurePerformance };
