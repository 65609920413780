import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const styles = createStyles((theme: Theme) => ({
  paperSize: {
    width: 1150,
  },
  headerWrapper: {
    margin: 0,
    wordBreak: 'break-all',
  },
}));

export const useStyles = makeStyles((theme: Theme) => ({
  paperSize: {
    width: 1150,
  },
  headerWrapper: {
    margin: 0,
    wordBreak: 'break-all',
  },
}));
