import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
  createStyles({
    flexRow: {
      display: 'flex',
      flex: 1,
      width: '100%',
      overflowY: 'auto',
      flexDirection: 'column',
    },
    flexWrap: {
      flexWrap: 'wrap',
      display: 'flex',
    },
    inputControl: {
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(3),
      flexBasis: '33%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    fullFlex: {
      flexBasis: '100%',
    },
    pictureRoot: {
      textAlign: 'center',
    },
    picture: {
      maxHeight: '50vh',
    },
    deleteIcon: {
      padding: '0 12px',
    },
    pictureLink: {
      marginBottom: '3vh',
      textAlign: 'left',
    },
  });

export const useStyles = makeStyles((theme: Theme) => ({
  flexRow: {
    display: 'flex',
    flex: 1,
    width: '100%',
    overflowY: 'auto',
    flexDirection: 'column',
  },
  flexWrap: {
    flexWrap: 'wrap',
    display: 'flex',
  },
  inputControl: {
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    flexBasis: '33%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  fullFlex: {
    flexBasis: '100%',
  },
  pictureRoot: {
    textAlign: 'center',
  },
  picture: {
    maxHeight: '50vh',
  },
  deleteIcon: {
    padding: '0 12px',
  },
  pictureLink: {
    marginBottom: '3vh',
    textAlign: 'left',
  },
}));
