import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      alignItems: 'flex-end',
    },
    gridControlContainer: {
      display: 'flex',
      width: '100%',
    },
    dropDownContainer: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      justifyContent: 'flex-start',
    },
    searchInputContainer: {
      display: 'flex',
      flex: 1,
      alignItems: 'baseline',
    },
    dropdown: {
      margin: 10,
    },
  });

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'flex-end',
  },
  gridControlContainer: {
    display: 'flex',
    width: '100%',
  },
  dropDownContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-start',
  },
  searchInputContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'baseline',
  },
  dropdown: {
    margin: 10,
  },
});
