import React, { ReactNode } from 'react';
import { VIEW_MODE } from '@wings/shared';
import { CustomAgGridReact, BaseGrid, AgGridCheckBox } from '@wings-shared/custom-ag-grid';
import {
  withRouter,
  DATE_FORMAT,
  Utilities,
  UIStore,
  IClasses,
  GRID_ACTIONS,
  IBaseGridFilterSetup,
  cellStyle,
} from '@wings-shared/core';
import { ColDef, GridOptions, ValueGetterParams, ValueFormatterParams } from 'ag-grid-community';
import {
  AIRCRAFT_REGISTRY_FILTERS,
  AircraftRegistryStore,
  AircraftRegistryModel,
  AircraftModuleSecurity,
} from '../Shared';
import { withTheme } from '@material-ui/core';
import { observer, inject } from 'mobx-react';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { finalize, takeUntil } from 'rxjs/operators';
import { CustomLinkButton } from '@wings-shared/layout';
import { SearchHeader } from '@wings-shared/form-controls';

interface Props {
  classes?: IClasses;
  aircraftRegistryStore: AircraftRegistryStore;
}

const filtersSetup: IBaseGridFilterSetup<AIRCRAFT_REGISTRY_FILTERS> = {
  defaultPlaceHolder: 'Search by Registry Name',
  filterTypesOptions: Object.values(AIRCRAFT_REGISTRY_FILTERS),
  defaultFilterType: AIRCRAFT_REGISTRY_FILTERS.REGISTRY,
};

@inject('aircraftRegistryStore')
@observer
class AircraftRegistry extends BaseGrid<Props, AircraftRegistryModel, AIRCRAFT_REGISTRY_FILTERS> {
  constructor(props) {
    super(props, filtersSetup);
  }

  /* istanbul ignore next */
  componentDidMount() {
    UIStore.setPageLoader(true);
    this.props.aircraftRegistryStore
      .getAircraftRegistries()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(aircraftVariations => (this.data = aircraftVariations));
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Registry',
      field: 'registry',
      filter: true,
    },
    {
      headerName: 'Registry Nationality',
      field: 'registryNationality',
      filter: true,
      valueFormatter: ({ value }: ValueFormatterParams) => value?.name,
    },
    {
      headerName: 'Registry Start Date',
      field: 'registryStartDate',
      filter: true,
      filterValueGetter: ({ data }: ValueGetterParams) =>
        Utilities.getformattedDate(data.receivedDate, DATE_FORMAT.DATE_PICKER_FORMAT),
      valueFormatter: ({ value }: ValueFormatterParams) =>
        Utilities.getformattedDate(value, DATE_FORMAT.DATE_PICKER_FORMAT),
    },
    {
      headerName: 'Registry End Date',
      field: 'registryEndDate',
      filter: true,
      filterValueGetter: ({ data }: ValueGetterParams) =>
        Utilities.getformattedDate(data.receivedDate, DATE_FORMAT.DATE_PICKER_FORMAT),
      valueFormatter: ({ value }: ValueFormatterParams) =>
        Utilities.getformattedDate(value, DATE_FORMAT.DATE_PICKER_FORMAT),
    },
    {
      headerName: 'ACAS',
      field: 'acas',
      filter: true,
      valueFormatter: ({ value }: ValueFormatterParams) => value?.name,
    },
    {
      headerName: 'Airframe',
      field: 'airframe',
      filter: true,
      valueFormatter: ({ value }: ValueFormatterParams) => value?.name,
    },
    {
      headerName: 'Oceanic Clearance Enabled',
      field: 'isOceanicClearanceEnabled',
      cellRenderer: 'checkBoxRenderer',
      cellRendererParams: { readOnly: true },
      filter: true,
    },
    {
      headerName: 'PDC Registered',
      field: 'isPDCRegistered',
      cellRenderer: 'checkBoxRenderer',
      cellRendererParams: { readOnly: true },
      filter: true,
    },
    ...this.auditFields,
    {
      headerName: 'Action',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      sortable: false,
      filter: false,
      minWidth: 150,
      maxWidth: 210,
      suppressSizeToFit: true,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        isActionMenu: true,
        actionMenus: () => [
          {
            title: 'Edit',
            isHidden: !AircraftModuleSecurity.isEditable,
            action: GRID_ACTIONS.EDIT,
          },
          {
            title: 'Details',
            action: GRID_ACTIONS.DETAILS,
          },
        ],
        onAction: (action: GRID_ACTIONS, rowIndex: number) => {},
      },
    },
  ];

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: false,
      gridActionProps: {
        tooltip: 'Aircraft Registry',
        getDisabledState: () => this.hasError,
      },
    });

    return {
      ...baseOptions,
      doesExternalFilterPass: node => {
        const { id, registry } = node.data as AircraftRegistryModel;
        return (
          !id ||
          this._isFilterPass({
            [AIRCRAFT_REGISTRY_FILTERS.REGISTRY]: registry,
          })
        );
      },
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
      },
      frameworkComponents: {
        ...baseOptions.frameworkComponents,
        checkBoxRenderer: AgGridCheckBox,
      },
    };
  }

  private get rightContent(): ReactNode {
    return (
      <CustomLinkButton
        variant="contained"
        startIcon={<AddIcon />}
        to={VIEW_MODE.NEW.toLowerCase()}
        title="Add Aircraft Registry"
        disabled={this.isProcessing}
      />
    );
  }

  public render(): ReactNode {
    return (
      <>
        <SearchHeader
          searchPlaceHolder={this.searchPlaceHolder}
          searchTypeValue={this.selectedOption}
          searchTypeOptions={this._selectOptions}
          onSearchTypeChange={selectedOption => this._setSelectedOption(selectedOption as AIRCRAFT_REGISTRY_FILTERS)}
          onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
          rightContent={this.rightContent}
          expandCollapse={() => this.autoSizeColumns()}
        />
        <CustomAgGridReact
          isRowEditing={this.isRowEditing}
          rowData={this.data}
          gridOptions={this.gridOptions}
          disablePagination={this.isRowEditing}
        />
      </>
    );
  }
}

export default withRouter(withTheme(AircraftRegistry));
export { AircraftRegistry as PureAircraftRegistry };
