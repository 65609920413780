import React, { ReactNode } from 'react';
import { ColDef, GridOptions, ICellEditorParams, ICellEditor } from 'ag-grid-community';
import { Theme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { SettingsModuleSecurity } from '@wings-shared/security';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import { FUEL_RESERVE_POLICY_FILTER, SettingsStore } from '../../../Shared';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { AlertStore } from '@uvgo-shared/alert';
import { FuelReservePolicyModel } from '../../../Shared/Models';
import { finalize, takeUntil } from 'rxjs/operators';
import { AxiosError } from 'axios';
import { SearchHeader } from '@wings-shared/form-controls';
import { IClasses, UIStore, Utilities, GRID_ACTIONS, IBaseGridFilterSetup, cellStyle } from '@wings-shared/core';
import { AgGridCellEditor, CustomAgGridReact, BaseGrid, AgGridActions } from '@wings-shared/custom-ag-grid';

interface Props {
  settingsStore?: SettingsStore;
  classes?: IClasses;
  theme?: Theme;
}

const filtersSetup: IBaseGridFilterSetup<FUEL_RESERVE_POLICY_FILTER> = {
  defaultPlaceHolder: 'Search by Policy Number',
  filterTypesOptions: Object.values(FUEL_RESERVE_POLICY_FILTER),
  defaultFilterType: FUEL_RESERVE_POLICY_FILTER.NUMBER,
};

@inject('settingsStore')
@observer
class FuelReservePolicy extends BaseGrid<Props, FuelReservePolicyModel, FUEL_RESERVE_POLICY_FILTER> {
  private readonly alertMessageId = 'FuelReservePolicyAlert';

  constructor(props) {
    super(props, filtersSetup);
  }

  componentDidMount() {
    this.loadInitialData();
  }

  private get settingsStore(): SettingsStore {
    return this.props.settingsStore as SettingsStore;
  }

  /* istanbul ignore next */
  private loadInitialData() {
    UIStore.setPageLoader(true);
    this.settingsStore
      .getFuelReservePolicies()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(fuelReservePolicies => (this.data = fuelReservePolicies));
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Policy Number',
      field: 'policyNumber',
      cellEditorParams: {
        isRequired: true,
        rules: 'required|numeric|digits_between:1,3',
      },
    },
    {
      headerName: 'Policy Description',
      field: 'policyDescription',
      cellEditorParams: {
        isRequired: true,
        rules: 'required|string|between:1,250',
      },
    },
    {
      headerName: '',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      sortable: false,
      filter: false,
      hide: !SettingsModuleSecurity.isEditable,
      suppressSizeToFit: true,
      minWidth: 150,
      maxWidth: 210,
      cellStyle: { ...cellStyle() },
    },
  ];

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: SettingsModuleSecurity.isEditable,
      gridActionProps: {
        showDeleteButton: false,
        getDisabledState: () => this.hasError,
        onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
      },
    });
    return {
      ...baseOptions,
      doesExternalFilterPass: node => {
        const { policyDescription, policyNumber, id } = node.data as FuelReservePolicyModel;
        return (
          !id ||
          this._isFilterPass({
            [FUEL_RESERVE_POLICY_FILTER.DESCRIPTION]: policyDescription,
            [FUEL_RESERVE_POLICY_FILTER.NUMBER]: policyNumber.toString(),
          })
        );
      },
      frameworkComponents: {
        actionRenderer: AgGridActions,
        customCellEditor: AgGridCellEditor,
      },
    };
  }

  private addNewModel() {
    this._addNewItems([ new FuelReservePolicyModel({ id: 0 }) ], {
      startEditing: true,
      colKey: 'policyNumber',
    });
    this.hasError = true;
  }

  // Called from Ag Grid Component
  @action
  public onInputChange(params: ICellEditorParams, value: string): void {
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
  }

  @action
  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    if (rowIndex === null) {
      return;
    }
    switch (gridAction) {
      case GRID_ACTIONS.EDIT:
        this._startEditingCell(rowIndex, this.columnDefs[0].field || '');
        break;
      case GRID_ACTIONS.SAVE:
        this.upsertFuelReservePolicy(rowIndex);
        break;
      case GRID_ACTIONS.CANCEL:
      default:
        this._cancelEditing(rowIndex);
        break;
    }
  }

  private get rightContent(): ReactNode {
    if (!SettingsModuleSecurity.isEditable) {
      return null;
    }
    return (
      <PrimaryButton
        variant="contained"
        startIcon={<AddIcon />}
        disabled={this.isProcessing}
        onClick={() => this.addNewModel()}
      >
        Add Fuel Reserve Policy
      </PrimaryButton>
    );
  }

  /* istanbul ignore next */
  private upsertFuelReservePolicy(rowIndex: number): void {
    const model: FuelReservePolicyModel = this._getTableItem(rowIndex);
    if (this.isAlreadyExists(model.id)) {
      return;
    }
    this.gridApi.stopEditing();
    UIStore.setPageLoader(true);
    this.settingsStore
      .upsertFuelReservePolicy(this._getTableItem(rowIndex))
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: FuelReservePolicyModel) => this._updateTableItem(rowIndex, response),
        error: (error: AxiosError) => {
          AlertStore.critical(error.message);
        },
      });
  }

  private isAlreadyExists(id: number): boolean {
    const editorInstance: ICellEditor[] = this.gridApi.getCellEditorInstances({ columns: [ 'policyNumber' ] });
    const value = editorInstance[0]?.getValue();

    const isDuplicateData = this.data.some(a => Utilities.isEqual(a.policyNumber, Number(value)) && id !== a.id);

    if (isDuplicateData) {
      AlertStore.critical(`Fuel Reserve Policy already exist for PolicyNumber: ${value}`);
    }
    return isDuplicateData;
  }

  public render(): ReactNode {
    return (
      <>
        <SearchHeader
          searchPlaceHolder={this.searchPlaceHolder}
          searchTypeValue={this.selectedOption}
          searchTypeOptions={this._selectOptions}
          onSearchTypeChange={selectedOption => this._setSelectedOption(selectedOption as FUEL_RESERVE_POLICY_FILTER)}
          onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
          rightContent={this.rightContent}
          isDisabled={this.isRowEditing}
        />
        <CustomAgGridReact
          isRowEditing={this.isRowEditing}
          rowData={this.data}
          gridOptions={this.gridOptions}
          disablePagination={this.isRowEditing}
        />
      </>
    );
  }
}

export default FuelReservePolicy;
