import React, { ReactNode } from 'react';
import { Utilities, ENTITY_STATE, IClasses, GRID_ACTIONS, cellStyle } from '@wings-shared/core';
import {
  ColDef,
  GridOptions,
  ICellEditorParams,
  RowNode,
  RowEditingStartedEvent,
  GridReadyEvent,
} from 'ag-grid-community';
import { inject, observer } from 'mobx-react';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { AircraftModuleSecurity, FlightPlanFormatAccountModel, FlightPlanStore } from '../../../Shared';
import { styles } from './FlightPlanFormatMasterDetails.style';
import { Chip, withStyles } from '@material-ui/core';
import { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete';
import { ChildGridWrapper, CollapsibleWithButton, ConfirmDialog } from '@wings-shared/layout';
import {
  AgGridCellEditor,
  AgGridViewRenderer,
  CustomAgGridReact,
  BaseGrid,
  AgGridActions,
} from '@wings-shared/custom-ag-grid';

interface Props {
  classes?: IClasses;
  isEditable?: boolean;
  flightPlanFormatAccounts: FlightPlanFormatAccountModel[];
  flightPlanStore?: FlightPlanStore;
  onDataSave: (response: FlightPlanFormatAccountModel[]) => void;
  onRowEditing: (isEditing: boolean) => void;
}

@inject('flightPlanStore')
@observer
class FlightPlanFormatMasterDetails extends BaseGrid<Props, FlightPlanFormatAccountModel> {
  private alertMessageId: string = 'FlightPlanFormatMasterDetailsAlert';

  componentDidUpdate(prevProps: Props) {
    if (this.props.isEditable !== prevProps.isEditable) {
      this._setColumnVisible('actionRenderer', Boolean(this.props.isEditable));
    }
  }

  // Called from Ag Grid Component
  public onInputChange({ colDef }: ICellEditorParams, value: string): void {
    if (Utilities.isEqual(colDef.field || '', 'registriesName')) {
      const hasInValidRegistry = this.hasInValidRegistry(value);
      if (hasInValidRegistry) {
        this.getComponentInstance(colDef.field || '').setCustomError(
          'Registries should be unique with maximum length 100'
        );
      }
    }
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
  }

  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    const { onRowEditing } = this.props;
    if (rowIndex === null) {
      return;
    }
    switch (gridAction) {
      case GRID_ACTIONS.EDIT:
        this._startEditingCell(rowIndex, this.columnDefs[0].field || '');
        break;
      case GRID_ACTIONS.SAVE:
        this.upsertFlightPlanFormatAccount(rowIndex);
        break;
      case GRID_ACTIONS.CANCEL:
        this.canceEditing(rowIndex);
        onRowEditing(false);
        break;
      case GRID_ACTIONS.DELETE:
        this.confirmRemoveAccount(rowIndex);
        break;
      default:
        this.gridApi.stopEditing(true);
        onRowEditing(false);
        break;
    }
  }

  private addNewAccount(): void {
    const formatAccount = new FlightPlanFormatAccountModel({
      id: 0,
      tempId: Utilities.getTempId(true),
    });
    this._addNewItems([ formatAccount ], { startEditing: true, colKey: 'accountNumber' });
    this.hasError = true;
  }

  private confirmRemoveAccount(rowIndex: number): void {
    const model: FlightPlanFormatAccountModel = this._getTableItem(rowIndex);
    if (model.id === 0) {
      this.deleteFlightPlanFormatAccount(model);
      return;
    }

    ModalStore.open(
      <ConfirmDialog
        title="Confirm Delete"
        message="Are you sure you want to remove this Account?"
        yesButton="Delete"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => this.deleteFlightPlanFormatAccount(model)}
      />
    );
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Account',
      field: 'accountNumber',
      cellEditorParams: {
        rules: 'required|string|between:1,5',
        ignoreNumber: true,
      },
    },
    {
      headerName: 'Name',
      field: 'name',
      cellEditorParams: {
        rules: 'required|string|between:1,100',
        ignoreNumber: true,
      },
    },
    {
      headerName: 'Registries',
      field: 'registriesName',
      cellRenderer: 'viewRenderer',
      cellEditorParams: {
        ignoreNumber: true,
      },
      cellRendererParams: {
        getViewRenderer: (rowIndex: number, node: RowNode, classes: IClasses) =>
          this.viewRenderer(node.data?.registriesName),
      },
    },

    {
      headerName: '',
      field: 'actionRenderer',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      maxWidth: 130,
      minWidth: 130,
      sortable: false,
      filter: false,
      suppressSizeToFit: true,
      suppressNavigable: true,
      cellStyle: { ...cellStyle() },
    },
  ];

  /* istanbul ignore next */
  private get gridActionProps(): object {
    return {
      showDeleteButton: true,
      getDisabledState: () => this.hasError,
      getEditableState: () => this.props.isEditable,
      onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
    };
  }

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: AircraftModuleSecurity.isEditable,
      gridActionProps: this.gridActionProps,
    });

    return {
      ...baseOptions,
      suppressClickEdit: true,
      onCellDoubleClicked: ({ rowIndex, colDef }) => {
        const { isEditable } = this.props;
        if (!isEditable) {
          return;
        }
        this._startEditingCell(Number(rowIndex), colDef.field || '');
      },
      onGridReady: (param: GridReadyEvent) => {
        this._onGridReady(param);
        this._setColumnVisible('actionRenderer', Boolean(this.props.isEditable));
      },
      onRowEditingStarted: (event: RowEditingStartedEvent) => {
        if (this.isProcessing) {
          this.gridApi.stopEditing();
          return;
        }
        this.hasError = true;
        this._startEditingRow(event);
        this.props.onRowEditing(true);
      },
      groupHeaderHeight: 0,
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
        filter: true,
      },

      frameworkComponents: {
        actionRenderer: AgGridActions,
        customCellEditor: AgGridCellEditor,
        viewRenderer: AgGridViewRenderer,
      },
    };
  }

  private viewRenderer(registries: string, getTagProps?: AutocompleteGetTagProps): ReactNode {
    return registries
      .split(';')
      .filter(a => a.trim().length)
      .map((registries: string, index) => (
        <Chip
          classes={{ root: this.props.classes?.chip }}
          key={index}
          label={registries.toUpperCase()}
          {...(getTagProps instanceof Function ? getTagProps({ index }) : {})}
        />
      ));
  }

  /* istanbul ignore next */
  private upsertFlightPlanFormatAccount(rowIndex: number): void {
    const { flightPlanFormatAccounts, onRowEditing } = this.props;
    this.data = [ ...flightPlanFormatAccounts ];
    const data: FlightPlanFormatAccountModel = this._getTableItem(rowIndex);
    if (this.hasValidRowData(data, rowIndex)) {
      return;
    }
    onRowEditing(false);
    this.gridApi.stopEditing();
    this.updateTableData();
  }

  /* istanbul ignore next */
  private canceEditing(rowIndex: number): void {
    const data: FlightPlanFormatAccountModel = this._getTableItem(rowIndex);
    const isNewEntry = Utilities.isEqual(data.entityState || '', ENTITY_STATE.UNCHNAGED);
    this._cancelEditing(rowIndex, isNewEntry);
  }

  /* istanbul ignore next */
  private deleteFlightPlanFormatAccount(model: FlightPlanFormatAccountModel): void {
    ModalStore.close();
    this._removeTableItems([ model ]);
    this.updateTableData();
  }

  private updateTableData(): void {
    this.data = this._getAllTableRows().map(
      requirement =>
        new FlightPlanFormatAccountModel({
          ...requirement,
          id: requirement.id || Utilities.getTempId(true),
          entityState: ENTITY_STATE.NEW,
          accountNumber: requirement.accountNumber.padStart(5, '0'),
        })
    );
    this.props.onDataSave(this.data);
  }

  private hasValidRowData(data: FlightPlanFormatAccountModel, rowIndex: number): boolean {
    const value = this.getCellEditorInstance('accountNumber').getValue();
    const isExists = this.data.some(
      account => Utilities.isEqual(account.accountNumber, value.padStart(5, '0')) && !account.isSameData(data)
    );
    if (isExists) {
      this.showAlert('Account should be unique.', this.alertMessageId);
      return isExists;
    }
    return false;
  }

  private hasInValidRegistry(registriesName: string): boolean {
    const regList = registriesName.split(';').map(r => r.trim().toLowerCase());
    return regList.some((registry, index, array) => array.indexOf(registry) !== index || registry.length > 100);
  }

  public render(): ReactNode {
    const { isEditable, classes } = this.props as Required<Props>;
    return (
      <div className={classes.root}>
        <CollapsibleWithButton
          title="Account"
          buttonText="Add Account"
          isButtonDisabled={this.isProcessing || !(AircraftModuleSecurity.isEditable && isEditable)}
          onButtonClick={() => this.addNewAccount()}
        >
          <ChildGridWrapper hasAddPermission={false}>
            <CustomAgGridReact
              isRowEditing={this.isRowEditing}
              rowData={this.props.flightPlanFormatAccounts}
              gridOptions={this.gridOptions}
              disablePagination={this.isRowEditing}
            />
          </ChildGridWrapper>
        </CollapsibleWithButton>
      </div>
    );
  }
}

export default withStyles(styles)(FlightPlanFormatMasterDetails);
export { FlightPlanFormatMasterDetails as PureFlightPlanFormatMasterDetails };
