import React, { ReactNode } from 'react';
import {
  withRouter,
  Utilities,
  UIStore,
  ISelectOption,
  ViewPermission,
  SearchStore,
  IdNameCodeModel,
  SettingsTypeModel,
  SelectOption,
  IBaseGridFilterSetup,
  GRID_ACTIONS, 
  cellStyle
} from '@wings-shared/core';
import { AlertStore } from '@uvgo-shared/alert';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import {
  ColDef,
  GridOptions,
  ValueFormatterParams,
  EditableCallbackParams,
  ICellEditorParams,
  ICellEditor,
  ColGroupDef,
} from 'ag-grid-community';
import {
  FlightPlanningServiceModel,
  FlightPlanningServiceStore,
  FLIGHT_PLANNING_SERVICE_FILTERS,
  SettingsStore,
  AircraftModuleSecurity,
  sidebarMenu
} from '../Shared';
import { withTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { inject, observer } from 'mobx-react';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { finalize, takeUntil } from 'rxjs/operators';
import { action } from 'mobx';
import { AxiosError } from 'axios';
import { SidebarStore, ConfirmDialog } from '@wings-shared/layout';
import RegistryAssociation from './RegistryAssociation/RegistryAssociation';
import { SearchHeader } from '@wings-shared/form-controls';
import {
  AgGridCellEditor,
  CustomAgGridReact,
  BaseGrid,
  AgGridActions,
  AgGridGroupHeader,
  AgGridAutoComplete,
} from '@wings-shared/custom-ag-grid';

interface Props {
  flightPlanningServiceStore: FlightPlanningServiceStore;
  sidebarStore?: typeof SidebarStore;
  settingsStore: SettingsStore;
}

const filtersSetup: IBaseGridFilterSetup<FLIGHT_PLANNING_SERVICE_FILTERS> = {
  defaultPlaceHolder: 'Search by Customer Name',
  filterTypesOptions: Object.values(FLIGHT_PLANNING_SERVICE_FILTERS),
  defaultFilterType: FLIGHT_PLANNING_SERVICE_FILTERS.NAME,
};

@inject('flightPlanningServiceStore', 'settingsStore', 'sidebarStore')
@observer
class FlightPlanningService extends BaseGrid<Props, FlightPlanningServiceModel, FLIGHT_PLANNING_SERVICE_FILTERS> {
  constructor(props) {
    super(props, filtersSetup);
  }

  componentDidMount() {
    this.props.sidebarStore?.setNavLinks(sidebarMenu, 'aircraft');
    this.loadInitialData();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const { clientSearchValue } = SearchStore;
    if (clientSearchValue.searchValue) {
      return;
    }
    SearchStore.clearSearch();
  }

  private get flightPlanningServiceStore(): FlightPlanningServiceStore {
    return this.props.flightPlanningServiceStore;
  }

  /* istanbul ignore next */
  private loadInitialData() {
    UIStore.setPageLoader(true);
    this.flightPlanningServiceStore
      .getFlightPlanningServices(true)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(response => {
        this.data = response.results;
        const { clientSearchValue } = SearchStore;
        const { selectedOption, searchValue } = clientSearchValue;
        if (searchValue) {
          this._setSelectedOption(
            (selectedOption as FLIGHT_PLANNING_SERVICE_FILTERS) || FLIGHT_PLANNING_SERVICE_FILTERS.NAME
          );
          this._setSearchValue(searchValue);
          this.searchHeaderRef.current?.setSearchValue(searchValue);
          SearchStore.clearSearch();
          return;
        }
        SearchStore.clearSearch();
      });
  }

  /* istanbul ignore next */
  private upsertFlightPlanningService(rowIndex: number): void {
    const data: FlightPlanningServiceModel = this._getTableItem(rowIndex);
    if (this.isAlreadyExists(data)) {
      return;
    }
    this.gridApi.stopEditing();
    const model = new FlightPlanningServiceModel({
      ...data,
      customerNumber: new SettingsTypeModel({
        name: data.customerNumber?.value?.toString(),
      }),
    });
    UIStore.setPageLoader(true);
    this.flightPlanningServiceStore
      .upsertFlightPlanningService(model)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: FlightPlanningServiceModel) => this._updateTableItem(rowIndex, response),
        error: (error: AxiosError) => AlertStore.critical(error.message),
      });
  }

  private isAlreadyExists(currentData: FlightPlanningServiceModel): boolean {
    const editorInstance: ICellEditor[] = this.gridApi.getCellEditorInstances({ columns: [ 'customerNumber' ] });
    const value = editorInstance[0].getValue().value;
    const isDuplicateData = this.data.some(a => a.customerNumber.name === value);
    if (isDuplicateData) {
      this.showAlert('Customer Number should be unique.', 'FlightPlanningService');
      return true;
    }
    return false;
  }

  private get customerLists(): ISelectOption[] {
    return this.flightPlanningServiceStore.customers.map(a => {
      return { ...a, id: a.code, value: a.code, label: `${a.name}-${a.code}` };
    });
  }

  /* istanbul ignore next */
  private columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Customer Number',
      field: 'customerNumber',
      cellEditor: 'customAutoComplete',
      cellRenderer: 'agGroupCellRenderer',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.name,
      editable: ({ data }: EditableCallbackParams) => Boolean(!data?.id),
      cellEditorParams: {
        isRequired: () => true,
        getAutoCompleteOptions: () => this.customerLists,
        onSearch: value => this.searchCustomers(value),
        valueGetter: (option: SelectOption) => option.value,
      },
    },
    {
      headerName: 'Customer Name',
      field: 'customerName',
      cellEditorParams: {
        ignoreNumber: true,
        rules: 'required|string|between:1,100',
      },
    },
    {
      columnGroupShow: 'open',
      ...this.auditFields[0],
    },
    {
      headerName: '',
      field: 'actionRenderer',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      maxWidth: 150,
      minWidth: 130,
      suppressSizeToFit: true,
      suppressNavigable: true,
      filter: false,
      sortable: false,
      hide: !AircraftModuleSecurity.isEditable,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        isActionMenu: true,
        actionMenus: () => [{ title: 'Delete', isHidden: false, action: GRID_ACTIONS.DELETE }],
        onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
      },
    },
  ];

  /* istanbul ignore next */
  private get gridActionProps(): object {
    return {
      tooltip: 'Flight Planning Service',
      showDeleteButton: false,
      getDisabledState: () => this.hasError,
      onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
    };
  }

  // Called from Ag Grid Component
  /* istanbul ignore next */
  @action
  public onInputChange(params: ICellEditorParams, value: string): void {
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
  }

  // Called from Ag Grid Component
  /* istanbul ignore next */
  @action
  public onDropDownChange(params: ICellEditorParams, option: IdNameCodeModel): void {
    this.hasError = Utilities.hasInvalidRowData(this.gridApi) || !option?.value;
    if (option?.name) {
      this.getComponentInstance('customerName').setValue(option.name);
      this.hasError = false;
      return;
    }
  }

  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    if (rowIndex === null) {
      return;
    }
    switch (gridAction) {
      case GRID_ACTIONS.SAVE:
        this.upsertFlightPlanningService(rowIndex);
        break;
      case GRID_ACTIONS.DELETE:
        ModalStore.open(
          <ConfirmDialog
            title="Delete Flight Planning Service"
            message="Are you sure you want to delete this Record?"
            yesButton="Yes"
            onNoClick={() => ModalStore.close()}
            onYesClick={() => this.deleteRecord(rowIndex)}
          />
        );
        break;
      case GRID_ACTIONS.CANCEL:
      default:
        this._cancelEditing(rowIndex);
        break;
    }
  }

  /* istanbul ignore next */
  private searchCustomers(searchValue: string): void {
    const request = {
      searchCollection: JSON.stringify([
        { propertyName: 'CustomerNumber', propertyValue: searchValue, operator: 'and' },
        { propertyName: 'CustomerName', propertyValue: searchValue, operator: 'or' },
      ]),
    };
    this.flightPlanningServiceStore
      .getCustomers(request)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  /* istanbul ignore next */
  @action
  private deleteRecord(rowIndex: number): void {
    const data: FlightPlanningServiceModel = this._getTableItem(rowIndex);
    UIStore.setPageLoader(true);
    ModalStore.close();
    this.flightPlanningServiceStore
      .removeFlightPlanningService(data.id)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe({
        next: () => {
          this._removeTableItems([ data ]);
          this.data = this._getAllTableRows();
        },
        error: (error: AxiosError) => AlertStore.critical(error.message),
      });
  }

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: true,
      gridActionProps: this.gridActionProps,
    });

    return {
      ...baseOptions,
      suppressClickEdit: true,
      detailCellRenderer: 'customDetailCellRenderer',
      detailCellRendererParams: {
        isMasterDetails: true,
        isEditable: AircraftModuleSecurity.isEditable,
        isParentRowEditing: () => this.isRowEditing,
        flightPlanningServiceStore: this.props.flightPlanningServiceStore,
        settingsStore: this.props.settingsStore,
      },
      doesExternalFilterPass: node => {
        const { id, customerName, customerNumber } = node.data as FlightPlanningServiceModel;
        return (
          !id ||
          this._isFilterPass({
            [FLIGHT_PLANNING_SERVICE_FILTERS.NAME]: customerName,
            [FLIGHT_PLANNING_SERVICE_FILTERS.NUMBER]: customerNumber.name,
          })
        );
      },
      groupHeaderHeight: 0,
      suppressColumnVirtualisation: true,
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
      },
      masterDetail: true,
      frameworkComponents: {
        actionRenderer: AgGridActions,
        customCellEditor: AgGridCellEditor,
        customHeader: AgGridGroupHeader,
        customAutoComplete: AgGridAutoComplete,
        customDetailCellRenderer: RegistryAssociation,
      },
    };
  }

  @action
  private addFlighPlanningService(): void {
    this._addNewItems([ new FlightPlanningServiceModel({ id: 0 }) ], {
      startEditing: true,
      colKey: 'customerNumber',
    });
    this.hasError = true;
  }

  private get rightContent(): ReactNode {
    return (
      <ViewPermission hasPermission={AircraftModuleSecurity.isEditable}>
        <PrimaryButton
          variant="contained"
          startIcon={<AddIcon />}
          disabled={this.isProcessing}
          onClick={() => this.addFlighPlanningService()}
        >
          Add Flight Planning Service
        </PrimaryButton>
      </ViewPermission>
    );
  }

  public render(): ReactNode {
    return (
      <>
        <SearchHeader
          ref={this.searchHeaderRef}
          searchPlaceHolder={this.searchPlaceHolder}
          searchTypeValue={this.selectedOption}
          searchTypeOptions={this._selectOptions}
          onSearchTypeChange={selectedOption =>
            this._setSelectedOption(selectedOption as FLIGHT_PLANNING_SERVICE_FILTERS)
          }
          onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
          rightContent={this.rightContent}
        />
        <CustomAgGridReact
          isRowEditing={this.isRowEditing}
          rowData={this.data}
          gridOptions={this.gridOptions}
          disablePagination={this.isRowEditing}
        />
      </>
    );
  }
}

export default withRouter(withTheme(FlightPlanningService));
export { FlightPlanningService as PureFlightPlanningService };
