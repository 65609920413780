import React, { ReactNode, RefObject } from 'react';
import { ColDef, GridOptions, ValueGetterParams, ValueFormatterParams, RowNode } from 'ag-grid-community';
import { AircraftVariationModel, VARIATION_SEARCH_FILTERS } from '../../../Shared';
import { withStyles, Select, MenuItem, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { styles } from './VariationSearch.styles';
import { observable, action } from 'mobx';
import { SearchHeader, PureSearchHeader } from '@wings-shared/form-controls';
import { IClasses, Utilities, withRouter, SettingsTypeModel, IBaseGridFilterSetup } from '@wings-shared/core';
import { AgGridViewRenderer, CustomAgGridReact, BaseGrid, AgGridChipView } from '@wings-shared/custom-ag-grid';

interface Props {
  classes?: IClasses;
  data: AircraftVariationModel[];
  onSelect: (selectedVariation: AircraftVariationModel) => void;
}

const filtersSetup: IBaseGridFilterSetup<VARIATION_SEARCH_FILTERS> = {
  defaultPlaceHolder: 'Search',
  filterTypesOptions: Object.values(VARIATION_SEARCH_FILTERS),
  defaultFilterType: VARIATION_SEARCH_FILTERS.ALL,
};

@observer
class VariationSearch extends BaseGrid<Props, AircraftVariationModel> {
  @observable private currentPageSize: number = 30;
  public searchHeaderRef: RefObject<PureSearchHeader> = React.createRef<PureSearchHeader>();
  constructor(props) {
    super(props, filtersSetup);
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'ICAO Type Designator',
      field: 'icaoTypeDesignator',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterValueGetter: ({ data }: ValueGetterParams) => data.icaoTypeDesignator?.label,
    },
    {
      headerName: 'A/C Make',
      field: 'make',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterValueGetter: ({ data }: ValueGetterParams) => data.make.label,
    },
    {
      headerName: 'A/C Model',
      field: 'model',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterValueGetter: ({ data }: ValueGetterParams) => data.model.label,
    },
    {
      headerName: 'Series',
      field: 'series',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterValueGetter: ({ data }: ValueGetterParams) => data.series.label,
    },
    {
      headerName: 'Engine Type',
      field: 'engineType',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterValueGetter: ({ data }: ValueGetterParams) => data.engineType.label,
    },
    {
      headerName: 'Popular Names',
      field: 'popularNames',
      cellRenderer: 'agGridChipView',
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterValueGetter: ({ data }: ValueGetterParams) => data.popularNames.map(a => a.label).toString(),
    },
    {
      headerName: '',
      field: 'action',
      cellRenderer: 'viewRenderer',
      filter: false,
      minWidth: 100,
      cellRendererParams: {
        getViewRenderer: (rowIndex: number, node: RowNode) => this.viewRenderer(node.data),
      },
    },
  ];

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: false,
      gridActionProps: {
        tooltip: 'Aircraft Variation',
        getDisabledState: () => this.hasError,
      },
    });

    return {
      ...baseOptions,
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
      },
      paginationPageSize: this.currentPageSize,
      doesExternalFilterPass: node => {
        const { icaoTypeDesignator, make, model, series, engineType } = node.data as AircraftVariationModel;
        return this._isFilterPass({
          [VARIATION_SEARCH_FILTERS.ALL]: [
            icaoTypeDesignator.label,
            make.label,
            model.label,
            series.label,
            engineType.label,
          ],
        });
      },
      frameworkComponents: {
        viewRenderer: AgGridViewRenderer,
        agGridChipView: AgGridChipView,
      },
    };
  }

  /* istanbul ignore next */
  private viewRenderer(rowData: AircraftVariationModel): ReactNode {
    return (
      <PrimaryButton variant="text" color="primary" onClick={() => this.props.onSelect(rowData)}>
        Select
      </PrimaryButton>
    );
  }

  private resetFilter(): void {
    this.searchHeaderRef.current?.resetSearchInput();
    this._setSearchValue('');
    this.columnDefs.forEach(col => {
      const filterComponent = this.gridApi.getFilterInstance(col.field || '');
      filterComponent?.setModel(null);
      this.gridApi.onFilterChanged();
    });
  }

  @action
  private onPageSizeChange(pageSize: number): void {
    this.currentPageSize = pageSize;
    this.gridApi.paginationSetPageSize(pageSize);
  }

  public render(): ReactNode {
    const { classes } = this.props as Required<Props>;
    return (
      <div className={classes.container}>
        <div className={classes.gridControlContainer}>
          <div className={classes.dropDownContainer}>
            <Typography>Show</Typography>
            <Select
              value={this.currentPageSize}
              onChange={event => this.onPageSizeChange(event.target.value as number)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              className={classes.dropdown}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </Select>
            <Typography>entries</Typography>
          </div>
          <div className={classes.searchInputContainer}>
            <SearchHeader
              ref={this.searchHeaderRef}
              searchPlaceHolder={this.searchPlaceHolder}
              searchTypeValue={this.selectedOption}
              searchTypeOptions={this._selectOptions}
              onSearchTypeChange={selectedOption => this._setSelectedOption(selectedOption as VARIATION_SEARCH_FILTERS)}
              onSearch={(searchValue: string) => this._setSearchValue(searchValue)}
              isHideSearchSelectControl={true}
            />
            <PrimaryButton variant="text" color="primary" onClick={() => this.resetFilter()}>
              Clear filter
            </PrimaryButton>
          </div>
        </div>
        <CustomAgGridReact
          isRowEditing={this.isRowEditing}
          rowData={this.props.data}
          gridOptions={this.gridOptions}
          disablePagination={this.isRowEditing}
        />
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(VariationSearch));
export { VariationSearch as PureVariationSearch };
