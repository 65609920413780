import React, { ReactNode } from 'react';
import {
  Utilities,
  DATE_FORMAT,
  ENTITY_STATE,
  DATE_TIME_PICKER_TYPE,
  IClasses,
  GRID_ACTIONS,
  cellStyle,
} from '@wings-shared/core';
import {
  ColDef,
  GridOptions,
  ICellEditorParams,
  RowEditingStartedEvent,
  ValueFormatterParams,
  GridReadyEvent,
  ValueGetterParams,
} from 'ag-grid-community';
import { inject, observer } from 'mobx-react';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { AircraftModuleSecurity, FlightPlanStore, FlightPlanFormatChangeRecordModel } from '../../../Shared';
import { withStyles } from '@material-ui/core';
import { styles } from '../FlightPlanDocumentGrid/FlightPlanDocumentGrid.styles';
import { ChildGridWrapper, CollapsibleWithButton, ConfirmDialog } from '@wings-shared/layout';
import {
  AgGridCellEditor,
  CustomAgGridReact,
  BaseGrid,
  AgGridActions,
  AgGridDateTimePicker,
} from '@wings-shared/custom-ag-grid';

interface Props {
  classes?: IClasses;
  isEditable?: boolean;
  flightPlanFormatChangeRecords: FlightPlanFormatChangeRecordModel[];
  flightPlanStore?: FlightPlanStore;
  onDataSave: (response: FlightPlanFormatChangeRecordModel[]) => void;
  onRowEditing: (isEditing: boolean) => void;
}

@inject('flightPlanStore')
@observer
class FlightPlanChangeRecordGrid extends BaseGrid<Props, FlightPlanFormatChangeRecordModel> {
  // Called from Ag Grid Component
  public onInputChange({ colDef }: ICellEditorParams, value: string): void {
    this.hasError = Utilities.hasInvalidRowData(this.gridApi);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.isEditable !== prevProps.isEditable) {
      this._setColumnVisible('actionRenderer', Boolean(this.props.isEditable));
    }
  }

  private gridActions(gridAction: GRID_ACTIONS, rowIndex: number): void {
    const { onRowEditing } = this.props;
    if (rowIndex === null) {
      return;
    }
    switch (gridAction) {
      case GRID_ACTIONS.EDIT:
        this._startEditingCell(rowIndex, this.columnDefs[0].field || '');
        break;
      case GRID_ACTIONS.SAVE:
        this.upsertFlightPlanFormatChangeRecord(rowIndex);
        onRowEditing(false);
        break;
      case GRID_ACTIONS.CANCEL:
        this.canceEditing(rowIndex);
        onRowEditing(false);
        break;
      case GRID_ACTIONS.DELETE:
        this.confirmRemoveRecord(rowIndex);
        break;
      default:
        this.gridApi.stopEditing(true);
        onRowEditing(false);
        break;
    }
  }

  private addNewChangeRecord(): void {
    const changeRecord = new FlightPlanFormatChangeRecordModel({ id: 0 });
    this._addNewItems([ changeRecord ], { startEditing: true, colKey: 'requestedBy' });
    this.hasError = true;
  }

  private confirmRemoveRecord(rowIndex: number): void {
    const model: FlightPlanFormatChangeRecordModel = this._getTableItem(rowIndex);
    if (model.id === 0) {
      this.deleteFlightPlanFormatChangeRecord(model);
      return;
    }

    ModalStore.open(
      <ConfirmDialog
        title="Confirm Delete"
        message="Are you sure you want to remove this record?"
        yesButton="Delete"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => this.deleteFlightPlanFormatChangeRecord(model)}
      />
    );
  }

  /* istanbul ignore next */
  private columnDefs: ColDef[] = [
    {
      headerName: 'Requested By',
      field: 'requestedBy',
      cellEditorParams: {
        rules: 'required|string|between:1,100',
        ignoreNumber: true,
      },
    },
    {
      headerName: 'Changed By',
      field: 'changedBy',
      cellEditorParams: {
        rules: 'required|string|between:1,100',
        ignoreNumber: true,
      },
    },
    {
      headerName: 'Notes',
      field: 'notes',
      minWidth: 700,
      cellEditorParams: {
        rules: 'string|between:1,500',
        ignoreNumber: true,
      },
    },
    {
      headerName: 'Changed Date',
      field: 'changedDate',
      cellEditor: 'customTimeEditor',
      valueFormatter: ({ value }: ValueFormatterParams) =>
        Utilities.getformattedDate(value, DATE_FORMAT.DATE_PICKER_FORMAT),
      filterValueGetter: ({ data }: ValueGetterParams) =>
        Utilities.getformattedDate(data.changedDate, DATE_FORMAT.API_DATE_FORMAT),
      cellEditorParams: {
        isRequired: () => true,
        format: DATE_FORMAT.DATE_PICKER_FORMAT,
        pickerType: DATE_TIME_PICKER_TYPE.DATE,
      },
    },
    {
      headerName: '',
      field: 'actionRenderer',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      maxWidth: 130,
      minWidth: 130,
      sortable: false,
      filter: false,
      suppressSizeToFit: true,
      suppressNavigable: true,
      cellStyle: { ...cellStyle() },
    },
  ];

  /* istanbul ignore next */
  private get gridActionProps(): object {
    return {
      showDeleteButton: true,
      getDisabledState: () => this.hasError,
      getEditableState: () => this.props.isEditable,
      onAction: (action: GRID_ACTIONS, rowIndex: number) => this.gridActions(action, rowIndex),
    };
  }

  /* istanbul ignore next */
  private get gridOptions(): GridOptions {
    const baseOptions: Partial<GridOptions> = this._gridOptionsBase({
      context: this,
      columnDefs: this.columnDefs,
      isEditable: AircraftModuleSecurity.isEditable,
      gridActionProps: this.gridActionProps,
    });

    return {
      ...baseOptions,
      suppressClickEdit: true,
      onCellDoubleClicked: ({ rowIndex, colDef }) => {
        const { isEditable } = this.props;
        if (!isEditable) {
          return;
        }
        this._startEditingCell(Number(rowIndex), colDef.field || '');
      },
      onGridReady: (param: GridReadyEvent) => {
        this._onGridReady(param);
        this._setColumnVisible('actionRenderer', Boolean(this.props.isEditable));
      },
      onRowEditingStarted: (event: RowEditingStartedEvent) => {
        if (this.isProcessing) {
          this.gridApi.stopEditing();
          return;
        }
        this.hasError = true;
        this._startEditingRow(event);
        this.props.onRowEditing(true);
      },
      defaultColDef: {
        ...baseOptions.defaultColDef,
        suppressMovable: true,
        filter: true,
      },

      frameworkComponents: {
        actionRenderer: AgGridActions,
        customCellEditor: AgGridCellEditor,
        customTimeEditor: AgGridDateTimePicker,
      },
    };
  }

  /* istanbul ignore next */
  private upsertFlightPlanFormatChangeRecord(rowIndex: number): void {
    this.gridApi.stopEditing();
    this.updateTableData();
  }

  /* istanbul ignore next */
  private canceEditing(rowIndex: number): void {
    const data: FlightPlanFormatChangeRecordModel = this._getTableItem(rowIndex);
    const isNewEntry = Utilities.isEqual(data.entityState || '', ENTITY_STATE.UNCHNAGED);
    this._cancelEditing(rowIndex, isNewEntry);
  }

  /* istanbul ignore next */
  private deleteFlightPlanFormatChangeRecord(model: FlightPlanFormatChangeRecordModel): void {
    ModalStore.close();
    this._removeTableItems([ model ]);
    this.updateTableData();
  }

  private updateTableData(): void {
    this.data = this._getAllTableRows().map(
      requirement =>
        new FlightPlanFormatChangeRecordModel({
          ...requirement,
          id: requirement.id || Utilities.getTempId(true),
          entityState: ENTITY_STATE.NEW,
        })
    );
    this.props.onDataSave(this.data);
  }

  public render(): ReactNode {
    const { isEditable, classes } = this.props as Required<Props>;
    return (
      <div className={classes.root}>
        <CollapsibleWithButton
          title="Change Record"
          buttonText="Add Change Record"
          isButtonDisabled={this.isProcessing || !(AircraftModuleSecurity.isEditable && isEditable)}
          onButtonClick={() => this.addNewChangeRecord()}
        >
          <ChildGridWrapper hasAddPermission={false}>
            <CustomAgGridReact
              isRowEditing={this.isRowEditing}
              rowData={this.props.flightPlanFormatChangeRecords}
              gridOptions={this.gridOptions}
              disablePagination={this.isRowEditing}
            />
          </ChildGridWrapper>
        </CollapsibleWithButton>
      </div>
    );
  }
}

export default withStyles(styles)(FlightPlanChangeRecordGrid);
export { FlightPlanChangeRecordGrid as PureFlightPlanChangeRecordGrid };
