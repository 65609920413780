import React, { ReactNode } from 'react';
import { withStyles } from '@material-ui/core';
import { VIEW_MODE, BaseUpsertComponent, ModelStatusOptions } from '@wings/shared';
import {
  AuditFields,
  EDITOR_TYPES,
  ViewInputControl,
  IViewInputControl,
  IGroupInputControls,
} from '@wings-shared/form-controls';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { fields } from './Fields';
import { styles } from './PerformanceEditor.styles';
import classNames from 'classnames';
import { finalize, takeUntil } from 'rxjs/operators';
import { NavigateFunction } from 'react-router';
import { ArrowBack } from '@material-ui/icons';
import { forkJoin, Observable, of } from 'rxjs';
import ScheduleGrid from './ScheduleGrid/ScheduleGrid';
import PerformanceLinkGrid from './PerformanceLinkGrid/PerformanceLinkGrid';
import GenericRegistryGrid from './GenericRegistryGrid/GenericRegistryGrid';
import { AlertStore } from '@uvgo-shared/alert';
import {
  AircraftModuleSecurity,
  CruisePolicyScheduleModel,
  PerformanceModel,
  PerformanceStore,
  PolicyScheduleModel,
  SettingsProfileModel,
  SettingsStore,
  SpeedScheduleSettingsStore,
  updateAircraftSidebarOptions
} from '../../Shared';
import {
  IAPIGridRequest,
  IClasses,
  IOptionValue,
  UIStore,
  Utilities,
  withRouter,
  ViewPermission,
  SettingsTypeModel,
  GRID_ACTIONS,
} from '@wings-shared/core';
import {
  CustomLinkButton,
  EditSaveButtons,
  DetailsEditorWrapper,
  Collapsable,
  SidebarStore,
} from '@wings-shared/layout';
import CruiseScheduleGrid from './CruiseScheduleGrid/CruiseScheduleGrid';

interface Props {
  classes?: IClasses;
  performanceStore?: PerformanceStore;
  settingsStore?: SettingsStore;
  speedScheduleSettingsStore?: SpeedScheduleSettingsStore;
  viewMode?: VIEW_MODE;
  params?: { mode: VIEW_MODE; id: number };
  navigate?: NavigateFunction;
  sidebarStore?: typeof SidebarStore;
}

@inject('performanceStore', 'settingsStore', 'speedScheduleSettingsStore', 'sidebarStore')
@observer
class PerformanceEditor extends BaseUpsertComponent<Props, PerformanceModel> {
  private readonly alertId: string = 'PerformanceEditorAlert';
  @observable private editingGrid: string[] = [];
  @observable private performanceData: PerformanceModel = new PerformanceModel();

  constructor(p: Props) {
    super(p, fields);
    this.viewMode = (p.params?.mode.toUpperCase() as VIEW_MODE) || VIEW_MODE.DETAILS;
  }

  @action
  private updateRowEditing(isEditing: boolean, girdName: string): void {
    if (isEditing) {
      this.editingGrid.push(girdName);
      return;
    }
    this.editingGrid = [ ...this.editingGrid.filter(a => !Utilities.isEqual(a, girdName)) ];
  }

  private get isRowEditing(): boolean {
    return Boolean(this.editingGrid.length);
  }

  // needs to access using ref
  public get hasError(): boolean {
    if (this.form.hasError) {
      return true;
    }
    return false;
  }

  /* istanbul ignore next */
  componentDidMount() {
    this.props.sidebarStore?.setNavLinks(updateAircraftSidebarOptions('Performance'), 'aircraft');
    UIStore.setPageLoader(true);
    forkJoin([
      this.loadPerformanceById(),
      this.performanceStore.getPerformances(),
      this.loadScheduleData(),
      this.loadBaseData(),
    ])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(([ performance ]) => {
        if (!this.performanceId) {
          this.setFormValues(this.performanceData);
          return;
        }
        this.performanceData = performance;
        this.setFormValues(new PerformanceModel({ ...this.performanceData }));
      });
  }

  private loadPerformanceById(): Observable<PerformanceModel> {
    if (!this.performanceId) {
      return of(new PerformanceModel());
    }
    const request: IAPIGridRequest = {
      filterCollection: JSON.stringify([ Utilities.getFilter('PerformanceId', this.performanceId) ]),
    };
    return this.performanceStore.getPerformanceById(request);
  }

  loadScheduleData(): Observable<
    [SettingsProfileModel[], SettingsProfileModel[], SettingsProfileModel[], SettingsProfileModel[]]
    > {
    return forkJoin([
      this.speedScheduleSettingsStore.getClimbSchedules(),
      this.speedScheduleSettingsStore.getHoldSchedules(),
      this.speedScheduleSettingsStore.getCruiseSchedules(),
      this.speedScheduleSettingsStore.getDescentSchedules(),
    ]);
  }
  loadBaseData(): Observable<[SettingsTypeModel[], SettingsTypeModel[], SettingsTypeModel[], SettingsTypeModel[]]> {
    return forkJoin([
      this.settingsStore.getSourceTypes(),
      this.settingsStore.getAccessLevels(),
      this.settingsStore.getWakeTurbulenceCategories(),
      this.settingsStore.getICAOTypeDesignators(),
    ]);
  }

  /* istanbul ignore next */
  private isValidData(performanceData: PerformanceModel): boolean {
    const { performances } = this.performanceStore;
    const { climbSchedules, cruiseSchedules, descentSchedules, holdSchedules } = performanceData;
    const isDuplicate = performances.some(
      x => Utilities.isEqual(x.name, performanceData.name) && !Utilities.isEqual(x.id, performanceData.id)
    );
    if (isDuplicate) {
      this.showAlert('Name should be unique.', this.alertId);
      return false;
    }
    if (!this.isScheduleValid(climbSchedules)) {
      this.showAlert('Climb Schedule should have default schedule', this.alertId);
      return false;
    }
    if (!this.isCruiseScheduleValid(cruiseSchedules)) {
      this.showAlert('Cruise Schedule should have default schedule', this.alertId);
      return false;
    }
    if (!this.isScheduleValid(descentSchedules)) {
      this.showAlert('Descent Schedule should have default schedule', this.alertId);
      return false;
    }
    if (!this.isScheduleValid(holdSchedules)) {
      this.showAlert('Hold Schedule should have default schedule', this.alertId);
      return false;
    }
    return true;
  }

  private isScheduleValid(schedules: PolicyScheduleModel[]): boolean {
    return schedules.length ? schedules.some(a => a.isDefault) : true;
  }

  private isCruiseScheduleValid(schedules: CruisePolicyScheduleModel[]): boolean {
    return schedules.length ? schedules.some(a => a.isDefault) : true;
  }

  /* istanbul ignore next */
  private upsertPerformance(): void {
    const performanceData: PerformanceModel = this.getUpdatedModel();
    if (!this.isValidData(performanceData)) {
      return;
    }
    UIStore.setPageLoader(true);
    this.performanceStore
      .upsertPerformance(performanceData)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: () => {
          this.setRichEditorFocused(false);
          this.form.reset();
          this.navigateToPerformance();
        },
        error: error => AlertStore.critical(error.message),
      });
  }

  @action
  protected onCancel(model: PerformanceModel): void {
    const viewMode = this.props.params?.mode.toUpperCase();
    if (viewMode === VIEW_MODE.DETAILS) {
      this.setViewMode(VIEW_MODE.DETAILS);
      this.form.reset();
      this.setFormValues(new PerformanceModel({ ...this.performanceData }));
      return;
    }
    this.navigateToPerformance();
  }

  private onAction(action: GRID_ACTIONS): void {
    switch (action) {
      case GRID_ACTIONS.EDIT:
        this.setViewMode(VIEW_MODE.EDIT);
        break;
      case GRID_ACTIONS.SAVE:
        this.upsertPerformance();
        break;
      case GRID_ACTIONS.CANCEL:
        this.onCancel(this.performanceData);
        break;
    }
  }

  /* istanbul ignore next */
  private navigateToPerformance(): void {
    this.props.navigate && this.props.navigate('/aircraft/performance');
  }

  private get performanceId(): number {
    return Number(this.props.params?.id);
  }

  private get performanceStore(): PerformanceStore {
    return this.props.performanceStore as PerformanceStore;
  }

  private get settingsStore(): SettingsStore {
    return this.props.settingsStore as SettingsStore;
  }

  private get speedScheduleSettingsStore(): SpeedScheduleSettingsStore {
    return this.props.speedScheduleSettingsStore as SpeedScheduleSettingsStore;
  }

  /* istanbul ignore next */
  private get groupInputControls(): IGroupInputControls {
    return {
      title: 'General',
      inputControls: [
        {
          fieldKey: 'name',
          type: EDITOR_TYPES.TEXT_FIELD,
        },
        {
          fieldKey: 'maxFlightLevel',
          type: EDITOR_TYPES.TEXT_FIELD,
        },
        {
          fieldKey: 'mtowInPounds',
          type: EDITOR_TYPES.TEXT_FIELD,
        },
        {
          fieldKey: 'mtowInKilos',
          type: EDITOR_TYPES.TEXT_FIELD,
          isDisabled: true,
        },
        {
          fieldKey: 'icaoTypeDesignator',
          type: EDITOR_TYPES.DROPDOWN,
          options: this.settingsStore.icaoTypeDesignators,
        },
        {
          fieldKey: 'wakeTurbulenceCategory',
          type: EDITOR_TYPES.DROPDOWN,
          options: this.settingsStore.wakeTurbulenceCategories,
        },
        {
          fieldKey: 'isRestricted',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'isVerificationComplete',
          type: EDITOR_TYPES.CHECKBOX,
        },
        {
          fieldKey: 'fomS230',
          type: EDITOR_TYPES.TEXT_FIELD,
          isFullFlex: true,
          multiline: true,
          rows: 5,
        },
      ],
    };
  }

  /* istanbul ignore next */
  private get systemInputControls(): IGroupInputControls {
    return {
      title: 'System',
      inputControls: [
        {
          fieldKey: 'accessLevel',
          type: EDITOR_TYPES.DROPDOWN,
          options: this.settingsStore.accessLevels,
        },
        {
          fieldKey: 'sourceType',
          type: EDITOR_TYPES.DROPDOWN,
          options: this.settingsStore.sourceTypes,
        },
        {
          fieldKey: 'status',
          type: EDITOR_TYPES.DROPDOWN,
          options: ModelStatusOptions,
          isHalfFlex: !this.isDetailView,
        },
      ],
    };
  }

  private getUpdatedModel(): PerformanceModel {
    const formValues: PerformanceModel = this.form.values();
    const { climbSchedules, cruiseSchedules, descentSchedules, holdSchedules, performanceLinks } = formValues;
    const updatedModel = new PerformanceModel({
      ...this.performanceData,
      ...formValues,
      climbSchedules,
      cruiseSchedules,
      descentSchedules,
      holdSchedules,
      performanceLinks,
      defaultClimbSchedule: this.getDefaultSchedule(climbSchedules),
      defaultCruiseSchedule: this.getDefaultCruiseSchedule(cruiseSchedules),
      defaultDescentSchedule: this.getDefaultSchedule(descentSchedules),
      defaultHoldSchedule: this.getDefaultSchedule(holdSchedules),
    });
    return updatedModel;
  }

  private getDefaultSchedule(schedule: PolicyScheduleModel[]): SettingsTypeModel {
    return new SettingsTypeModel(schedule.find(a => a.isDefault)?.schedule);
  }

  private getDefaultCruiseSchedule(schedule: CruisePolicyScheduleModel[]): SettingsTypeModel {
    return new SettingsTypeModel(schedule.find(a => a.isDefault)?.schedule);
  }

  @action
  protected onValueChange(value: IOptionValue, fieldKey: string): void {
    if (Utilities.isEqual(fieldKey, 'mtowInPounds')) {
      this.getField('mtowInKilos').set('');
    }
    this.getField(fieldKey).set(value);
  }

  private get systemDataFields(): ReactNode {
    const { classes } = this.props as Required<Props>;
    return (
      <Collapsable title="System" titleVariant="h6">
        <>
          <div className={classes.flexWrap}>
            {this.systemInputControls.inputControls
              .filter(inputControl => !inputControl.isHidden)
              .map((inputControl: IViewInputControl, index: number) => (
                <ViewInputControl
                  {...inputControl}
                  key={index}
                  field={this.getField(inputControl.fieldKey || '')}
                  isEditable={this.isEditable}
                  onValueChange={(option, _) => this.onValueChange(option, inputControl.fieldKey || '')}
                />
              ))}
          </div>
          <AuditFields
            isEditable={this.isEditable}
            fieldControls={this.auditFields}
            onGetField={(fieldKey: string) => this.getField(fieldKey)}
            isNew={this.isAddNew}
          />
        </>
      </Collapsable>
    );
  }

  private get headerActions(): ReactNode {
    return (
      <>
        <ViewPermission hasPermission={!this.isEditable}>
          <CustomLinkButton to="/aircraft/performance" title="Performance" startIcon={<ArrowBack />} />
        </ViewPermission>
        <EditSaveButtons
          disabled={this.hasError || UIStore.pageLoading || this.isRowEditing}
          hasEditPermission={AircraftModuleSecurity.isEditable}
          isEditMode={this.isEditable}
          isEditing={this.isRowEditing}
          onAction={action => this.onAction(action)}
        />
      </>
    );
  }

  public render(): ReactNode {
    const { classes } = this.props as Required<Props>;
    return (
      <DetailsEditorWrapper headerActions={this.headerActions} isEditMode={this.isEditable}>
        <div className={classes.flexRow}>
          <Collapsable title={'Performance'}>
            <div className={classes.flexWrap}>
              {this.groupInputControls.inputControls
                .filter(inputControl => !inputControl.isHidden)
                .map((inputControl: IViewInputControl, index: number) => {
                  return (
                    <ViewInputControl
                      {...inputControl}
                      key={index}
                      field={this.getField(inputControl.fieldKey || '')}
                      isEditable={this.isEditable}
                      classes={{
                        flexRow: classNames({
                          [classes.halfFlex]: inputControl.isHalfFlex,
                          [classes.inputControl]: !inputControl.isHalfFlex,
                          [classes.fullFlex]: inputControl.isFullFlex,
                        }),
                      }}
                      onValueChange={(option, _) => this.onValueChange(option, inputControl.fieldKey || '')}
                    />
                  );
                })}
            </div>
          </Collapsable>
          <GenericRegistryGrid key="genericRegistry" rowData={this.getField('navBlueGenericRegistries').values()} />
          <ScheduleGrid
            key="climbSchedules"
            rowData={this.getField('climbSchedules').values()}
            onDataSave={schedule => this.getField('climbSchedules').set(schedule)}
            onRowEdit={isRowEditing => this.updateRowEditing(isRowEditing, 'climbSchedules')}
            isEditable={this.isEditable}
            policyList={this.speedScheduleSettingsStore.climbSchedules}
            title="Climb Schedule"
          />
          <CruiseScheduleGrid
            key="cruiseSchedules"
            rowData={this.getField('cruiseSchedules').values()}
            onDataSave={schedule => this.getField('cruiseSchedules').set(schedule)}
            onRowEdit={isRowEditing => this.updateRowEditing(isRowEditing, 'cruiseSchedules')}
            isEditable={this.isEditable}
            policyList={this.speedScheduleSettingsStore.cruiseSchedules}
            title="Cruise Schedule"
          />
          <ScheduleGrid
            key="descentSchedules"
            rowData={this.getField('descentSchedules').values()}
            onDataSave={schedule => this.getField('descentSchedules').set(schedule)}
            onRowEdit={isRowEditing => this.updateRowEditing(isRowEditing, 'descentSchedules')}
            isEditable={this.isEditable}
            policyList={this.speedScheduleSettingsStore.descentSchedules}
            title="Descent Schedule"
          />
          <ScheduleGrid
            key="holdSchedules"
            rowData={this.getField('holdSchedules').values()}
            onDataSave={schedule => this.getField('holdSchedules').set(schedule)}
            onRowEdit={isRowEditing => this.updateRowEditing(isRowEditing, 'holdSchedules')}
            isEditable={this.isEditable}
            policyList={this.speedScheduleSettingsStore.holdSchedules}
            title="Hold Schedule"
          />
          <PerformanceLinkGrid
            key="PerformanceLink"
            performanceLinkData={this.getField('performanceLinks').values()}
            onDataSave={performanceLinks => this.getField('performanceLinks').set(performanceLinks)}
            onRowEdit={isRowEditing => this.updateRowEditing(isRowEditing, 'performanceLinks')}
            isEditable={this.isEditable}
          />
          <Collapsable title="Comments" titleVariant="h6">
            <ViewInputControl
              field={this.getField('comments')}
              isEditable={this.isEditable}
              onValueChange={(option, _) => this.getField('comments').set(option)}
              type={EDITOR_TYPES.RICH_TEXT_EDITOR}
              showExpandButton={false}
            />
          </Collapsable>
          {this.systemDataFields}
        </div>
      </DetailsEditorWrapper>
    );
  }
}

export default withRouter(withStyles(styles)(PerformanceEditor));
export { PerformanceEditor as PurePerformanceEditor };
